import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PropertyModel } from 'src/app/models/property.model';
import { PickerControlItemModel } from 'src/app/models/pickerControlItem.model';

@Injectable({
  providedIn: 'root'
})
export class PropertyModuleService {

  private serviceUrl = environment.apiUrl + 'ModuleProperty';

  constructor(private http: HttpClient) { }

  getProperties(): Observable<PropertyModel> {
    return this.http.get<PropertyModel>(`${this.serviceUrl}/getProperties`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
  
  getMyPropertyList(pageSize: number , page: number ): Observable<PickerControlItemModel[]> {
    return this.http.get<PickerControlItemModel[]>(`${this.serviceUrl}/getMyPropertyList?pageSize=${pageSize}&page=${page}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getProperty(id: string): Observable<PropertyModel> {
    return this.http.get<PropertyModel>(`${this.serviceUrl}/getProperty/${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  createModuleProperty(model: PropertyModel): Observable<PropertyModel> {
    return this.http.post<PropertyModel>(`${this.serviceUrl}/setProperty`, model, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
}
