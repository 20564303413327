<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="display-4 my-5">Property Management</h1>
            <section>
                <div
                    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-3 mb-4 obrame-action-row">
                    <div class="btn-toolbar mb-2 mb-md-0">
                        <div class="btn-group me-2">
                            <button type="button" class="btn btn-obrame-secondary">
                                <fa-icon [icon]="faBorderAll" [fixedWidth]="true" class="fixed"></fa-icon> Grid View
                            </button>
                            <!-- <button type="button" class="btn btn-obrame-secondary">
                                <fa-icon [icon]="faTableList" [fixedWidth]="true" class="fixed"></fa-icon> List View
                            </button> -->
                        </div>
                    </div>
                    <a class="btn btn-primary btn-obrame-primary" [routerLink]='["property"]'>
                        <fa-icon [icon]="faHouseMedical" class="px-2"></fa-icon>
                        Add Property
                    </a>
                </div>
            </section>
            <section>
                <div class="row row-cols-1 row-cols-md-3 g-4" *ngIf="propertiesModel">
                    <div class="col" *ngFor="let model of propertiesModel">
                        <div class="card obrame-card h-100">
                            <div class="card-body">
                                <a [routerLink]='["property", model.id]'>
                                    <h5 class="card-title text-center mb-4 px-4">{{model.title}}</h5>
                                    <div class="mb-3">
                                        <fa-icon [icon]="faLocationDot" [fixedWidth]="true" class="fixed" size="lg">
                                        </fa-icon>
                                        <span class="mx-4">{{model.addressTitle}}</span>
                                    </div>
                                    <div class="mb-3">
                                        <fa-icon [icon]="faHouse" [fixedWidth]="true" class="fixed" size="lg"></fa-icon>
                                        <span class="mx-4">{{model.houseNumber}}</span>
                                    </div>
                                    <div class="mb-3">
                                        <fa-icon [icon]="faSquare" [fixedWidth]="true" class="fixed" size="lg"></fa-icon>
                                        <span class="mx-4">{{model.houseSize}} {{model.houseSizeUnit}}</span>
                                    </div>
                                    <div class="mb-3">
                                        <fa-icon [icon]="faBed" [fixedWidth]="true" class="fixed mr-2" size="lg">
                                        </fa-icon>
                                        <span class="mx-4">{{model.numberOfRooms}}</span>
                                    </div>
                                </a>
                            </div>
                            <!-- <div class="card-footer">
                                            <small class="text-muted">Last updated 3 mins ago</small>
                                        </div> -->
                        </div>
                    </div>
        
        
                </div>
            </section>
        </div>
    </div>
</div>