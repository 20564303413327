<div *ngIf="isReadMode">
    <div class="form-group">
        <label class="mb-3">Your verified phone number:</label>
        <h5>{{phoneNumber}}</h5>
    </div>
</div>
<div *ngIf="!isReadMode">
    <div class="row g2" *ngIf="!showValidation">
        <div class="col-12 col-lg-6">
            <label class="form-label" for="phoneNumber">Phone Number</label>
            <input type="tel" class="form-control form-obrame-input" id="phoneNumber" placeholder="+34 ..."
                aria-describedby="phoneNumberHelp" [formControl]="phone"
                [ngClass]="{'is-invalid': phone.dirty && !phone.valid }">

            <small id="phoneNumberHelp" class="form-text text-muted">
                We will send a verification code to your mobile phone number.
            </small>
            <div class="invalid-feedback">Please check your number, which should start with your contry code. e.g.
                +34 for Spain..
            </div>
        </div>

        <div class="col-md-block col-lg-2 justify-content-center align-content-center">
            <div class="d-grid gap-2">
            <button class="btn btn-obrame-primary" [disabled]="!phone.valid || isSendLoading"
                (click)="onSendValidationCode()">
                Send
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                    *ngIf="isSendLoading"></span>
            </button>
            </div>
        </div>
    </div>
    <div class="row mb-4" *ngIf="showValidation">
        <label mb-3>Code sent to:</label>
        <h5>{{phone.value}}</h5>
    </div>
    <div class="row" *ngIf="showValidation">
        <div class="col-md-block col-lg-6">
            <label class="form-label" for="VerificationNumber">Verification Code</label>
            <input type="number" class="form-control form-obrame-input" id="VerificationNumber"
                placeholder="1234" aria-describedby="verificationNumberHelp" [formControl]="phoneValidationCode"
                [ngClass]="{'is-invalid': phoneValidationCode.dirty && !phoneValidationCode.valid }">


            <small id="verificationNumberHelp" class="form-text text-muted">Please enter the code received.</small>
            <div class="invalid-feedback">Please check the pin entered.</div>

        </div>
        <div class="col-md-block col-lg-3 mt-3 gap-2">
            <div class="d-grid gap-2">
                <button class="btn btn-obrame-primary" [disabled]="!phoneValidationCode.valid || isVerificationLoading"
                    (click)="onValidateCode()">
                    Verify the Code
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        *ngIf="isVerificationLoading"></span>
                </button>
                <button class="btn btn-obrame-secondary" (click)="onCancelVerification()">
                    Edit the number
                </button>
            </div>
        </div>
    </div>

    <app-error-alert [errorMessage]="errorMessage" [hideError]="hideErrorMessage"></app-error-alert>
</div>