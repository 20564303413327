import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';

import { AccountService } from '../account.service';
import { EmailVerificationModel } from 'src/app/models/emailVerification.model';


@Injectable()
export class ConfirmationResolver  {

  constructor(private service: AccountService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    console.dir('In confirmation resolver');
    const code = route.queryParamMap.get('code');
    const userId = route.queryParamMap.get('userid');

    if (code && userId) {
      // console.log('userid: ' + userId + ' code:' + code);
      const model = new EmailVerificationModel(userId, code);
      console.log('model: ' + model);
      this.service.verifyEmail(model)
        .subscribe(
          () => { console.log('sucess in resolver'); return true; },
          (error: any) => {
            console.log('error in resolver' + error);
            return false;
          },
        );
    } else { return of(false); }
  }
}
