import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


import { AuthGuard } from './shared/auth-guard.service';
import { DashboardComponent } from './private/dashboard/dashboard.component';
import { ProfileProgressGuard } from './profile/profileProgress.guard';
import { DashboardResolver } from './private/dashboard/dashboard-resolver.service';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '', component: DashboardComponent, pathMatch: 'full', canActivate: [AuthGuard, ProfileProgressGuard],
        resolve: { dashboardData: DashboardResolver }
      }
    ], {})
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
