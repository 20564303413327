import { Component, OnInit } from '@angular/core';
import { faEnvelope, faStar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-register-confirmation',
  templateUrl: './register-confirmation.component.html',
  styleUrls: ['./register-confirmation.component.css']
})
export class RegisterConfirmationComponent implements OnInit {
  faEnvelope = faEnvelope;
  faStar = faStar;

  constructor() { }

  ngOnInit() {
  }

}
