import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RegisterModel } from '../models/register.model';
import { LoginModel } from '../models/login.model';
import { TokenModel } from '../models/token.model';
import { PhoneVerificationModel } from '../models/phoneVerification.model';
import { EmailVerificationModel } from '../models/emailVerification.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthTokenService } from '../shared/auth-token.service';
import { PasswordResetRequestModel } from '../models/passwordResetRequest.model';
import { PasswordResetModel } from '../models/passwordReset.model';


@Injectable()
export class AccountService {

    private serviceUrl = environment.apiUrl + 'account';
    private serviceUrlProfile = environment.apiUrl + 'profile';
    private loggedIn = new BehaviorSubject<boolean>(false); // {1}

    constructor(private http: HttpClient, private router: Router, private tokenService: AuthTokenService) { }

    get isLoggedIn() {
        if (!this.tokenService.isTokenExpired()) {
            this.loggedIn.next(true);
        }
        return this.loggedIn.asObservable(); // {2}
    }

    registerAccount(newRegistration: RegisterModel): Observable<RegisterModel> {
        return this.http.post<RegisterModel>(this.serviceUrl + '/register', newRegistration, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    sendPhoneVerificationCode(phonenumber: string): Observable<string> {
        return this.http.post<string>(this.serviceUrlProfile + '/sendPhoneVerificationCode?phoneNumber=' + phonenumber, null, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    verifyPhoneNumber(model: PhoneVerificationModel): Observable<PhoneVerificationModel> {
        return this.http.post<PhoneVerificationModel>(this.serviceUrlProfile + '/VerifyPhoneNumber', model, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    passwordResetRequest(model: PasswordResetRequestModel): Observable<PasswordResetRequestModel> {
        return this.http.post<PasswordResetRequestModel>(this.serviceUrl + '/PasswordResetRequest', model, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    passwordResetValidation(id: string): Observable<string> {
        return this.http.get<string>(this.serviceUrl + '/PasswordResetValidate/' + id, {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            })
          });
    }

    passwordReset(model: PasswordResetModel): Observable<string> {
        return this.http.post<string>(this.serviceUrl + '/PasswordReset', model, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    verifyEmail(model: EmailVerificationModel): Observable<void> {
        return this.http.post<void>(this.serviceUrl + '/verifyEmail', model, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    login(loginInfo: LoginModel): Observable<TokenModel> {
        const headers = new HttpHeaders({ 'No-Auth': 'True' });
        return this.http.post<TokenModel>(this.serviceUrl + '/login', loginInfo, { headers: headers });
    }

    setIsLogin(): void {
        this.loggedIn.next(true);
    }

    logout(): void {
        // remove user from local storage to log user out
        localStorage.removeItem('userToken');
        this.loggedIn.next(false);
        this.router.navigate(['/login']);
    }

}
