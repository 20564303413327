import { Component } from '@angular/core';
import { faAddressCard, faMoneyCheckDollar, faLock } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent {
  faAddressCard= faAddressCard;
  faMoneyCheckDollar = faMoneyCheckDollar;
  faLock = faLock;
}
