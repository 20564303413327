<div class="container py-5">
    <div class="row">
        <div class="col-12">
            <section *ngIf="model">
                <div class="row">
                    <div class="col-12 col-lg-8">
                        <h1 class="display-6 my-4">{{model.assignment.propertyAddress}}</h1>
                        <h3 class="mt-3"><small class="text-muted">{{model.assignment.assignmentDate}}
                                {{model.assignment.startTime}} ({{model.assignment.duration}} h)</small></h3>

                        <dl class="row mt-5">
                            <dt class="col-sm-3">Team</dt>
                            <dd class="col-sm-9">{{model.assignment.teamName}}</dd>
                            <dt class="col-sm-3">Member</dt>
                            <dd class="col-sm-9">{{model.assignment.memberName}}</dd>
                            <dt class="col-sm-3">Status</dt>
                            <dd class="col-sm-9">{{model.assignment.status}}</dd>
                            <dt class="col-sm-3">Total Price:</dt>
                            <dd class="col-sm-9">{{model.assignment.priceRate}} €</dd>
                        </dl>

                        <app-timeline [model]="model.timelineModel"></app-timeline>


                    </div>
                    <div class="col-12 col-lg-4">
                        <h2 class="mb-4 mt-4 border-bottom"
                            *ngIf="!model.assignment.isDeclined && !model.assignment.isCancelled">
                            Actions</h2>
                        <div class="d-grid gap-2 d-md-flex" *ngIf="model.isServiceProvider; else propertyOwner">
                            <button type="button" class="btn btn-obrame-secondary"
                                *ngIf="!model.assignment.isAccepted && !model.assignment.isCancelled && !model.assignment.isDeclined"
                                (click)="onAssignmentAction('accept')">Accept <span
                                    class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                    *ngIf="isAcceptLoading"></span></button>
                            <!-- <button type="button" class="btn btn-outline-secondary">Propose New Time</button> -->
                            <button type="button" class="btn btn-outline-danger"
                                *ngIf="!model.assignment.isDeclined && !model.assignment.isAccepted"
                                (click)="openDeclineModal(declineModal)">Decline</button>
                        </div>
                        <ng-template #propertyOwner>
                            <div class="d-grid gap-2 d-md-flex"
                                *ngIf="!model.assignment.isDeclined && !model.assignment.isCancelled">
                                <!-- <button type="button" class="btn btn-outline-secondary">Edit Date</button> -->
                                <button type="button" class="btn btn-outline-warning"
                                    (click)="openDeclineModal(cancelModal)">Cancel</button>
                            </div>
                        </ng-template>
                    </div>
                </div>

            </section>

            <ng-template #declineModal let-modal>
                <div class="modal-header">
                    <h4 class="modal-title">Decline Assignment Request</h4>
                    <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                </div>
                <div class="modal-body">
                    <p><b>Are you sure you want to cancel this assignment? </b></p>
                    <p> If you proceed, the requester will be notified and the
                        assignment will be archived. Please note that the state of the assignment cannot be changed
                        after it has
                        been archived.
                    </p>
                    <p>
                        Canceling an assignment should be done only if you are certain that it cannot be completed or if
                        there is a
                        valid reason for cancellation. Once an assignment has been archived, it cannot be modified, and
                        any progress
                        made towards completing the assignment will be lost.
                    </p>

                    <p>
                        If you are sure that you want to cancel the assignment, please click the "Confirm" button.
                        Otherwise, click
                        "Cancel" to return to the previous screen.

                        Thank you for using our platform, and please let us know if you have any further questions or
                        concerns.</p>

                    <div class="mb-3">
                        <label for="declineCtrl" class="form-label"><b>Decline Reason</b></label>
                        <textarea class="form-control" id="declineCtrl" rows="3" [formControl]="declineCtrl"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" (click)="onAssignmentAction('decline')"
                        [disabled]="!declineCtrl.valid || !declineCtrl.dirty">
                        Decline The Assignment
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            *ngIf="isDeclineLoading"></span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary"
                        (click)="modal.close('Close click')">Cancel</button>
                </div>
            </ng-template>

            <ng-template #cancelModal let-modal>
                <div class="modal-header">
                    <h4 class="modal-title">Cancel The Assignment Request</h4>
                    <button type="button" class="btn-close" aria-label="Close"
                        (click)="modal.dismiss('Cross click')"></button>
                </div>
                <div class="modal-body">
                    <p><b>Are you sure you want to cancel this assignment? </b></p>
                    <p> If you proceed, the person performing the task gets will be notified and the
                        assignment will be archived. Please note that the state of the assignment cannot be changed
                        after it has
                        been archived.
                    </p>
                    <p>
                        Canceling an assignment should be done only if you are certain that it cannot be completed or if
                        there is a
                        valid reason for cancellation. Once an assignment has been archived, it cannot be modified, and
                        any progress
                        made towards completing the assignment will be lost.
                    </p>

                    <p>If you are sure that you want to cancel the assignment, please click the "Confirm" button.
                        Otherwise, click
                        "Cancel" to return to the previous screen.</p>

                    <div class="mb-3">
                        <label for="declineCtrl" class="form-label"><b>Cancel Reason</b></label>
                        <textarea class="form-control" id="declineCtrl" rows="3" [formControl]="declineCtrl"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-danger" (click)="onAssignmentAction('cancel')"
                        [disabled]="!declineCtrl.valid || !declineCtrl.dirty">
                        Cancel The Assignment
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            *ngIf="isCancelLoading"></span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary"
                        (click)="modal.close('Close click')">Close</button>
                </div>
            </ng-template>
        </div>
    </div>
</div>