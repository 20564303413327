import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faClock, faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { ProfessionalOrderDetailsModel } from 'src/app/models/professionalOrderDetails.model';


@Component({
  selector: 'app-professional-order-details',
  templateUrl: './professional-order-details.component.html',
  styleUrls: ['./professional-order-details.component.css']
})
export class ProfessionalOrderDetailsComponent implements OnInit {

  faClock = faClock;
  faEuroSign = faEuroSign;

  model: ProfessionalOrderDetailsModel;

  constructor(private route: ActivatedRoute) {
    this.model = this.route.snapshot.data.professionalOrderData;
  }

  ngOnInit(): void {
  }

}
