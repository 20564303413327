import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../account.service';
import { EmailVerificationModel } from 'src/app/models/emailVerification.model';
import { switchMap } from 'rxjs/operators';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  faCheck = faCheck;
  faXmark = faXmark;
  showSuccess = false;
  showFailure = false;
  model = new EmailVerificationModel('', '');

  constructor(private route: ActivatedRoute, private service: AccountService) {

  }

  ngOnInit() {

    this.route.paramMap.subscribe(
      params => {
        this.model.userId = params.get('userid');
        this.model.code = params.get('code');
        // console.dir(this.model);
        this.activateUser(this.model);
      }
    );

  }

  activateUser(model: EmailVerificationModel) {
    console.dir(model);
    if (model && model.userId && model.code) {
      this.service.verifyEmail(model)
        .subscribe(
          () => { this.showSuccess = true; },
          (error: any) => {
            console.log('error in resolver' + error);
            this.showFailure = true;
          },
        );
    }
  }

}
