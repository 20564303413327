import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ModuleServiceModel } from 'src/app/models/moduleService.model';
import { ServiceProviderService } from './service-provider.service';




@Injectable()
export class ServiceProviderResolver  {

  constructor(private serviceProviderService: ServiceProviderService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ModuleServiceModel> {
    
    return this.serviceProviderService.getModuleService().pipe(
      map(moduleService => {
        if (moduleService) {
          return moduleService;
        }
        return null;
      }),
      catchError(error => {
        console.log(`Retrieval error: ${error}`);
        this.router.navigate(['/']);
        return of(null);
      })
    );
  }

}
