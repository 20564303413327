import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService } from '../account.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordResolver {

  constructor(private accountService: AccountService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {


    const code = route.params.code;
    //console.log('retrived code: ' + code);

    return this.accountService.passwordResetValidation(code).pipe(
      map(() => {
        return code;
      }),
      catchError(error => {
        console.log(`Retrieval error: ${error}`);
        return of(null);
      })
    );
  }
}
