import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { passwordMatchValidator } from 'src/app/utilities/form-validators';
import { AccountService } from '../account.service';
import {faLock, faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  faLock = faLock;
  alertIcon = faTriangleExclamation;

  isCodeValid = false;
  isLoading = false;
  showFailure = false;
  showSuccess = false;
  isCompleted = false;
  errorMessage = "";
  resetId = "";

  passwordForm: UntypedFormGroup;
  password: UntypedFormControl;
  passwordConfirm: UntypedFormControl;
  constructor(private router: Router, private route: ActivatedRoute, private accountService: AccountService) { }

  ngOnInit(): void {
    // Get the validation data
    this.route.data.subscribe(data => {
      this.onValidationRetrived(data.resetServices);
  });

    this.password = new UntypedFormControl('', [Validators.required, Validators.minLength(8)]);
    this.passwordConfirm = new UntypedFormControl('', [Validators.required, Validators.minLength(8), passwordMatchValidator(this.password)]);

    this.passwordForm = new UntypedFormGroup({
      password: this.password,
      passwordConfirm: this.passwordConfirm,
    });
  }

  onValidationRetrived(resetCode: string){
    if(resetCode){
      this.isCodeValid = true;
      this.resetId = resetCode;
    }
  }

  onReset(){
    this.showFailure = false;
    this.isLoading = true;

    const resetModel = Object.assign({"Id": this.resetId }, this.passwordForm.value);
    console.log(resetModel);
    this.accountService.passwordReset(resetModel)
    .subscribe(
      () => this.onResetComplete(),
      (error: any) => {
        this.showFailure = true;
        this.isLoading = false;
        if (error.status === 400) {
            this.errorMessage = error?.error[0]?.title;
        } else if (error.status === 500 || error.status === 0) {
            this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
        }
        console.log(error);
      },
  );
  }
  onResetComplete(): void {
    this.showSuccess = true;
    this.isLoading = false;
    this.isCompleted = true;
  }


}
