import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AddressModel } from 'src/app/models/address.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  private serviceUrl = environment.apiUrl + 'Address';

  constructor(private http: HttpClient) { }

  getAddresses(): Observable<AddressModel[]> {
    return this.http.get<AddressModel[]>(this.serviceUrl, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getAddress(id: string): Observable<AddressModel> {
    return this.http.get<AddressModel>(this.serviceUrl + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  createAddress(model: AddressModel): Observable<AddressModel> {
    return this.http.post<AddressModel>(this.serviceUrl, model, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  updateAddress(model: AddressModel): Observable<AddressModel> {
    return this.http.put<AddressModel>(this.serviceUrl + '/' + model.id, model, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
    // .pipe(
    //   catchError(this.handleError('updateHero', hero))
    // );
  }

  setWorkAddress(id: string): Observable<AddressModel> {
    return this.http.put<AddressModel>(`${this.serviceUrl}/${id}/setWorkAddress`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
    // .pipe(
    //   catchError(this.handleError('updateHero', hero))
    // );
  }

  removeAddress(id: string): Observable<boolean> {
    return this.http.delete<boolean>(this.serviceUrl + '/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
}
