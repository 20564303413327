import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { InboxService } from './inbox.service';
import { DiscussionModel } from '../models/discussion.model';
import { catchError, map } from 'rxjs/operators';
import { InboxModel } from '../models/inbox.model';

@Injectable({
    providedIn: 'root'
})
export class InboxResolverService {

    amount = 10;
    skip = 0;
    constructor(private service: InboxService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InboxModel> {

        return this.service.getInbox(this.amount, this.skip).pipe(
            map(inbox => {
                if (inbox) {
                    return inbox;
                }
                return null;
            }),
            catchError(error => {
                console.log(`Retrieval error: ${error}`);
                this.router.navigate(['/']);
                return of(null);
            })
        );
    }
}
