import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faUsers, faUserPlus, faEllipsis, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { TeamMemberTableModel } from 'src/app/models/teamMemberTable.model';


import { HttpErrorResponse } from '@angular/common/http';
import { TeamsService } from '../teams.service';
import { TeamMemberModel } from 'src/app/models/teamMember.model';
import { TeamInvitationModel } from 'src/app/models/teamInvitation.model';
import { TeamInviteModel } from 'src/app/models/teamInvite.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { Observable, catchError, map, of, tap } from 'rxjs';

@Component({
  selector: 'app-teams',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.css']
})
export class TeamMembersComponent {
  faUserPlus = faUserPlus;
  faUsers = faUsers;
  faEnvelope = faEnvelope;
  faEllipsis = faEllipsis;


  tableModel: TeamMemberTableModel;

  activeMode = "members"
  isLoading = false;
  hideErrorMessage = true;
  errorMessage = "";
  currentPage = 1;
  pageSize = 10;
  collectionSize = 1;


  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private teamService: TeamsService, private toastService: ToastService) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.tableModel = data.teamsData;
    });
  }

  getData(mode: string) {
    this.activeMode = mode;
    this.currentPage = 1;
    if (mode === "members") {
      this.getMembers();
    } else {
      this.getInvitations();
    }
  }


  //getTeamInvitations
  getMembers() {
    this.teamService.getMembers(this.currentPage, this.pageSize).pipe(
      tap(_ => { console.log("Getting members"); this.isLoading = true; }),
      map(response => this.onTeamTableReceived(response)),
      catchError(this.handleError<TeamMemberTableModel>("GetTeams"))
    ).subscribe();
  }

  onTeamTableReceived(data: TeamMemberTableModel): void {
    this.isLoading = false;
    this.tableModel = data;
  }

  getInvitations() {
    this.teamService.getTeamInvitations(this.currentPage, this.pageSize).pipe(
      tap(_ => { console.log("Getting invitations"); this.isLoading = true; }),
      map(response => this.onTeamTableReceived(response)),
      catchError(this.handleError<TeamMemberTableModel>("GetInvitations"))
    ).subscribe();
  }

  onTeamCancellation(membership: TeamMemberModel) {
    this.teamService.setTeamMembershipCancellation(membership).pipe(
      tap(_ => { console.log("Getting invitations"); this.isLoading = true; }),
      map(_ => this.getData("members")),
      catchError(this.handleError<TeamMemberTableModel>("SetMembershipCancellation"))
    ).subscribe();
  }

  onReSendInvitation(invitation: TeamInviteModel) {
    this.teamService.setInvitationRequest(invitation).pipe(
      tap(_ => { console.log("Resending invitation"); this.isLoading = true; }),
      map(_ => this.onActionCompleted('The invitation was sent successfully!')),
      catchError(this.handleError<TeamMemberTableModel>("ResendSetInvitationRequest"))
    ).subscribe();
  }

  onActionCompleted(message: string) {
    this.getData("invitations");
    this.toastService.show(message, { classname: 'obrame-toast-success', delay: 4000 });
  }


  onRemoveInvitation(invitation: TeamInviteModel) {
    this.teamService.setCancelInvitationRequest(invitation).pipe(
      tap(_ => { console.log("Cancel Invitation"); this.isLoading = true; }),
      map(_ => this.onActionCompleted('The invitation was cancelled successfully!')),
      catchError(this.handleError<TeamMemberTableModel>("SetMembershipCancellation"))
    ).subscribe();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.toastService.show(error?.error, { classname: 'obrame-toast-error', delay: 4000 });
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);


      this.hideErrorMessage = false;
      this.errorMessage = error.message;

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  onError(error: HttpErrorResponse): void {
    this.hideErrorMessage = false;
    this.isLoading = false;
    if (error.status === 400) {
      this.errorMessage = error?.error[0]?.description;
    } else if (error.status === 500 || error.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
    console.log(error);
  }

}
