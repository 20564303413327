import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaymentMethodModel } from '../models/paymentMethod.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private serviceUrlPayment = environment.apiUrl + 'payment/getPaymentMethods';

  constructor(private http: HttpClient) { }

  getPaymentMethods(): Observable<PaymentMethodModel[]> {
    return this.http.get<PaymentMethodModel[]>(this.serviceUrlPayment, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
}
