import { Component, OnInit, Input } from '@angular/core';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-error-alert',
    templateUrl: './error-alert.component.html',
    styleUrls: ['./error-alert.component.css']
})
export class ErrorAlertComponent implements OnInit {
    @Input() errorMessage: string;
    @Input() hideError: boolean;

    faTriangleExclamation = faTriangleExclamation;
    constructor() { }

    ngOnInit() {
        if (!this.errorMessage) {
            this.errorMessage = 'Upps, we found an error in our systems! Our engineers on it so no worries. Please check back in a few minutes.';
        }
    }

}
