import { UntypedFormControl, AbstractControl, ValidatorFn } from '@angular/forms';

// custom validator to check that two fields match
export function passwordMatchValidator(pwd: UntypedFormControl) : ValidatorFn{
    return (control: AbstractControl): {[key: string]: boolean } | null => {
      if (control.value !== undefined && pwd.value != undefined && control.value !== pwd.value) {
        return { 'missMatch': true}
      } 
      return null;
    };
  }