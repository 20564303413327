export class AddressModel {

  constructor(
    public id: string,
    public title: string,
    public isVerified: boolean,
    public isDeleted: boolean,
    public isWork: boolean,
    public street_Number: string,
    public street_Name: string,
    public additional_Address: string,
    public postal_Code: string,
    public city: string,
    public state: string,
    public country: string,
    public instructions: string,
    public longitude: string,
    public latitude: string
  ) {  }
}
