<main role="main" class="container">
    <div class="row">
        <div class="col-4">
            <app-dual-card [models]="model.participants">
            </app-dual-card>
        </div>
        <div class="col-8">
            <form #messageForm="ngForm">
                <div class="pt-5">
                    <div class="form-group">
                        <textarea class="form-control" id="newMessage" rows="3" placeholder="Escribe un mensaje..." [(ngModel)]="newMessage" name="newMessage" required>></textarea>
                    </div>
                    <div class="text-right">
                        <button type="button" class="btn btn-primary mb-2" (click)="sendMessage(); messageForm.reset()">Mándalo</button>
                    </div>
                </div>
            </form>

            <div class="msg_history mt-5" *ngIf="messages else loading">
                <ng-container *ngFor="let message of messages">

                    <div class="incoming_msg" *ngIf="model.currentUserId !== message.owner.id">
                        <div class="incoming_msg_img">
                            <p *ngIf="!message.owner.profilePictureUrl">{{message.owner.firstName}}</p>
                            <img *ngIf="message.owner.profilePictureUrl" src="{{message.owner.profilePictureUrl}}" alt="{{message.owner.firstName}}">
                        </div>
                        <div class="received_msg">
                            <div class="received_withd_msg">
                                <p>
                                    {{message.message}}
                                </p>
                                <span class="time_date"> {{message.createdOn}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="outgoing_msg" *ngIf="model.currentUserId === message.owner.id">
                        <div class="sent_msg">
                            <p>
                                {{message.message}}
                            </p>
                            <span class="time_date"> {{message.createdOn}}</span>
                        </div>
                    </div>

                </ng-container>
            </div>

            <ng-template #loading>
                <div>Loading...</div>
            </ng-template>

        </div>
    </div>
</main>