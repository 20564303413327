import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faEnvelope, faTriangleExclamation, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { AccountService } from '../account.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

  faEnvelope = faEnvelope;
  alertIcon = faTriangleExclamation;
  successIcon = faCheckCircle;

  isLoading = false;
  resetForm: UntypedFormGroup;
  email: UntypedFormControl;
  showSuccess = false;
  showFailure = false;
  errorMessage: string;


  constructor(private fb: UntypedFormBuilder, private router: Router, private _accountService: AccountService) { }

  ngOnInit(): void {
    this.email = new UntypedFormControl('', [Validators.required, Validators.email]);

    this.resetForm = this.fb.group({
      email: this.email,
    });
  }


  onReset() {
    this.showSuccess = false;
    this.showFailure = false;
    this.isLoading = true;
    const resetModel = Object.assign({}, this.resetForm.value);

    this._accountService.passwordResetRequest(resetModel)
      .subscribe(
        () => this.onResetComplete(),
        (error: HttpErrorResponse) => this.onError(error)
      );
  }

  onResetComplete(): void {
    this.showSuccess = true;
    this.isLoading = false;
    this.resetForm.reset();
    this.resetForm.markAsUntouched();
  }

  onError(httpError: HttpErrorResponse) {
    this.isLoading = false;
    this.showFailure = true;
    this.resetForm.reset();
    console.log(httpError);
    if (httpError.status === 400) {


      switch (httpError.error) {
        case 'credentials':
          this.errorMessage = 'Either your reset link has expired or the account does not exist. If you have registered with us, please contact support.';
          break;
        default:
          this.errorMessage = httpError?.error;
          break;
      }
    } else if (httpError.status === 500 || httpError.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
  }

}
