<div class="row g-2" [formGroup]="parentForm">
  <div class="col-md col-lg-3">
    <label class="form-label" for="yearSelectGrid">Year</label>
    <select class="form-select form-obrame-select" id="yearInput" formControlName="year">
      <option *ngFor="let y of years" [value]="y">{{y}}</option>
    </select>
  </div>
  <div class="col-md col-lg-2">
    <label class="form-label" for="monthSelectGrid">Month</label>
    <select class="form-select form-obrame-select" id="monthSelectGrid" formControlName="month">
      <option *ngFor="let m of months" [value]="m">{{m}}</option>
    </select>

  </div>
  <div class="col-md  col-lg-2">
    <label class="form-label" for="daySelectGrid">Day</label>
    <select class="form-select form-obrame-select" id="daySelectGrid" formControlName="day">
      <option *ngFor="let d of days" [value]="d">{{d}}</option>
    </select>
  </div>
</div>