<div class="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2" *ngIf="models">
    <div class="col g-4" *ngFor="let model of models">
        <div class="card h-100 obrame-selector" [class.cardSelected]="model === selectedModel"
            style="max-width: 540px;">
            <a (click)="onSelectButton(model)">
                <div class="row">
                    <div class="d-flex justify-content-center align-items-center col-12 col-sm-3 col-md-3 col-lg-4 p-4"
                        *ngIf="model.icon">
                        <fa-icon [icon]="model.faIcon" [fixedWidth]="true" class="fixed" size="4x"></fa-icon>
                    </div>
                    <div class="col-12 col-sm-9 col-md-9 col-lg-7">
                        <div class="card-body">
                            <h5 class="card-title">{{model.title}}</h5>
                            <p class="card-text">{{model.content}}</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>