import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnboardingModel } from 'src/app/models/onboarding.model';
import { OnboardingService } from './onboarding.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ContentService } from 'src/app/shared/content.service';
import { SelectModel } from 'src/app/models/select.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CardItemModel } from 'src/app/models/cardItem.model';
import { faInfo, faPhone, faBriefcase, faPersonCircleCheck, faSliders } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css']
})
export class OnboardingComponent implements OnInit {

  faInfo = faInfo;
  faPhone = faPhone;
  faBriefcase = faBriefcase;
  faPersonCircleCheck = faPersonCircleCheck;
  faSliders = faSliders;

  model: OnboardingModel;
  countriesModel: SelectModel[];
  serviceModuleCards: CardItemModel[];
  operatingCardModels: CardItemModel[];
  obrameModuleCard?: CardItemModel;
  onboardingForm = new FormGroup({});
  errorMessage = '';

  isLoading = false;
  isStageOneVisible = true;
  isCompanyvisible = false;

  isStageOneButtonDisabled = true;

  constructor(private route: ActivatedRoute, private router: Router, private onboardingService: OnboardingService, private contentService: ContentService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.model = data.onboardingData;
    });

    //adding the controls, the other form groups are added dynamically in html
    this.onboardingForm.addControl("businessName", new FormControl('', Validators.required));

    //if onboarded go to dashboard
    if (this.model.isOnboarded) {
      this.router.navigate(['/dashboard']);
    }

    this.getCountries();
    this.getserviceModuleCards();

    this.operatingCardModels = [
      new CardItemModel("1", "An Individual", "I work as an individual.", "faUser"),
      new CardItemModel("2", "A Company", "I'm a part of a company.", "faBuilding")
    ];

    //selecting the default value for the modules
    if (this.serviceModuleCards) {

    }

    //selecting the default value for the operating model
    this.operatingModelSelected(this.operatingCardModels[0]);
  }

  onPhoneVerificationCompleted(isValidated: boolean) {
    if (isValidated) {
      this.refreshState();
    }
  }

  serviceSelected(selection: CardItemModel) {
    this.obrameModuleCard = selection;
    this.model.moduleTypeId = selection.id;
    console.log(selection.id);
  }

  operatingModelSelected(selection: CardItemModel) {
    if (selection.id == "2") {
      this.isCompanyvisible = true;
    } else {
      this.isCompanyvisible = false;
    }
  }


  IsButtonDisabled(): boolean {
    var per = this.onboardingForm.controls["personalInfoForm"] as FormGroup;
    var comp = this.onboardingForm.controls["company"] as FormGroup;


    if (this.model.phoneNumberConfirmed && this.obrameModuleCard != null && this.onboardingForm.controls["businessName"].valid) {
      if (this.isCompanyvisible && comp.valid && per.valid && this.onboardingForm.valid) {
        return false;
      } else {
        if (!this.isCompanyvisible && per.valid) {
          return false;
        }
      }
      return true;
    }
    else {
      return true;
    }
  }


  onStageOneAction(): void {
    this.isLoading = true;
    this.onboardingForm.disable();
    if (this.model.moduleTypeId === "00000000-0000-0000-0000-000000000000") {
     this.model.moduleTypeId = this.obrameModuleCard.id;
    }
    this.model.businessName = this.onboardingForm.controls["businessName"].value;
    this.model.isCompany = this.isCompanyvisible;

    // Copy the form values over the  product object values
    const newOnbordingModel = Object.assign({}, this.model, this.onboardingForm.controls["company"].value, this.onboardingForm.controls["personalInfoForm"].value);


    this.onboardingService.setOnboarding(newOnbordingModel).subscribe(
      (data: OnboardingModel) => this.onSaveComplete(data),
      (error: HttpErrorResponse) => this.onError(error)
    );
  }

  onSaveComplete(data) {
    window.location.reload();
  }

  refreshState(): void {
    this.onboardingService.getOnboarding().subscribe(
      (m: OnboardingModel) => this.model = m,
      (error: HttpErrorResponse) => this.onError(error)
    );
  }

  onError(error: HttpErrorResponse) {
    // this.hideErrorMessage = false;
    // this.isLoading = false;
    if (error.status === 400) {
      this.errorMessage = error?.error[0]?.description;
    } else if (error.status === 500 || error.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
    console.log(error);
  }

  getCountries(): void {
    this.contentService.getCountires().subscribe(
      (m: SelectModel[]) => { this.countriesModel = m; },
      (error: HttpErrorResponse) => this.onError(error)
    );
  }
  getserviceModuleCards(): void {
    this.contentService.getServiceModules().subscribe(
      (m: CardItemModel[]) => {
        this.serviceModuleCards = m;
        this.serviceSelected(this.serviceModuleCards[0]);
        this.model.moduleTypeId = this.serviceModuleCards[0].id;
      },
      (error: HttpErrorResponse) => this.onError(error)
    );
  }

}
