import { FilterModel } from "./filter.model"

// a new FilterAssignmentModel that inherits the FilterModel class
export class FilterAssignmentModel extends FilterModel {
    constructor(public pageSize: number,
        public page: number,
        public startDate: Date,
        public endDate: Date,
        public propertiesId: Array<string>) {
        super(pageSize, page);
    }
}