<div class="container py-5">
    <div class="row">
        <div class="col-12">
            <h1 class="display-6">Welcome back, {{model.profileFirstName}}</h1>

            <section class="mt-5" *ngIf="model">
                <h2 class="display-5 py-5">Overview of porfile</h2>
                <ng-container *ngIf="model.poOverview">
                    <app-podashboard [weekModel]="model.poThisWeek"
                        [dashboardModel]="model.poOverview"></app-podashboard>
                </ng-container>
                <ng-container *ngIf="model.spOverview">
                    <app-spdashboard [weekModel]="model.spThisWeek"
                        [dashboardModel]="model.spOverview"></app-spdashboard>
                </ng-container>
            </section>

        </div>
    </div>
</div>