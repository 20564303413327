import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { inject } from "@angular/core";
import { Observable, catchError, map, of } from "rxjs";
import { PaymentMethodModel } from "src/app/models/paymentMethod.model";
import { PaymentService } from "src/app/shared/payment.service";


export const AssignmentResolver: ResolveFn<PaymentMethodModel[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: PaymentService = inject(PaymentService)
): Observable<PaymentMethodModel[]> => service.getPaymentMethods().pipe(
        map(service => {
            if (service) {
                return service;
            }
            console.log(`No payment service was found`);
            return null;
        }),
        catchError(error => {
            console.log(`Retrieval error: ${error}`);
            return of(null);
        })
    );
