<section class="pb-5">
    <h1 class="display-4 my-5">Manage <span class="obrame-txt-hightlight-yellow">Obrame</span> Subscriptions</h1>
    <div class="row">
        <div class="col-12 col-lg-2 text-center">
            <div class="col-12 col-lg-6 mb-5">
                <div
                    class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                    <fa-icon [icon]="faInfo" size="2x"></fa-icon>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-10">
            <p>
                Take full control of your Obrame licencing by managing, modifying, or canceling existing subscriptions
                with just a few clicks. Stay on top of your expenses and streamline your finances by accessing all
                subscription details in one place.
            </p>
            <p>Discover new services and products tailored to your interests. With our user-friendly interface,
                exploring and purchasing additional subscriptions is a breeze. Simply click the "Buy More" button to
                explore a world of possibilities.</p>
        </div>
    </div>
</section>

<section>
    <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-3 mb-4 obrame-action-row">
        <div class="btn-toolbar mb-2 mb-md-0">
        </div>
        <a class="btn btn-obrame-primary" [routerLink]='["/profile/subscriptions"]'>
            <fa-icon [icon]="faShoppingCart" class="px-2"></fa-icon>
            Buy Subscriptions
        </a>
    </div>

    <div class="py-3" *ngIf="model">
        <table class="table table-hover">
            <caption>List of licences</caption>
            <thead class="table-light">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Expiration Date</th>
                    <th scope="col">Billed Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let sub of model">
                    <th scope="row">{{sub.templateName}}</th>
                    <td>{{sub.startDate}}</td>
                    <td>{{sub.expirationDate}}</td>
                    <td>{{sub.billedAmount}} €</td>
                </tr>
            </tbody>
        </table>
    </div>
</section>