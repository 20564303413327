<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-12 col-lg-9 ">
                <h1 class="display-4 text-center my-5"><span class="obrame-txt-hightlight-yellow">Obrame</span>
                    Registration Completed!</h1>
                <div class="row pt-5">
                    <div class="col-3 d-flex justify-content-center">
                        <div class="col-10 col-sm-8 col-lg-6">
                            <div
                                class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                                <fa-icon [icon]="faEnvelope" size="2x"></fa-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-9">
                        <h3 class="mb-4">Confirm Your Email</h3>
                        <p>Congratulations, you have successfully created your Obrame account. You should receive an
                            email
                            to confirm your email address. If you have already activated your account, you can
                            <strong><a [routerLink]="['/login']">sign in</a></strong>.
                        </p>
                    </div>
                </div>
                <div class="row pt-5">
                    <div class="col-3 d-flex justify-content-center">
                        <div class="col-10 col-sm-8 col-lg-6">
                            <div
                                class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                                <fa-icon [icon]="faStar" size="2x"></fa-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-9">
                        <h3 class="mb-4">The Onboarding</h3>
                        <p>We can start the onboarding process as you confirm your e-mail address and log in to Obrame.
                            The
                            onboarding process will enable you to choose the functionalities for your account and the
                            right
                            plan for your need.</p>
                    </div>
                </div>

            </div>
        </div>
    </div>