<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <h1 class="display-4 text-center my-5">Reset your <span class="obrame-txt-hightlight-yellow">Obrame</span>
                Password</h1>
            <ng-template [ngIf]="isCodeValid" [ngIfElse]="notValid">
                <ng-template [ngIf]="!isCompleted">
                    <p class=" text-center my-5">Choose a new password. After changing the password, you will be
                        redirected to the login page.</p>
                    <div class="d-flex justify-content-center align-items-center">
                        <form novalidate (ngSubmit)="onReset()" [formGroup]="passwordForm">
                            <div class="mb-3">
                                <label for="inputNewPassword" class="form-label">Your New Password</label>
                                <input type="password" placeholder="" id="inputNewPassword"
                                    class="form-control form-obrame-input" aria-describedby="passwordHelpInline"
                                    size="50" formControlName="password"
                                    [ngClass]="{'is-invalid': password.invalid && (password.dirty || password.touched)}">
                                <div class="invalid-feedback">
                                    Use 8 or more characters with a mix of letters, numbers and symbols.
                                </div>
                                <div class="invalid-feedback">
                                    Please check your email format.
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="inputPasswordConfirm" class="form-label">New Password Confirmation</label>
                                <input type="password" placeholder="" id="inputPasswordConfirm"
                                    class="form-control form-obrame-input" aria-describedby="passwordConfirmHelp"
                                    size="50" formControlName="passwordConfirm"
                                    [ngClass]="{'is-invalid': passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched)}">
                                <div class="invalid-feedback">
                                    Both passwords must match.
                                </div>
                            </div>

                            <ng-template [ngIf]="showSuccess">
                                <div class="alert alert-success" role="alert">
                                    <h2 class="display-5 mb-4">Password change succeed!</h2>
                                    <p>Your password has been successfully reset and you can <a
                                            [routerLink]='["/login"]'>Sign In</a>
                                        with your new password.
                                    </p>
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="showFailure">
                                <div class="alert alert-danger" role="alert">
                                    <b class="display-5"><fa-icon [icon]="alertIcon"></fa-icon> Upppss...</b>

                                    <p class="mt-3">You can't use your old passwords or the reset link has
                                        expired.</p>
                                </div>
                            </ng-template>

                            <div class="d-grid my-5">
                                <button type="submit" class="btn btn-obrame-primary py-2"
                                    [disabled]="!passwordForm.valid || !passwordForm.dirty || isLoading || isCompleted">
                                    Reset Password
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        *ngIf="isLoading"></span>
                                </button>
                            </div>

                        </form>
                    </div>
                </ng-template>
            </ng-template>

            <ng-template #notValid>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="alert alert-danger col-12 col-lg-9" role="alert">
                        <b class="display-5"><fa-icon [icon]="alertIcon"></fa-icon> Something went wrong!</b>
           
                        <p class="mt-4">Either the reset link has expired or the reset code is unknown. Use
                            the reset link to receive a new password reset request. </p>
                            <a [routerLink]='["/reset"]'>Request a new link</a> to reset your password or contact support.
                    </div>
                </div>
               
            </ng-template>


        </div>
    </div>
</div>