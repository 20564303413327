import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxComponent } from './inbox.component';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/auth-guard.service';
import { SharedModule } from '../shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/auth.interceptor';
import { DiscussionComponent } from './discussion/discussion.component';
import { DiscussionResolverService } from './discussion/discussion-resolver.service';
import { InboxService } from './inbox.service';
import { InboxResolverService } from './inbox-resolver.service';



@NgModule({

    imports: [
        RouterModule.forChild([
            {
                path: 'inbox',
                canActivate: [AuthGuard],
                component: InboxComponent,
                resolve: { inboxData: InboxResolverService }
            },
            {
                path: 'inbox/discussion/:id',
                canActivate: [AuthGuard],
                component: DiscussionComponent,
                resolve: { discussionServices: DiscussionResolverService }
            }
        ]),
        SharedModule
    ],
    declarations: [InboxComponent, DiscussionComponent],
    providers: [
        DiscussionResolverService,
        InboxService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor, multi: true
        }
    ]
})
export class InboxModule { }
