import { Component, Input } from '@angular/core';
import { faClipboardCheck, faLocationDot, faUsers } from '@fortawesome/free-solid-svg-icons';
import { DashboardOverviewModel } from 'src/app/models/dashboardOverview.model';
import { DashboardThisWeekModel } from 'src/app/models/dashboardThisWeek.model';

@Component({
  selector: 'app-podashboard',
  templateUrl: './podashboard.component.html',
  styleUrls: ['./podashboard.component.css']
})
export class PODashboardComponent {
  @Input() weekModel: DashboardThisWeekModel;
  @Input() dashboardModel: DashboardOverviewModel;


  faUsers = faUsers;
  faClipboardCheck = faClipboardCheck;
  faLocationDot = faLocationDot;
}
