import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PropertyModel } from 'src/app/models/property.model';
import { faHouseMedical, faBorderAll, faTableList, faLocationDot, faBed, faHouse, faSquare } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.css']
})
export class PropertyComponent implements OnInit {
  faHouseMedical = faHouseMedical;
  faBorderAll= faBorderAll;
  faTableList = faTableList;
  faLocationDot = faLocationDot;
  faBed = faBed;
  faHouse = faHouse;
  faSquare = faSquare;

  propertiesModel: PropertyModel[];
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.onPropertiesRetrived(data.propertiesData);
    });
  }

  onPropertiesRetrived(properties: PropertyModel[]){
    console.log(properties)
    this.propertiesModel = properties;
  }

}
