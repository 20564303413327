import { Component, Input } from '@angular/core';
import { TimelineModel } from 'src/app/models/timeline.model';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent {

  @Input() model: TimelineModel[];

  ngOnInit(): void {


  }
}
