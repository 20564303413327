import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbCalendar, NgbDate, NgbDatepickerModule, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { faCalendar, faXmark, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FilterAssignmentModel } from 'src/app/models/filterAssignment.model';
import { UntypedFormControl } from '@angular/forms';
import { NgbDateUtilService } from 'src/app/shared/ngb-date-utilities/ngb-date-util.service';

@Component({
  selector: 'app-assignment-filters',
  templateUrl: './assignment-filters.component.html',
  styleUrls: ['./assignment-filters.component.css']
})
export class AssignmentFiltersComponent {

  @Input() model: FilterAssignmentModel;
  @Output() filterSelectedEvent: EventEmitter<FilterAssignmentModel> = new EventEmitter<FilterAssignmentModel>();


  // toppings = new FormControl('');
  // toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  faCalendar = faCalendar;
  faXmark = faXmark;
  faFilter = faFilter;

  closeResult: string;

  startDate: NgbDateStruct;
  endDate: NgbDateStruct;

  filterCount: number = 2;


  //fromDate: NgbDate = this.calendar.getToday();
  // toDate: NgbDate | null = this.calendar.getNext(this.fromDate, 'd', 30);

  constructor(private modalService: NgbModal, public formatter: NgbDateParserFormatter) { }

  ngOnInit(): void {

    this.startDate = new NgbDate(this.model.startDate.getFullYear(), this.model.startDate.getMonth() + 1, this.model.startDate.getDate());
    this.endDate = new NgbDate(this.model.endDate.getFullYear(), this.model.endDate.getMonth() + 1, this.model.endDate.getDate());


  }


  open(content) {
    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  appyFilters() {
    this.filterCount = 0;

    this.model.startDate = this.convertToDate(this.startDate);
    if (this.startDate) {
      this.filterCount++;
    }

    this.model.endDate = this.convertToDate(this.endDate);
    if (this.endDate) {
      this.filterCount++;
    }


    this.filterSelectedEvent.emit(this.model);
    this.modalService.dismissAll();
  }

  convertToDate(ngbDate: NgbDateStruct): Date {
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day + 1);
  }


}
