import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PropertyModel } from 'src/app/models/property.model';
import { PropertyModuleService } from './property-module.service';




@Injectable()
export class PropertiesResolver  {

  constructor(private propertyService: PropertyModuleService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PropertyModel> {
    
    return this.propertyService.getProperties().pipe(
      map(property => {
        if (property) {
          return property;
        }
        return null;
      }),
      catchError(error => {
        console.log(`Retrieval error: ${error}`);
        this.router.navigate(['/']);
        return of(null);
      })
    );
  }

}
