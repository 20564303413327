import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


import { AssignmentModel } from 'src/app/models/assignment.model';

import { AssignmentProcessModel } from 'src/app/models/assignmentProcess.model';
import { AssignmentDisplaytModel } from 'src/app/models/assignmentDisplay.model';
import { TimelineModel } from 'src/app/models/timeline.model';
import { AssignmentActionModel } from 'src/app/models/assignmentAction.model';
import { FilterAssignmentModel } from 'src/app/models/filterAssignment.model';



@Injectable({
  providedIn: 'root'
})
export class AssignmnetService {

  private serviceUrl = environment.apiUrl + 'Assignment';

  constructor(private http: HttpClient) { }


  getPropertyOwnerAssignments(filters: FilterAssignmentModel): Observable<AssignmentDisplaytModel[]> {
    return this.http.post<AssignmentDisplaytModel[]>(`${this.serviceUrl}/getPropertyOwnerAssignments`, filters, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getServiceProviderAssignments(filters: FilterAssignmentModel): Observable<AssignmentDisplaytModel[]> {
    return this.http.post<AssignmentDisplaytModel[]>(`${this.serviceUrl}/getServiceProviderAssignments`, filters, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getAssignmentProcess(id: string): Observable<AssignmentProcessModel> {
    return this.http.get<AssignmentProcessModel>(`${this.serviceUrl}/getAssignmentProcess/${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getAssignmentTimeline(id: string, amount: number, skip: number): Observable<TimelineModel[]> {
    return this.http.get<TimelineModel[]>(`${this.serviceUrl}/getAssignmentTimeline/${id}/?amount=${amount}&?skip=${skip}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  setAssignment(assignment: AssignmentModel): Observable<AssignmentModel> {
    return this.http.post<AssignmentModel>(`${this.serviceUrl}/setAssignment`, assignment, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }


  setAssignmentAction(model: AssignmentActionModel): Observable<AssignmentProcessModel> {
    {
      return this.http.post<AssignmentProcessModel>(`${this.serviceUrl}/setAssignmentAction`, model, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });
    }
  }

  calculateAssignmentPriceRate(model: AssignmentModel): Observable<AssignmentModel> {
    {
      return this.http.post<AssignmentModel>(`${this.serviceUrl}/calculateAssignmentPriceRate`, model, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });
    }
  }


}
