import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AssignmnetService } from '../assignmnet.service';
import { AssignmentProcessModel } from 'src/app/models/assignmentProcess.model';


@Injectable()
export class AssignmentProcessResolver  {

  constructor(private assignmentService: AssignmnetService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AssignmentProcessModel> {
    const id = route.params.id;
    //console.log('retrived id: ' + id);
    return this.assignmentService.getAssignmentProcess(id).pipe(
      map(model => {
        if (model) {
          return model;
        }
        console.log(`No assignments was found`);
        return null;
      }),
      catchError(error => {
        console.log(`Retrieval error: ${error}`);
        this.router.navigate(['/']);
        return of(null);
      })
    );
  }

}
