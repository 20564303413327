import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { OrderService } from '../../order.service';
import { ProfessionalOrderDetailsModel } from 'src/app/models/professionalOrderDetails.model';
import { ProfessionalOrderModel } from 'src/app/models/professionalOrder.model';



@Injectable()
export class ProfessionalOrderDetailsResolver  {

  constructor(private orderService: OrderService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProfessionalOrderModel> {

    const id = route.params.orderid;

    return this.orderService.getOrder(id).pipe(
      map(orderModel => {
        if (orderModel) {
          return orderModel;
        }
        console.log(`No Order was found`);
        this.router.navigate(['/reservations']);
        return null;
      }),
      catchError(error => {
        console.log(`Retrieval error: ${error}`);
        this.router.navigate(['/reservations']);
        return of(null);
      })
    );

  }
}
