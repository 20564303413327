<div class="container">
    <div class="row">
        <div class="col-12">
            <section>
                <h2 class="display-4 my-5">Team Invitations</h2>
                <div class="row" *ngFor="let invite of invitations">
                    <div class="col-12 col-lg-4 mb-3 mb-sm-0">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Invitation from <strong>{{invite.businessName}}</strong></h5>
                                <p class="card-text">{{invite.profileCard.firstName}} {{invite.profileCard.lastName}}
                                    has
                                    invited you to their team.</p>
                                <a [routerLink]="['/teams/invitation/', invite.id]"
                                    class="btn btn-obrame-primary"><fa-icon [icon]="faClipboardUser"
                                        [fixedWidth]="true" class="fixed"></fa-icon> Review</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <h2 class="display-4 my-5">My Teams</h2>
                <div class="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 g-4">
                    <div class="col" *ngFor="let member of tableModel.members">
                        <div class="card">
                            <div class="card-header d-flex justify-content-between">
                                <h5 class="card-title p-2">{{member.businessTitle}}</h5>
                                <div ngbDropdown container="body">
                                    <button type="button" class="btn btn-obrame-secondary btn-sm" ngbDropdownToggle>
                                        <fa-icon [icon]="faEllipsis" [fixedWidth]="true" class="fixed"></fa-icon>
                                    </button>
                                    <div ngbDropdownMenu>
                                        <button (click)="onTeamCancellation(member)" ngbDropdownItem><fa-icon
                                                [icon]="faUserXmark" size="lg" [fixedWidth]="true"
                                                class="fixed px-2"></fa-icon>
                                            Leave Team</button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">

                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item d-flex justify-content-between align-items-start">
                                        <span class="text-muted"><fa-icon [icon]="faCalendarPlus" size="lg"
                                                [fixedWidth]="true" class="fixed px-2"></fa-icon> New requests</span>
                                        <span class="badge badge-obrame rounded-pill">1</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-start">
                                        <span class="text-muted"><fa-icon [icon]="faClipboard" size="lg"
                                                [fixedWidth]="true" class="fixed px-2"></fa-icon> Open tasks</span>
                                        <span class="badge badge-obrame rounded-pill">4</span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        </div>
    </div>
</div>