import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { ProfileService } from 'src/app/profile/profile.service';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.css']
})
export class ProfileInfoComponent {
  faBars = faBars;
  profilePictureUrl: string;
  errorMessage = '';

  constructor(private profileService: ProfileService) { }

  ngOnInit(): void {
        this.getProfilePictureUrl();
  }

  getProfilePictureUrl() { 
    this.profileService.getProfilePicture("icon").subscribe(
      (data : any) => this.profilePictureUrl = data.url,
      (error: HttpErrorResponse) => this.onError(error)
    );
  }

  onError(error: HttpErrorResponse) {
    // this.hideErrorMessage = false;
    // this.isLoading = false;
    if (error.status === 400) {
      this.errorMessage = error?.error[0]?.description;
    } else if (error.status === 500 || error.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
    console.log(error);
  }
}
