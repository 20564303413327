<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 class="display-4 my-5">Assignments</h1>
            <section class="mt-5">
                <div class="d-flex justify-content-between flex-column flex-md-row obrame-action-row">
                    <div class="p-2 order-2 order-md-0">
                        <div class="d-flex btn-group me-2">
                            <button type="button" class="btn btn-obrame-secondary">
                                <fa-icon [icon]="faList" [fixedWidth]="true" class="fixed"></fa-icon> List View
                            </button>
                            <button type="button" class="btn btn-obrame-secondary">
                                <fa-icon [icon]="faCalendarDays" [fixedWidth]="true" class="fixed"></fa-icon> Calendar
                            </button>
                        </div>
                    </div>
                    <div class="me-auto p-2 order-1">
                        <app-assignment-filters [model]="filter"
                            (filterSelectedEvent)="onApplyFilter($event)"></app-assignment-filters>
                    </div>
                    <div class="p-2 order-0 order-md-2">
                        <a class="btn btn-obrame-primary" [routerLink]='["/assignments/assignment"]'>
                            <fa-icon [icon]="faClipboardList" class="px-2"></fa-icon>
                            New Assignment
                        </a>
                    </div>
                </div>
            </section>
            <section class="py-4">
                <div class="row row-cols-1 row-cols-md-3 g-4" *ngIf="assignmentsModel">
                    <div class="col" *ngFor="let model of assignmentsModel">
                        <div class="card obrame-card h-100">
                            <div class="card-body">
                                <a [routerLink]='["../assignment", model.id]'>
                                    <h5 class="card-title text-center mb-4 px-4">{{model.propertyAddress}}</h5>
                                    <div class="mb-3">
                                        <fa-icon [icon]="faUser" [fixedWidth]="true" class="fixed" size="lg">
                                        </fa-icon>
                                        <span class="mx-4">{{model.teamName}}</span>
                                    </div>
                                    <div class="mb-3">
                                        <fa-icon [icon]="faCalendar" [fixedWidth]="true" class="fixed"
                                            size="lg"></fa-icon>
                                        <span class="mx-4">{{model.assignmentDate}}</span>
                                    </div>
                                    <div class="mb-3">
                                        <fa-icon [icon]="faClock" [fixedWidth]="true" class="fixed" size="lg"></fa-icon>
                                        <span class="mx-4">{{model.startTime}} ({{model.duration}} h)</span>
                                    </div>

                                    <div class="mb-3">
                                        <fa-icon [icon]=" faHourglassHalf" [fixedWidth]="true" class="fixed mr-2"
                                            size="lg">
                                        </fa-icon>
                                        <span class="mx-4">{{model.status}}</span>
                                    </div>

                                </a>
                            </div>
                            <div class="card-footer">
                                <small class="text-muted">Last updated {{model.updatedOn}}</small>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
        </div>
    </div>
</div>