import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssignmentProcessModel } from 'src/app/models/assignmentProcess.model';
import { TimelineModel } from 'src/app/models/timeline.model';
import { AssignmnetService } from '../assignmnet.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AssignmentActionModel } from 'src/app/models/assignmentAction.model';
import { ModalDismissReasons, NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-assignment-process',
  templateUrl: './assignment-process.component.html',
  styleUrls: ['./assignment-process.component.css']
})
export class AssignmentProcessComponent {
  model: AssignmentProcessModel;

  assignmentId: string;
  amountOfTimelineEvents = 10;
  skipAmountOfTimelineEvents = 0;

  isAcceptLoading = false;
  isDeclineLoading = false;
  isCancelLoading = false;
  isLoading = false;
  hideErrorMessage = true;
  errorMessage = "";
  actionMessage = "";
  declineCtrl = new UntypedFormControl('', [Validators.required]);
  modalReference: NgbModalRef;
  closeResult = "";

  constructor(private route: ActivatedRoute, private assignmentService: AssignmnetService, private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    const id: Observable<string> = this.route.params.pipe(map(p => p.id));
    id.subscribe(param => { this.assignmentId = param; });

    this.route.data.subscribe(data => {
      this.onAssignmentsReceived(data.assignmentData);
    });
  }

  onAssignmentsReceived(assignment: AssignmentProcessModel) {
    this.model = assignment;
    console.log(this.model);
    if (this.assignmentId) {
      this.getTimelineData();
      this.isAcceptLoading = false;
      this.isDeclineLoading = false;
      this.isCancelLoading = false;
    }
  }

  getTimelineData() {
    if (this.assignmentId) {
      this.assignmentService.getAssignmentTimeline(this.assignmentId, this.amountOfTimelineEvents, this.skipAmountOfTimelineEvents).subscribe(
        timelineEvents => {
          this.model.timelineModel = timelineEvents
          console.log(timelineEvents);
          // this.hideLoginError = false;
        },
        (error: any) => this.onError(error)
      );
    }
  }

  onAssignmentAction(action: string) {

    switch (action) {
      case 'accept':
        this.actionMessage = "";
        this.isAcceptLoading = true;
        break;
      case 'decline':
        this.actionMessage = this.declineCtrl.value;
        this.isDeclineLoading = true;
        break;
      case 'cancel':
        this.actionMessage = this.declineCtrl.value;
        this.isCancelLoading = true;
        break;
      default:
        this.actionMessage = "";
        break;
    }

    const actionModel = new AssignmentActionModel(this.assignmentId, action, this.actionMessage);

    this.assignmentService.setAssignmentAction(actionModel).subscribe(
      (data: AssignmentProcessModel) => { this.onAssignmentsReceived(data); this.modalReference.close(); },
      (error: any) => this.onError(error)
    );
  }

  openDeclineModal(content) {
    this.modalReference = this.modalService.open(content, { size: 'lg' });
    // this.modalReference.result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
  }


  onError(error: HttpErrorResponse) {
    this.hideErrorMessage = false;
    this.isLoading = false;
    if (error.status === 400) {
      this.errorMessage = error?.error[0]?.description;
    } else if (error.status === 500 || error.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
    console.log(error);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
    }

}
