import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ProfileModel } from '../models/profile.model';
import { ChangePasswordModel } from '../models/changePassword.model';
import { AddressModel } from '../models/address.model';
import { environment } from 'src/environments/environment';
import { SelectModel } from '../models/select.model';
import { ProfileProgressModel } from '../models/profileProgress.model';
import { BusinessModel } from '../models/business.model';


@Injectable()
export class ProfileService {

  private serviceUrl = environment.apiUrl + 'profile';
  private serviceUrlbiz = environment.apiUrl + 'business';

  constructor(private http: HttpClient) { }

  getProfile(): Observable<ProfileModel> {
    return this.http.get<ProfileModel>(this.serviceUrl + '', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getBusiness(): Observable<BusinessModel> {
    return this.http.get<BusinessModel>(this.serviceUrlbiz, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  setBusiness(newbiz: BusinessModel): Observable<BusinessModel> {
    return this.http.post<BusinessModel>(`${this.serviceUrlbiz}`, newbiz, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  setProfile(model: ProfileModel): Observable<ProfileModel> {
    return this.http.post<ProfileModel>(this.serviceUrl + '', model, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  setProfilePicture(model: FormData): Observable<void> {
    return this.http.post<void>(this.serviceUrl + '/setProfilePicture', model, {
      reportProgress: true
    });
  }

  getProfilePicture(pictureMode: string): Observable<string> {
    return this.http.get<string>(this.serviceUrl + '/getProfilePicture?pictureMode=' + pictureMode, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })

    });
  }

  getProfilePictureById(userId, pictureMode: string): Observable<string> {
    return this.http.get<string>(`${this.serviceUrl}/${userId}/${pictureMode}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })

    });
  }

  setPassword(model: ChangePasswordModel): Observable<void> {
    return this.http.post<void>(this.serviceUrl + '/SetPassword', model, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getProfileProgress(): Observable<ProfileProgressModel> {
    return this.http.get<ProfileProgressModel>(this.serviceUrl + '/getProfileProgress', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

}
