import { ResolveFn } from '@angular/router';
import { SubscriptionService } from './subscription.service';
import { inject } from '@angular/core';
import { Observable, catchError, map, of, pipe } from 'rxjs';
import { SubscriptionTemplateModel } from 'src/app/models/subscriptionTemplate.model';
import { SubscriptionGroupModel } from 'src/app/models/subscriptionGroupModel.model';

export const SubscriptionTemplateResolver: ResolveFn<SubscriptionGroupModel[]> = (
  route,
  state,
  service: SubscriptionService = inject(SubscriptionService)): Observable<SubscriptionGroupModel[]> =>

  service.getSubscriptionTemplateByModule(route.paramMap.get('id')).pipe(
    map(service => {
      if (service) {
        return service;
      }
      console.log(`No onboarding service was found`);
      return null;
    }),
    catchError(error => {
      console.log(`Retrieval error: ${error}`);
      return of(null);
    })
);