<div class="container py-5">
    <div class="row">
        <div class="col-12">
            <h1 class="display-4">My Assignments</h1>
            <section class="mt-5">
                <div class="d-flex justify-content-between flex-column flex-md-row obrame-action-row">
                    <div class="p-2 order-2 order-md-0">
                        <div class="d-flex btn-group me-2">
                            <button type="button" class="btn btn-obrame-secondary">
                                <fa-icon [icon]="faList" [fixedWidth]="true" class="fixed"></fa-icon> List View
                            </button>
                            <button type="button" class="btn btn-obrame-secondary">
                                <fa-icon [icon]="faCalendarDays" [fixedWidth]="true" class="fixed"></fa-icon> Calendar
                            </button>
                        </div>
                    </div>
                    <div class="me-auto p-2 order-1">
                        <app-assignment-filters [model]="filter"
                            (filterSelectedEvent)="onApplyFilter($event)"></app-assignment-filters>
                    </div>
                </div>
                <!-- <div
                    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-3 mb-4 obrame-action-row">
                    <div class="btn-toolbar mb-2 mb-md-0">
                        <div class="btn-group me-2">
                            <button type="button" class="btn btn-obrame-secondary">
                                <fa-icon [icon]="faList" [fixedWidth]="true" class="fixed"></fa-icon> List View
                            </button>
                             <button type="button" class="btn btn-obrame-secondary">
                                <fa-icon [icon]="faCalendarDays" [fixedWidth]="true" class="fixed"></fa-icon> Calendar
                            </button> 
                        </div>
                    </div> 
                </div>-->
            </section>
            <section class="py-1">
                <div class="row row-cols-1 row-cols-md-3 g-4" *ngIf="assignmentsModel">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Time</th>
                                <th scope="col">Address</th>
                                <th scope="col">Team</th>
                                <th scope="col">Status</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let assignment of assignmentsModel; index as i">
                                <th scope="row">{{ assignment.assignmentDate }}</th>
                                <td>{{assignment.startTime}}</td>
                                <td>{{ assignment.propertyAddress }}</td>
                                <td>{{ assignment.teamName }}</td>
                                <td>{{ assignment.status }}</td>
                                <td class="overflow-hidden">
                                    <div ngbDropdown container="body" placement="bottom-right" display="dynamic">
                                        <button type="button" class="btn btn btn-obrame-secondary btn-sm"
                                            ngbDropdownToggle>
                                            <fa-icon [icon]="faEllipsis" [fixedWidth]="true" class="fixed"></fa-icon>
                                        </button>
                                        <div ngbDropdownMenu>
                                            <button [routerLink]="['/assignments/assignment', assignment.id]"
                                                ngbDropdownItem>View</button>
                                            <button ngbDropdownItem>Accept</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    </div>
</div>