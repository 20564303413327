import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';

import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { AppComponent } from './app.component';

import { AccountModule } from './account/account.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './shared/auth-guard.service';

import { ProfileModule } from './profile/profile.module';
import { ProfessionalModule } from './professional/professional.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { OrdersModule } from './orders/orders.module';
import { InboxModule } from './inbox/inbox.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from './shared/shared.module';
import { NgbDateSpanishFormatterService } from './shared/ngb-date-utilities/ngb-date-spanish-formatter.service';
import { NgbDateObrameAdapterService } from './shared/ngb-date-utilities/ngb-date-obrame-adapter.service';
import { PrivateModule } from './private/private.module';
import { TopNavComponent } from './navigation/top-nav/top-nav.component';
import { LeftNavComponent } from './navigation/left-nav/left-nav.component';



@NgModule({ declarations: [
        AppComponent,
        TopNavComponent,
        LeftNavComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        GoogleMapsModule,
        AccountModule,
        PrivateModule,
        ProfileModule,
        ProfessionalModule,
        OrdersModule,
        InboxModule,
        SharedModule,
        AppRoutingModule,
        NgbModule,
        BrowserAnimationsModule,
        FontAwesomeModule], providers: [AuthGuard, provideHttpClient(withInterceptorsFromDi()),] })
export class AppModule { }
