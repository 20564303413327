import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { ProfileModel } from '../../models/profile.model';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '../profile.service';
import { faPenToSquare, faFloppyDisk, faRectangleXmark } from '@fortawesome/free-solid-svg-icons';
import { BusinessModel } from 'src/app/models/business.model';
import { ContentService } from 'src/app/shared/content.service';
import { SelectModel } from 'src/app/models/select.model';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { ToastService } from 'src/app/shared/toast/toast.service';
@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
})
export class ProfileInfoComponent implements OnInit {

  faPenToSquare = faPenToSquare;
  faFloppyDisk = faFloppyDisk;
  faRectangleXmark = faRectangleXmark;

  model: ProfileModel;
  bizModel: BusinessModel;
  countriesModel: SelectModel[];

  isPersonalLoading = false;
  isPersonalReadOnlyMode = true;
  isBusinessLoading = false;
  isBusinessReadOnlyMode = true;
  hideErrorMessage = true;


  profileForm: UntypedFormGroup;
  businessForm: UntypedFormGroup;


  errorMessage = "We found an unexpected error has occurred. Please try again later or contact support for assistance."

  constructor(private route: ActivatedRoute, private profileService: ProfileService, private contentService: ContentService,
    private fb: UntypedFormBuilder, private toastService: ToastService) {
    this.model = this.route.snapshot.data.profileInfo;
  }


  ngOnInit(): void {

    this.route.data.subscribe(({ profileInfoData }) => {
      this.onProfileRetrived(profileInfoData);
    });

    this.getBusinessData();
    this.getCountries();
  }

  generatePersonalForm(profileModel: ProfileModel) {
    this.profileForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(profileModel.firstName, [Validators.required, Validators.minLength(3)]),
      lastName: new UntypedFormControl(profileModel.lastName, [Validators.required, Validators.minLength(3)]),
      email: new UntypedFormControl(profileModel.email, [Validators.required, Validators.email]),
    });

  }

  generateBusinessForm(bizModel: BusinessModel) {

    this.businessForm = this.fb.group({
      name: new UntypedFormControl(bizModel.name, [Validators.required, Validators.minLength(3)]),
      legalName: new UntypedFormControl(bizModel.legalName),
      taxId: new UntypedFormControl(bizModel.taxId),
      personalId: new UntypedFormControl(bizModel.personalId),
      streetAddress: new UntypedFormControl(bizModel.streetAddress, [Validators.required, Validators.minLength(3)]),
      city: new UntypedFormControl(bizModel.city, [Validators.required, Validators.minLength(3)]),
      province: new UntypedFormControl(bizModel.province, [Validators.minLength(3)]),
      postalCode: new UntypedFormControl(bizModel.postalCode, [Validators.required, Validators.minLength(3)]),
      countryId: new UntypedFormControl(bizModel.countryId),
      country: new UntypedFormControl(bizModel.country),
      phoneNumber: new UntypedFormControl(bizModel.phoneNumber),
      website: new UntypedFormControl(bizModel.website, [Validators.pattern("^(https?:\/\/)?([a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$"), Validators.minLength(3)]),
      countryOfResidencyId: new UntypedFormControl(bizModel.countryOfResidencyId),
      countryOfResidency: new UntypedFormControl(bizModel.countryOfResidencyName),
      isCompanyCtrl: new UntypedFormControl(bizModel.isCompany ? "company" : "person", [Validators.required])
    });
    //Setting validation based on the state of the form
    if (bizModel.isCompany) {
      this.setBusinessValidation();
    }else{
      this.setPersonValidation();
    }

  }
  getBusinessData() {
    this.profileService.getBusiness().pipe(
      tap(_ => { console.log("Getting buisness info."); this.isBusinessLoading = true; }),
      map(response => this.onBusinessDataReceived(response)),
      catchError(this.handleError<BusinessModel>('setSubscription'))
    ).subscribe();
  }

  onSetBusinessData() {
    this.isBusinessLoading = true;
    const b = Object.assign({}, this.bizModel, this.businessForm.value);
    this.profileService.setBusiness(b).pipe(
      tap(_ => { console.log("Setting buisness info." + b); this.isBusinessLoading = true; }),
      map(response => this.onBusinessDataReceived(response)),
      catchError(this.handleError<BusinessModel>('setSubscription'))
    ).subscribe();
  }

  onBusinessDataReceived(response: BusinessModel): any {
    this.isBusinessLoading = false;
    this.isBusinessReadOnlyMode = true;
    this.bizModel = response;
    this.generateBusinessForm(this.bizModel);
    //this.toastService.show("Business data saved successfully.", { classname: 'obrame-toast-success', delay: 4000 });
  }

  onProfileRetrived(profile: ProfileModel): any {
    this.isPersonalLoading = false;
    this.isPersonalReadOnlyMode = true;
    this.model = profile;
    this.generatePersonalForm(this.model);
  }

  onPhoneVerificationCompleted(isValidated: boolean) {
    if (isValidated) {
      this.getProfile();
    }
  }
  private getProfile() {
    this.profileService.getProfile().pipe(
      tap(_ => { console.log("Getting profile info."); this.isPersonalLoading = true; }),
      map(response => this.onProfileRetrived(response)),
      catchError(this.handleError<ProfileModel>('GetProfile'))
    ).subscribe();
  }

  onPersonalSave(): void {
    this.isPersonalLoading = true;
    const p = Object.assign({}, this.model, this.profileForm.value);

    this.profileService.setProfile(p).pipe(
      tap(_ => { console.log("Setting personal info."); this.isBusinessLoading = true; }),
      map(response => this.onPersonalCompleted(response)),
      catchError(this.handleError<ProfileModel>('SetProfile'))
    ).subscribe();
  }
  onPersonalCompleted(profile: ProfileModel): any {
    this.onProfileRetrived(profile);
   // this.toastService.show("Business data saved successfully.", { classname: 'obrame-toast-success', delay: 4000 });
  }
  getCountries(): void {
    this.contentService.getCountires().pipe(
      tap(_ => { console.log("Getting country data.") }),
      map(response => this.countriesModel = response),
      catchError(this.handleError<SelectModel[]>('getCountries.'))
    ).subscribe();
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      this.hideErrorMessage = false;
      this.isPersonalLoading = false;
      this.isBusinessLoading = false;

      //Refreshing the data
      this.getBusinessData();
      this.getProfile();

      //Toast
      this.toastService.show(error?.error, { classname: 'obrame-toast-error', delay: 4000 });

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.error}`);

      if (error.status === 400) {
        this.errorMessage = error.error;
      } else if (error.status === 500 || error.status === 0) {
        this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  setBusinessEditMode() {
    this.isPersonalLoading = false;
    if (this.isBusinessReadOnlyMode) {
      this.isBusinessReadOnlyMode = false;
    } else {
      this.isBusinessReadOnlyMode = true;
      this.generateBusinessForm(this.bizModel);
    }
  }
  setPersonalEditMode() {
    this.isPersonalLoading = false;
    if (this.isPersonalReadOnlyMode) {
      this.isPersonalReadOnlyMode = false;
    } else {
      this.isPersonalReadOnlyMode = true;

    }
  }
  onChangeCompanyOrPerson(e) {
    if (!this.isBusinessReadOnlyMode) {
      if (e.target.value === "company") {
        this.bizModel.isCompany = true;
        this.setBusinessValidation();
      } else {
        this.bizModel.isCompany = false;
        this.setPersonValidation();
      }
    }
  }

  setBusinessValidation() {
    this.businessForm.controls['legalName'].setValidators([Validators.required, Validators.minLength(3)]);
    this.businessForm.controls['taxId'].setValidators([Validators.required, Validators.minLength(3)]);
    this.businessForm.controls['countryId'].setValidators([Validators.required]);

    this.businessForm.controls['personalId'].clearValidators();
    this.businessForm.controls['personalId'].setErrors(null);
    this.businessForm.controls['countryOfResidencyId'].clearValidators();
    this.businessForm.controls['countryOfResidencyId'].setErrors(null);

  }
  setPersonValidation() {
    this.businessForm.controls['personalId'].setValidators([Validators.required, Validators.minLength(3)]);
    this.businessForm.controls['countryOfResidencyId'].setValidators([Validators.required]);

    this.businessForm.controls['legalName'].clearValidators();
    this.businessForm.controls['legalName'].setErrors(null);
    this.businessForm.controls['taxId'].clearValidators();
    this.businessForm.controls['taxId'].setErrors(null);
    this.businessForm.controls['countryId'].clearValidators();
    this.businessForm.controls['countryId'].setErrors(null);
  }


}
