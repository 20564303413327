import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faClipboardUser, faUsers, faUserPlus, faEllipsis, faEnvelope, faClipboard, faCalendarPlus, faUsersViewfinder, faXmark, faUserXmark } from '@fortawesome/free-solid-svg-icons';
import { TeamMemberTableModel } from 'src/app/models/teamMemberTable.model';


import { HttpErrorResponse } from '@angular/common/http';
import { TeamMemberModel } from 'src/app/models/teamMember.model';
import { TeamsService } from '../teams.service';
import { TeamInvitationModel } from 'src/app/models/teamInvitation.model';
import { Observable, catchError, map, of, tap } from 'rxjs';

@Component({
  selector: 'app-my-teams',
  templateUrl: './my-teams.component.html',
  styleUrls: ['./my-teams.component.css']
})
export class MyTeamsComponent {
  faUserPlus = faUserPlus;
  faClipboardUser = faClipboardUser;
  faUsers = faUsers;
  faEnvelope = faEnvelope;
  faEllipsis = faEllipsis;
  faClipboard = faClipboard;
  faCalendarPlus = faCalendarPlus;
  faUsersViewfinder = faUsersViewfinder;
  faXmark = faXmark;
  faUserXmark = faUserXmark;


  tableModel: TeamMemberTableModel;
  invitations: TeamInvitationModel[];


  isLoading = false;
  hideErrorMessage = true;
  errorMessage = "";
  currentPage = 1;
  pageSize = 10;


  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private teamService: TeamsService) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.tableModel = data.teamsData;
    });

    this.getTeamInvites();

  }

  getTeams() {
    this.teamService.getTeams(this.currentPage, this.pageSize).pipe(
      tap(_ => { console.log("Getting members"); this.isLoading = true; }),
      map(response => this.onTeamsDataComplete(response)),
      catchError(this.handleError<TeamMemberTableModel>("GetTeams"))
    ).subscribe();
  }

  onTeamsDataComplete(data: TeamMemberTableModel): void {
    this.isLoading = false;
    this.tableModel = data;
  }

  getTeamInvites() {
    this.teamService.getMyInvitations().pipe(
      tap(_ => { console.log("Getting invitations"); this.isLoading = true; }),
      map(response => this.onTeamInvitationsDataComplete(response)),
      catchError(this.handleError<TeamMemberTableModel>("GetTeams"))
    ).subscribe();
  }

  onTeamInvitationsDataComplete(data: TeamInvitationModel[]): void {
    this.invitations = data;
    this.isLoading = false;

  }



  onTeamCancellation(membership: TeamMemberModel) {
    this.teamService.setTeamMembershipCancellation(membership).pipe(
      tap(_ => { console.log("Getting invitations"); this.isLoading = true; }),
      map(_ => this.getTeams()),
      catchError(this.handleError<TeamMemberTableModel>("SetMembershipCancellation"))
    ).subscribe();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);


      this.hideErrorMessage = false;
      this.errorMessage = error.message;

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  onError(error: HttpErrorResponse): void {
    this.hideErrorMessage = false;
    this.isLoading = false;
    if (error.status === 400) {
      this.errorMessage = error?.error[0]?.description;
    } else if (error.status === 500 || error.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
    console.log(error);
  }

}