import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModuleServiceModel } from 'src/app/models/moduleService.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceProviderService {

  private serviceUrl = environment.apiUrl + 'ModuleService';

  constructor(private http: HttpClient) { }

  getModuleService(): Observable<ModuleServiceModel> {
    return this.http.get<ModuleServiceModel>(`${this.serviceUrl}/getModuleService`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  setModuleService(newModuleService: ModuleServiceModel): Observable<ModuleServiceModel> {
    return this.http.post<ModuleServiceModel>(`${this.serviceUrl}/setModuleService`, newModuleService, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
}
