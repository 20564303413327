import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionGroupModel } from 'src/app/models/subscriptionGroupModel.model';
import { SubscriptionTemplateModel } from 'src/app/models/subscriptionTemplate.model';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent {

  models: SubscriptionGroupModel[];
  selectedTabId = "";
  faInfo = faInfo;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ subscriptionTemplates }) => {
      this.models = subscriptionTemplates;
      this.selectedTabSelector(this.models);
    })
  }
  selectedTabSelector(tabs: SubscriptionGroupModel[]){
    tabs.forEach(element => {
      console.log(element);
      if(element.isSelected){
        this.selectedTabId = element.id;
      }
    });
  }
}
