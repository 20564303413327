<div class="container py-5">
    <div class="row">
        <div class="col-12">
            <section class="">
                <h1 class="display-4 text-center my-5">Invite Team Members to <span
                        class="obrame-txt-hightlight-yellow">Obrame</span></h1>

                <div class="row pt-5">
                    <div class="col-12 col-lg-2 text-center">
                        <div class="col-12 col-lg-6 mb-5">
                            <div
                                class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                                <fa-icon [icon]="faInfo" size="2x"></fa-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-10">
                        <p>
                            Managing properties is all about teamwork. Obrame will help you to invite people
                            from your
                            team to your workspace so you can communicate, assign tasks, and handle payments. You can
                            invite your team members using their email addresses.
                        </p>
                    </div>
                </div>
            </section>

            <section>
                <div class="row">
                    <div class="col-12 col">

                        <form novalidate [formGroup]="emailInvitationForm">
                            <div class="d-flex justify-content-center">
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="floatingInputGrid">Email address</label>
                                    <input type="email" class="form-control form-obrame-input" id="floatingInputGrid"
                                        formControlName="email"
                                        [ngClass]="{'is-invalid': emailInvitationForm.get('email').dirty && !emailInvitationForm.get('email').valid }">

                                    <div class="invalid-feedback">Please check the format of the email.</div>
                                    <div id="emailHelpBlock" class="form-text">
                                        You can invite people to join your Business Account by entering their email
                                        address.
                                    </div>

                                    <div class="row justify-content-center mt-5 mb-5">

                                        <button type="submit" class="btn btn-obrame-primary" (click)="sendInvitation()"
                                            [disabled]="!emailInvitationForm.valid || !emailInvitationForm.dirty || isLoading">
                                            Invite
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true" *ngIf="isLoading"></span>
                                        </button>
                                    </div>

                                    <app-error-alert [errorMessage]="errorMessage"
                                        [hideError]="hideErrorMessage"></app-error-alert>

                                    <div class="alert alert-success" role="alert" [hidden]="hideSuccessMessage">
                                        <div
                                        class="d-inline-flex align-items-center justify-content-center p-4 me-3 fs-4 flex-shrink-0 rounded-circle obrame-login-icon-bg">
                                        <fa-icon [icon]="faEnvelope"></fa-icon>
                                    </div>
                                        <span [innerHTML]="successMessage"></span>
                                    </div>
                                </div>
                            </div>


                            <div class="row mt-5">
                                <div class="col-12 col-lg-9">



                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

        </div>
    </div>

</div>