import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProfessionalOrderListModel } from 'src/app/models/professionalOrderList.model';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ProfessionalService } from '../professional.service';
import { ProfessionalOrderAcceptanceModel } from 'src/app/models/professionalOrderAcceptance.model';
import { Router } from '@angular/router';


@Component({
  selector: 'app-order-action-modal',
  templateUrl: './order-action-modal.component.html',
  styleUrls: ['./order-action-modal.component.css']
})
export class OrderActionModalComponent implements OnInit {

  @Input() orderModel: ProfessionalOrderListModel;
  @Input() isCommentMode: boolean;
  @Output() actionPressed = new EventEmitter<ProfessionalOrderAcceptanceModel>();

  closeResult: string;
  modelStateText: string;
  isJobAccepted: boolean;

  orderActionForm: UntypedFormGroup;
  commentsControl: UntypedFormControl;

  constructor(private modalService: NgbModal, private fb: UntypedFormBuilder, private router: Router) { }
  ngOnInit(): void {
    this.commentsControl = new UntypedFormControl('', [Validators.required]);

    this.orderActionForm = this.generateActionForm();
  }

  generateActionForm(): UntypedFormGroup {
    return this.fb.group({
      comments: this.commentsControl
    });
  }

  onOrderAction(content, isAccepted: boolean) {
    if (isAccepted) {
      this.modelStateText = 'Acepta';
      this.isJobAccepted = true;
    } else {
      this.modelStateText = 'Rechazar';
      this.isJobAccepted = false;
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.onModalAction();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    console.dir(this.closeResult);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onModalAction() {
    const acceptanceModel = new ProfessionalOrderAcceptanceModel(this.orderModel.id, this.isJobAccepted, this.commentsControl.value, '');
    this.actionPressed.emit(acceptanceModel);
  }

  onTransferToDiscussion() {
    this.router.navigateByUrl('/inbox/discussion/' + this.orderModel.discussionId);
  }

}
