<div class="container py-5">
    <div class="row">
        <div class="col-12">
            <section>
                <h2 class="display-4">Service Provider - <span class="obrame-txt-hightlight-yellow">Obrame</span>
                    Profile</h2>
                <div class="row mt-5">
                    <div class="col-12 col-lg-2 text-center">
                        <div class="col-12 col-lg-6 mb-5">
                            <div
                                class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                                <fa-icon [icon]="faInfo" size="2x"></fa-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-10">
                        <p>
                            Welcome to your Service Provider profile page, where you can effortlessly tailor your
                            professional profile. Craft a compelling bio to showcase your expertise, set your hourly
                            rate with confidence, and securely manage your banking details, including your IBAN number.
                            Your profile is your digital storefront, so make it stand out and attract clients who value
                            your skills and dedication.
                        </p>
                    </div>
                </div>
            </section>

            <section class="mt-5" *ngIf="model">
                <div class="position-relative" *ngIf="!isEditMode">
                    <div class="position-absolute top-0 end-0">
                        <button class="btn btn-obrame-primary" (click)="setEditMode()">
                            <fa-icon [icon]="faPenToSquare" class="px-2"></fa-icon>
                            Edit
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-lg-2">
                        <app-profile-picture [userId]="model.userId" [readMode]="false"></app-profile-picture>
                    </div>
                    <div class="col-md-8 col-lg-8" *ngIf="model">
                        <h2 class="lh-1 mb-3">{{model.firstname}} {{model.lastname}}</h2>

                        <ng-template [ngIf]="!isEditMode">
                            <dl class="row mt-5">
                                <dt class="col-sm-3">Bio</dt>
                                <dd class="col-sm-9">{{model.bioText}}</dd>

                                <dt class="col-sm-3 pt-4">Fixed Hour Rate:</dt>
                                <dd class="col-sm-9 pt-4">
                                    {{model.fixedHourlyPrice}} €/h
                                </dd>
                            </dl>
                        </ng-template>
                        <ng-template [ngIf]="isEditMode">
                            <form novalidate (ngSubmit)="onSave()" [formGroup]="moduleForm">
                                <div class="pt-5">
                                    <label class="form-label" for="inputBioText">Tell about yourself</label>
                                    <textarea class="form-control form-obrame-input" 
                                        id="inputBioText" aria-describedby="bioHelp" style="height: 100px"
                                        formControlName="bioText" maxlength="256"></textarea>

                                    <small id="bioHelp" class="form-text text-muted">
                                        Tell us about yourself in your own words. 256/{{!bioText.value ? 0
                                        :bioText.value?.length}}
                                    </small>
                                </div>


                                <div class="mt-5">
                                    <label class="form-label" for="inputPrice">Fixed Hour Rate (€/h):</label>
                                    <input type="number" class="form-control form-obrame-input"
                                        id="inputPrice" placeholder="€/h" value=""
                                        formControlName="fixedHourlyPrice" aria-describedby="priceNHelp"
                                        [ngClass]="{'is-invalid': fixedHourlyPrice.invalid && (fixedHourlyPrice.dirty || fixedHourlyPrice.touched)}">
                                   
                                    <small id="priceNHelp" class="form-text text-muted">
                                        This is your hourly price for your services. You can change it anytime.
                                    </small>
                                    <div class="invalid-feedback">Invalid price.</div>
                                </div>

                                <div class="d-grid gap-2 col-8 mx-auto mt-5 pt-5">
                                    <app-error-alert [errorMessage]="errorMessage"
                                        [hideError]="hideErrorMessage"></app-error-alert>

                                    <button type="submit" class="btn btn-obrame-primary py-2"
                                        [disabled]="!moduleForm.valid || !moduleForm.dirty || isLoading">
                                        Save
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                            *ngIf="isLoading"></span>
                                    </button>
                                    <button type="button" class="btn btn-secondary btn-obrame-secondary"
                                        (click)="setEditMode()">
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </ng-template>
                    </div>


                </div>
            </section>

        </div>
    </div>
</div>