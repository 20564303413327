import { ResolveFn } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { inject } from '@angular/core';
import { ProfileModel } from 'src/app/models/profile.model';
import { ProfileService } from '../profile.service';



export const ProfileInfoResolver: ResolveFn<ProfileModel> = (
  route,
  state,
  service: ProfileService = inject(ProfileService)): Observable<ProfileModel> =>

  service.getProfile().pipe(
    map(profile => {
      if (profile) {
        return profile;
      }
      console.log(`No profile was found`);
      return null;
    }),
    catchError(error => {
      console.log(`Retrieval error: ${error}`);
      return of(null);
    })
);