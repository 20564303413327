<div class="container">
    <div class="row">
        <div class="col-12">

            <section class="pb-5">
                <h1 class="display-4 my-5">{{invitationModel.businessName}} Team Invitation</h1>

                <div class="row pt-5">
                    <div class="col-12 col-lg-2 text-center">
                        <div class="col-12 col-lg-6 mb-5">
                            <div
                                class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                                <fa-icon [icon]="faInfo" size="2x"></fa-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-10">
                        <p>
                            You have been invited to join an operations team. By accepting this invitation, you can
                            chat and receive orders and payments from the inviter.
                            You can also decline the invitation with a message.
                        </p>
                    </div>
                </div>
            </section>

            <section>
                <div class="d-flex justify-content-center">
                    <app-profile-card [model]="invitationModel.profileCard"></app-profile-card>
                </div>
                <app-error-alert [errorMessage]="errorMessage" [hideError]="hideErrorMessage"></app-error-alert>

                <div class="d-grid gap-2 col-6 mx-auto pt-5">

                    <button type="button" class="btn btn-obrame-primary btn-lg" (click)="onAcceptInvitation()">
                        <fa-icon [icon]="faCheck"></fa-icon>
                        Accept the Invitation
                    </button>
                    <button type="button" class="btn btn-obrame-secondary btn-lg" (click)="onCancelInvitation()">
                        <fa-icon [icon]="faXmark"></fa-icon>
                        Reject</button>

                </div>
            </section>
        </div>
    </div>
</div>