import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faXmark, faPenToSquare, faInfo } from '@fortawesome/free-solid-svg-icons';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ModuleServiceModel } from 'src/app/models/moduleService.model';
import { SelectModel } from 'src/app/models/select.model';
import { ContentService } from 'src/app/shared/content.service';
import { ServiceProviderService } from './service-provider.service';

@Component({
  selector: 'app-service-provider',
  templateUrl: './service-provider.component.html',
  styleUrls: ['./service-provider.component.css']
})
export class ServiceProviderComponent {


  faPenToSquare = faPenToSquare
  faXmark = faXmark;
  faInfo = faInfo;


  model: ModuleServiceModel;

  moduleForm: UntypedFormGroup;
  bioText: UntypedFormControl;
  fixedHourlyPrice: UntypedFormControl;


  isLoading = false;
  isEditMode = false;
  searching = false;
  searchFailed = false;
  errorMessage = "";
  hideErrorMessage = true;



  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private contentService: ContentService, private moduleService: ServiceProviderService) { }

  ngOnInit(): void {

    this.bioText = new UntypedFormControl('');
    this.fixedHourlyPrice = new UntypedFormControl('', [Validators.required, Validators.min(8), Validators.max(100)]);

    this.moduleForm = this.fb.group({
      bioText: this.bioText,
      fixedHourlyPrice: this.fixedHourlyPrice,

    });

    this.route.data.subscribe(data => {
      this.onModelRetrived(data.moduleServiceData);
    });

  }

  reloadData() {
    this.moduleService.getModuleService().subscribe(
      (data: ModuleServiceModel) => { this.onModelRetrived(data); },
      (error: HttpErrorResponse) => this.onError(error)
    );
  }

  onModelRetrived(moduleModel: ModuleServiceModel) {
    this.model = moduleModel;
    this.moduleForm.patchValue({
      bioText: moduleModel.bioText,
      fixedHourlyPrice: moduleModel.fixedHourlyPrice
    });
  }

  onSave() {
    this.isLoading = true;
    this.hideErrorMessage = true;

    if (this.moduleForm.dirty && this.moduleForm.valid) {
      const moduleObject = Object.assign({}, this.model, this.moduleForm.value);

      this.moduleService.setModuleService(moduleObject).subscribe(
        (data: ModuleServiceModel) => this.onSaveComplete(data),
        (error: HttpErrorResponse) => this.onError(error)
      );
    }
  }


  onSaveComplete(data) {
    this.isEditMode = false;
    this.model = data;
    this.isLoading = false;
  }


  setEditMode() {
    //Cancel button
    if (this.isEditMode) {
      this.reloadData();
      this.isEditMode = false;
      this.isLoading = false;
      this.moduleForm.markAsPristine();
    } else {
      this.isEditMode = true;
    }
  }


  onError(error: HttpErrorResponse) {
    this.isLoading = false;
    this.hideErrorMessage = false;
    // this.isLoading = false;
    if (error.status === 400) {
      this.errorMessage = error?.error;
    } else if (error.status === 500 || error.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
    console.log(error);
  }

  /**
    * Used to format the result data from the lookup into the
    * display and list values. Maps `{name: "band", id:"id" }` into a string
  */
  resultFormatBandListValue(value: any) {
    return value.title;
  }
  /**
    * Initially binds the string value and then after selecting
    * an item by checking either for string or key/value object.
  */
  inputFormatBandListValue(value: any) {
    if (value.title)
      return value.title
    return value;
  }

}

