import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PortfolioModel } from 'src/app/models/portfolio.model';
import { PortfolioProServiceModel } from 'src/app/models/portfolioProService.model';
import { PortfolioCardModel } from 'src/app/models/portfolioCard.model';
import { environment } from 'src/environments/environment';
import { ProfessionalRegistrationModel } from '../../models/professionalRegistration.model';




@Injectable()
export class PortfolioService {

  private _serviceUrl = environment.apiUrl + 'portfolio';

  constructor(private _http: HttpClient) { }

  getPortfolio(serviceId: string): Observable<PortfolioModel> {
    return this._http.get<PortfolioModel>(this._serviceUrl + '/' + serviceId, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getPortfolioCard(portfolioId: string): Observable<PortfolioCardModel> {
    return this._http.get<PortfolioCardModel>(`${this._serviceUrl}/${portfolioId}/card`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getActiveServices(): Observable<PortfolioProServiceModel[]> {
    return this._http.get<PortfolioProServiceModel[]>(this._serviceUrl + '/activeServices', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  setPortfolio(model: PortfolioModel): Observable<PortfolioModel> {
    return this._http.post<PortfolioModel>(this._serviceUrl + '', model, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getProfessionalRegistrationStage(): Observable<ProfessionalRegistrationModel> {
    return this._http.get<ProfessionalRegistrationModel>(this._serviceUrl + '/registrationProgress', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  setAcceptance(id: string, isAccepted: boolean): Observable<PortfolioModel> {
    return this._http.put<PortfolioModel>(`${this._serviceUrl}/${id}/acceptance`, isAccepted, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  setActivation(id: string, isActive: boolean): Observable<PortfolioModel> {
    return this._http.put<PortfolioModel>(`${this._serviceUrl}/${id}/activation`, isActive, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
}
