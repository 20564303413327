import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AccountService } from './account/account.service';
import { SideBarNavigationModel } from './models/sideBarNavigation.model';
import { ContentService } from './shared/content.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'obrameWeb';
  errorMessage = '';

  isLoggedIn$: Observable<boolean>;
  sideBarModel: SideBarNavigationModel;

  constructor(private authService: AccountService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.isLoggedIn$.subscribe(x => console.log(x));
  }
 
}