import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfessionalOrderModel } from 'src/app/models/professionalOrder.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { OrderService } from '../order.service';
import { NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { NgbDateUtilService } from 'src/app/shared/ngb-date-utilities/ngb-date-util.service';

declare var Stripe: stripe.StripeStatic;

@Component({
  selector: 'app-professional',
  templateUrl: './professional.component.html',
  styleUrls: ['./professional.component.css']
})
export class ProfessionalComponent implements OnInit {

  stripeKey = environment.stripeApiKey;
  faCalendar = faCalendarAlt;
  model: ProfessionalOrderModel;
  orderForm: UntypedFormGroup;

  @ViewChild('cardElement', { static: true })
  public cardElement: ElementRef;
  stripe: stripe.Stripe;
  card;
  cardErrors;
  paymentError = '';
  errorMessage = '';

  isLoading = false;
  addressSelected = false;
  showAddressError = false;
  showPaymentError = false;
  showSuccessMessage = false;
  showErrorMessage = false;
  showOrderButton = true;
  isPaymentValid = false;

  acceptanceInput = new UntypedFormControl();

  constructor(private router: Router, private route: ActivatedRoute, private fb: UntypedFormBuilder,
              private orderService: OrderService, private calendarConfig: NgbDatepickerConfig, private dateUtil: NgbDateUtilService) {

    this.model = this.route.snapshot.data.professionalOrderData;

    const current = new Date();
    calendarConfig.minDate = {
      year: current.getFullYear(), month:
        current.getMonth() + 1, day: current.getDate() + 1
    };
    // config.maxDate = { year: 2099, month: 12, day: 31 };
    calendarConfig.outsideDays = 'hidden';
  }

  ngOnInit() {

    this.stripe = Stripe(this.stripeKey, { locale: 'es' });
    const elements = this.stripe.elements();
    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);
    this.card.addEventListener('change', event => {
      if (event && event.error) {
        this.isPaymentValid = false;
      } else {
        this.isPaymentValid = true;
      }
      this.cardErrors = event.error && event.error.message;
    });

    console.log("in Order init");
    console.log(this.model);
    if (this.model) {
      this.orderForm = this.buildOrderForm(this.model);
    }
  }

  buildOrderForm(formModel: ProfessionalOrderModel): UntypedFormGroup {
    return this.fb.group({
      orderDate: [this.dateUtil.generateDateValue(formModel.orderDate)],
      orderTime: [formModel.orderTime],
      duration: [formModel.duration],
      wishes: [],
      acceptanceInput: this.acceptanceInput
    });
  }

  onAddressSelected(addressId: string) {
    this.model.addressId = addressId;
    this.addressSelected = true;
  }

  onReCalculate(workLoad: number) {
    console.log('calculating price' + workLoad);
    this.calculate();
  }
  calculate() {
    let o = Object.assign({}, this.model, this.orderForm.value);
    o.orderDate = this.dateUtil.parseDate(o.orderDate);
    console.log(o);
    this.orderService.getProfessionalOrder(o)
      .subscribe(
        data => this.onReCalculated(data),
        (error: any) => {
          console.log(error);
        },
      );
  }
  onReCalculated(orderData: ProfessionalOrderModel) {
    this.model = orderData;
    this.buildOrderForm(orderData);
  }

  onOrder(): void {
    this.isLoading = true;

    if (this.addressSelected) {
      this.showAddressError = false;
      const self = this;
      this.stripe.confirmCardPayment(this.model.paymentIntentId, { payment_method: { card: this.card } })
        .then(function (result) {
          if (result.error) {
            // Show error to your customer
            this.isLoading = false;
            this.paymentError = result.error;
            this.showPaymentError = true;
          } else {
            // The payment succeeded!
            // this.model.paymentIntentId = (result.paymentIntent.id);
            self.onPaymentSucceed(result.paymentIntent.id);
            console.log('payment successed');
            console.log('https://dashboard.stripe.com/test/payments/' + result.paymentIntent.id);
          }
        });
    } else {
      this.isLoading = false;
      this.showAddressError = true;
    }

  }

  onPaymentSucceed(paymentId: string) {
    this.model.paymentSuccessId = paymentId;
    let order = Object.assign({}, this.model, this.orderForm.value);
    order.orderDate = this.dateUtil.parseDate(order.orderDate);
    console.log(order);

    this.orderService.setProfessionalOrder(order)
      .subscribe(
        () => this.onOrderCompleted(),
        (error: any) => {
          console.log(error);
          this.errorMessage = "Algo salió mal y lo arreglaremos pronto. Por favor, vuelva pronto!"
          this.showErrorMessage = true;
          this.isLoading = false;
        },
      );
  }

  onOrderCompleted(): void {
    this.showSuccessMessage = true;
    this.isLoading = false;
    this.showOrderButton = false;
  }
}
