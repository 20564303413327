<section class="pb-5">
    <h1 class="display-4 my-5"><span class="obrame-txt-hightlight-yellow">Obrame</span> Subscription Checkout</h1>

    <div class="row pt-5">
        <div class="col-12 col-lg-2 text-center">
            <div class="col-12 col-lg-6 mb-5">
                <div
                    class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                    <fa-icon [icon]="faInfo" size="2x"></fa-icon>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-10">
            <p>
                Here, you can securely complete your subscription purchase and start enjoying the full benefits of
                Obrame
                services. We prioritize the security of your payment information, ensuring a safe and reliable
                transaction process. Once your payment is confirmed, you will receive an email notification with
                instructions on how to
                access your account. You can then start using our services immediately and benefit from the features and
                support available to you.
            </p>
        </div>
    </div>
</section>

<section>
    <div class="row justify-content-center">
        <div class="col-12 col-md-12 col-lg-6">
            <div class="border p-3 mb-3 rounded" *ngIf="checkoutModel">
                <h4 class="mt-2">Billing information</h4>

                <p class="text-muted mb-4">Fill the form below in order to
                    send you the order's invoice.</p>
                <form [formGroup]="checkoutForm" novalidate>
                    <div class="row g-2 mt-4" *ngIf="checkoutModel.isCompany">
                        <div class="col-md">
                            <div class="form-floating mb-3">
                                <input type="text" readonly class="form-control-plaintext" id="floatingStaticLegalName"
                                    placeholder="company123" formControlName="legalName">
                                <label for="floatingPlaintextInput">Company</label>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="form-floating mb-3">
                                <input type="text" readonly class="form-control-plaintext" id="floatingStaticTaxId"
                                    placeholder="B1234123" formControlName="taxId">
                                <label for="floatingPlaintextInput">Business Id</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md">
                            <label class="form-label" for="inputFullAddress">Full Address</label>
                            <input type="text" class="form-control form-obrame-input" id="inputFullAddress"
                                aria-describedby="fullAddressHelp" formControlName="streetAddress">
                            <div class="invalid-feedback">The full address required.</div>

                        </div>
                    </div> <!-- end row -->
                    <div class="row g-2 mt-4">
                        <div class="col-md">
                            <label class="form-label" for="inputCityName">Town / City</label>
                            <input type="text" class="form-control form-obrame-input" id="inputCityName" placeholder=""
                                aria-describedby="cityNameHelp" formControlName="city">
                            <div class="invalid-feedback">The City name is required.</div>

                        </div>
                        <div class="col-md">
                            <label class="form-label" for="inputStateId">State / Province</label>
                            <input type="text" class="form-control form-obrame-input" id="inputStateId" value=""
                                formControlName="province">

                            <div class="invalid-feedback">The Province name is required.</div>

                        </div>
                        <div class="col-md">
                            <label class="form-label" for="inputPostalCode">Zip / Postal Code</label>
                            <input type="text" class="form-control form-obrame-input" id="inputPostalCode"
                                placeholder="" value="" formControlName="postalCode">
                            <div class="invalid-feedback">The Zip Or Postal Code is required.</div>

                        </div>
                    </div>

                    <div class="row g-2 mt-5">
                        <div class="col-md col-lg-6">
                            <label class="form-label" for="companyCountryInput">Country</label>
                            <select class="form-select form-obrame-select" id="companyCountryInput" formControlName="countryId"
                                aria-describedby="companyCountryHelp" *ngIf="countriesModel">
                                <option *ngFor="let country of countriesModel" [value]="country.id">
                                    {{country.title}}</option>
                            </select>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3 mt-4">
                                <label class="form-label" for="inputOrderNotes" class="form-label">Order Notes</label>
                                <textarea class="form-control form-obrame-input" id="inputOrderNotes" rows="3"
                                    placeholder="Anything we should know?" formControlName="orderNotes"></textarea>
                            </div>
                        </div>
                    </div> <!-- end row -->
                </form>
            </div>
            <div class="border p-3 mb-3 rounded" *ngIf="checkoutModel.template.price > 0">
                <div class="row">
                    <div class="col-sm-8">
                        <div class="form-check">
                            <input type="radio" id="BillingOptRadio1" name="billingOptions" class="form-check-input"
                                checked="">
                            <label class="form-check-label font-16 fw-bold" for="BillingOptRadio1">Credit / Debit
                                Card</label>
                        </div>
                        <p class="mb-0 ps-3 pt-1">Safe money transfer using your bank card. We support Mastercard and
                            Visa.</p>
                    </div>
                    <div class="col-sm-4 text-sm-end mt-3 mt-sm-0">
                        <fa-icon [icon]="faCreditCard"></fa-icon>
                    </div>
                </div> <!-- end row -->
                <div class="row mt-4">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-label" class="mb-3">Payment information</label>
                            <div class="form-control form-obrame-input" style='height: 2.4em; padding-top: .7em;' #cardElement>
                            </div>
                            <div class="text-danger">
                                {{cardErrors}}
                            </div>
                        </div>
                    </div>
                </div> <!-- end row -->
            </div>
            <app-error-alert [errorMessage]="errorMessage" [hideError]="hideErrorMessage"></app-error-alert>
            <div class="row mt-5">
                <div class="col-sm-6">
                    <a class="btn text-muted d-none d-sm-inline-block btn-link fw-semibold"
                        [routerLink]="['/profile', 'subscription', checkoutModel.template.templateId]">
                        <fa-icon [icon]="faArrowLeft" class="rounded me-2"></fa-icon> Back to Subscription Page </a>
                </div> <!-- end col -->
                <div class="col-sm-6">
                    <button class="w-100 btn btn-obrame-primary btn-lg" type="button" (click)="onActivateSubscription()"
                        [disabled]="!checkoutForm.valid || !isPaymentValid || isLoading ">Activate
                        Your Subscription
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            *ngIf="isLoading"></span>
                    </button>
                </div> <!-- end col -->
            </div> <!-- end row -->
        </div>
        <div class="col-12 col-md-12 col-lg-5 order-first order-md-first order-lg-last">
            <div class="border p-3 mt-4 mt-lg-0 rounded">
                <h4 class="header-title mb-3">Order Summary</h4>

                <div class="table-responsive">
                    <table class="table table-nowrap table-centered mb-0">
                        <tbody>
                            <tr>
                                <td>

                                    <p class="m-0 d-inline-block align-middle">
                                        <fa-icon [icon]="faReceipt" class="rounded me-2"></fa-icon>
                                        <b>{{checkoutModel.template.title}}</b>
                                        <br>
                                        <small>{{checkoutModel.template.description}}</small>
                                    </p>
                                </td>
                                <td class="text-end">
                                    {{checkoutModel.template.price}}€
                                </td>
                            </tr>
                            <tr class="text-end">
                                <td>
                                    <h6 class="m-0">Subscription Period Months:</h6>
                                </td>
                                <td class="text-end">
                                    {{checkoutModel.template.subLength}}
                                </td>
                            </tr>
                            <tr class="text-end">
                                <td>
                                    <h6 class="m-0">Sub Total:</h6>
                                </td>
                                <td class="text-end">
                                    {{checkoutModel.baseAmount}}€
                                </td>
                            </tr>
                            <tr class="text-end">
                                <td>
                                    <h6 class="m-0">Tax:</h6>
                                </td>
                                <td class="text-end">
                                    {{checkoutModel.taxAmount}}
                                </td>
                            </tr>
                            <tr class="text-end">
                                <td>
                                    <h5 class="m-0">Total:</h5>
                                </td>
                                <td class="text-end fw-semibold">
                                    {{checkoutModel.totalAmount}}€
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- end table-responsive -->
            </div>
        </div>
    </div>
</section>