import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnboardingModel } from 'src/app/models/onboarding.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  private serviceUrl = environment.apiUrl + 'onboarding';

  constructor(private http: HttpClient) { }

  getOnboarding(): Observable<OnboardingModel> {
    return this.http.get<OnboardingModel>(this.serviceUrl, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
  setOnboarding(newOnboarding: OnboardingModel): Observable<OnboardingModel> {
    return this.http.post<OnboardingModel>(this.serviceUrl, newOnboarding, {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    });
}
}
