import { Component, Input } from '@angular/core';
import { DashboardSPOverviewModel } from 'src/app/models/dashboardSPOverview.model';
import { DashboardThisWeekModel } from 'src/app/models/dashboardThisWeek.model';
import { faUsers, faClipboardCheck} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-spdashboard',
  templateUrl: './spdashboard.component.html',
  styleUrls: ['./spdashboard.component.css']
})
export class SPDashboardComponent {
  @Input() weekModel: DashboardThisWeekModel;
  @Input() dashboardModel: DashboardSPOverviewModel;


  faUsers = faUsers;
  faClipboardCheck = faClipboardCheck;
}
