<div class="container py-5">
    <div class="row">
        <div class="col-12">
            <h1 class="display-6">Property Information</h1>
            <section *ngIf="!isEditMode">
                <div class="position-relative">
                    <div class="position-absolute top-0 end-0">
                        <button class="btn btn-primary btn-obrame-primary" (click)="setEditMode()">
                            <fa-icon [icon]="faPenToSquare" class="px-2"></fa-icon>
                            Edit
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md col-lg-6">

                        <h2 class="mt-3">{{model.title}}</h2>
                        <h3 class="mt-4"><small class="text-muted">{{model.addressTitle}}</small></h3>
                        <dl class="row mt-5">
                            <dt class="col-sm-3">House number</dt>
                            <dd class="col-sm-9">{{model.houseNumber}}</dd>

                            <dt class="col-sm-3">House Size</dt>
                            <dd class="col-sm-9">
                                {{model.houseSize}} {{model.houseSizeUnit}}
                            </dd>
                            <dt class="col-sm-3">Number of Rooms</dt>
                            <dd class="col-sm-9">
                                {{model.numberOfRooms}}
                            </dd>
                        </dl>
                    </div>
                </div>
            </section>
            <section *ngIf="isEditMode">
                <form novalidate (ngSubmit)="onSave()" [formGroup]="propertyForm">
                    <div class="row g-2 my-4">
                        <div class="col-md col-lg-5">
                            <label class="form-label" for="inputTitle">Title of the property</label>
                            <input type="text" class="form-control form-obrame-input" id="inputTitle"
                                aria-describedby="titleHelp" formControlName="title"
                                [ngClass]="{'is-invalid': propertyForm.get('title').dirty && !propertyForm.get('title').valid }">

                            <small id="residencyCountryHelp" class="form-text text-muted">
                                You can give a name to your property to describe and find it easier.
                            </small>
                        </div>
                        <div class="offset-md-1"></div>
                    </div>
                    <div class="row g-2">
                        <div class="col-md-5">
                            <app-location-picker [resetAfterSelect]="false" [initialValue]="model.addressTitle"
                                (locationPickerEvent)="onAddressSelected($event)"></app-location-picker>
                        </div>
                        <div class="col-md-3 offset-md-1">
                            <label class="form-label" for="inputhouseNumber">House Number</label>
                            <input type="text" class="form-control form-obrame-input" id="inputhouseNumber" value=""
                                formControlName="houseNumber" aria-describedby="streetAddressHelp"
                                [ngClass]="{'is-invalid': propertyForm.get('houseNumber').dirty && !propertyForm.get('houseNumber').valid }">

                            <small id="streetAddressHelp" class="form-text text-muted">
                                The number of the flat. For example: 1 F.
                            </small>
                            <div class="invalid-feedback">The house number is required.</div>

                        </div>
                    </div>
                    <div class="row g-2 my-4">
                        <div class="col-md-3">
                            <label class="form-label" for="floatingInputHouseSize">House Size</label>
                            <input type="number" min="10" class="form-control form-obrame-input" id="floatingInputHouseSize"
                                aria-describedby="houseSizeHelp" formControlName="houseSize"
                                [ngClass]="{'is-invalid': propertyForm.get('houseSize').dirty && !propertyForm.get('houseSize').valid }">

                            <small id="houseSizeHelp" class="form-text text-muted">
                                The square meter to square feet of the house.
                            </small>
                            <div class="invalid-feedback">Please check the format of the value.</div>

                        </div>
                        <div class="col-md-2">
                            <label class="form-label" for="houseSizeUnitInput">Unit</label>
                            <select class="form-select form-obrame-select" id="houseSizeUnitInput"
                                formControlName="houseSizeUnit" aria-describedby="houseSizeUnitHelp">
                                <option *ngFor="let unit of areaUnits" [value]="unit.id">{{unit.title}}</option>
                            </select>

                            <small id="houseSizeUnitHelp" class="form-text text-muted">
                                The unit of the size of you country.
                            </small>

                        </div>
                        <div class="col-md-3 offset-md-1">
                            <label class="form-label" for="floatingInputnumberOfRooms">Number of rooms</label>
                            <input type="number" min="1" class="form-control form-obrame-input" id="floatingInputnumberOfRooms"
                                value="" formControlName="numberOfRooms" aria-describedby="numberOfRoomsHelp"
                                [ngClass]="{'is-invalid': propertyForm.get('numberOfRooms').dirty && !propertyForm.get('numberOfRooms').valid }"
                                max="20">

                            <small id="numberOfRoomsHelp" class="form-text text-muted">
                                The number of the rooms in the house. Choose 1 for the studio flat.
                            </small>
                            <div class="invalid-feedback">The number of the rooms is required and should be less
                                than 20.</div>

                        </div>
                    </div>
                    
                    <app-error-alert [errorMessage]="errorMessage" [hideError]="hideErrorMessage"></app-error-alert>

                    <div class="d-grid gap-2 col-8 mx-auto mt-5 pt-5">

                        <button type="submit" class="btn btn-obrame-primary py-2"
                            [disabled]="!propertyForm.valid || !propertyForm.dirty || isLoading || !isAddressSelected">
                            {{buttonText}}
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                *ngIf="isLoading"></span>
                        </button>
                        <button type="button" class="btn btn-secondary btn-obrame-secondary" (click)="setCancel()">
                            Cancel
                        </button>
                    </div>
                </form>
            </section>
        </div>
    </div>
</div>