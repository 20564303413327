import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PortfolioService } from '../portfolio.service';
import { UntypedFormControl } from '@angular/forms';
import { PortfolioModel } from 'src/app/models/portfolio.model';

@Component({
  selector: 'app-acceptance',
  templateUrl: './acceptance.component.html',
  styleUrls: ['./acceptance.component.css']
})
export class AcceptanceComponent implements OnInit {
  @Input() isAccepted: boolean;
  @Input() serviceId: string;
  @Output() portfolioEvent: EventEmitter<PortfolioModel> = new EventEmitter<PortfolioModel>();

  inputIsAccepted: UntypedFormControl;
  isLoading = false;

  constructor(private portfolioService: PortfolioService) { }

  ngOnInit(): void {

    this.inputIsAccepted = new UntypedFormControl();
  }

  onAcceptance() {
    this.portfolioService.setAcceptance(this.serviceId, true).subscribe(
      (data: PortfolioModel) => this.returnModel(data),
      (error: any) => {
        console.log(error);
        // this.hideLoginError = false;
      }
    );
  }

  returnModel(model: PortfolioModel) {
    this.portfolioEvent.emit(model);
  }

}
