<div class="container py-5">
    <div class="row">
        <div class="col-12">
            <section class="pb-5">
                <h1 class="display-4 my-5"><span class="obrame-txt-hightlight-yellow">New</span> Assignment</h1>
                <div class="row">
                    <div class="col-12 col-lg-2 text-center">
                        <div class="col-12 col-lg-6 mb-5">
                            <div
                                class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                                <fa-icon [icon]="faInfo" size="2x"></fa-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-10">
                        <p class="lead">This page is designed to streamline your workflow and make it easy for you to
                            assign tasks
                            to your team members. Once you are ready, you can send an invitation to the team member to
                            review and
                            accept the task. The team member will receive a notification with all the details about the
                            task and
                            will be able to accept or decline it.</p>

                    </div>
                </div>
            </section>
            <section>
                <div class="row">
                    <div class="col-12">
                        <h2 class="display-6 py-5">Create a new assignment</h2>
                    </div>
                </div>
                <form name="assignmentForm" novalidate [formGroup]="assignmentForm" (ngSubmit)="onSave()">
                    <div class="row g-5">
                        <div class="col-12 col-md-7 col-lg-8">


                            <div class="form-group row border-bottom py-4">
                                <label for="buttonDate" class="col-sm-3 col-form-label">Assignment Date</label>
                                <div class="col-sm-4">
                                    <div class="input-group">
                                        <input id="inputDate" readonly="true" class="form-control"
                                            placeholder="Choose date" name="dp" ngbDatepicker #d="ngbDatepicker"
                                            [formControl]="assignmentDateControl">
                                        <div class="input-group-append">
                                            <button id="buttonDate" class="btn btn-outline-secondary calendar"
                                                (click)="d.toggle()" type="button">
                                                <fa-icon [icon]="faCalendar"></fa-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row border-bottom py-4">
                                <label class="col-sm-3 col-form-label">Start Hour:</label>
                                <div class="col-sm-4">
                                    <ngb-timepicker [formControl]="startTimeControl" [minuteStep]="30"
                                        required></ngb-timepicker>
                                    <div *ngIf="startTimeControl.errors" class="small form-text text-danger">
                                        <div *ngIf="startTimeControl.errors['required']"> Please choose an assignment
                                            start time.
                                        </div>
                                        <div *ngIf="startTimeControl.errors['tooLate']">Oh no, it's way too late</div>
                                        <div *ngIf="startTimeControl.errors['tooEarly']">It's a bit too early</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row border-bottom py-4">
                                <label class="col-sm-3 col-form-label">Team member</label>
                                <div class="col-sm-4">
                                    <app-picker-control [includeIcon]="false" type="member"
                                        (selectedItemEvent)="onTeamMemberSelected($event)"></app-picker-control>
                                </div>
                            </div>
                            <div class="form-group row border-bottom py-4">
                                <label for="durationControl" class="col-sm-3 col-form-label">Duration</label>
                                <div class="col-sm-4">
                                    <select id="durationControl" class="form-select"
                                        aria-label="Default select duration" formControlName="duration"
                                        (change)="onReCalculate($event.target.value)">
                                        <option selected>Open this select menu</option>
                                        <option *ngFor="let number of [1,2,3,4,5,6,7,8]; index as i;"
                                            value="{{number}}">{{number}}
                                            h
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group row border-bottom py-4">
                                <label class="col-sm-3 col-form-label">Property</label>
                                <div class="col-sm-4">
                                    <app-picker-control [includeIcon]="false" [includeContent]="true" type="property"
                                        (selectedItemEvent)="onPropertySelected($event)"></app-picker-control>
                                </div>
                            </div>


                            <div class="form-group row border-bottom py-4">
                                <label for="commentsControl" class="col-sm-3 col-form-label">Comments</label>
                                <div class="col-sm-4">
                                    <textarea class="form-control" id="commentsControl" rows="3"
                                        formControlName="comments" maxlength="225"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 col-lg-4 order-md-last">
                            <h3 class="mb-3">Price and Payment</h3>
                            <div class="alert alert-warning" role="alert" *ngIf="!model.priceRates">
                                The price will be calculated when the team member and the duration of the assignment are
                                selected.
                            </div>

                            <ul class="list-group mb-3" *ngIf="model.priceRates">
                                <li class="list-group-item d-flex justify-content-between lh-sm"
                                    *ngFor="let priceModel of model.priceRates">
                                    <div>
                                        <h6 class="my-0">{{priceModel.title}}</h6>
                                        <small class="text-body-secondary">{{priceModel.description}}</small>
                                    </div>
                                    <span class="text-body-secondary">{{ priceModel.priceRate }} €</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between" *ngIf="model">
                                    <span>Total (EUR)</span>
                                    <strong>{{ model.assignmentTotalPrice}} €</strong>
                                </li>
                            </ul>
                            <div class="mt-5">
                                <div class="form-check" *ngFor="let method of paymentMethods;">
                                    <fa-icon [icon]="method.faIcon" class="mx-3"></fa-icon>
                                    <input class="form-check-input" type="radio" id="{{method.id}}" [value]="method.id"
                                        formControlName="paymentMethodId" [attr.disabled]="!method.isEnabled ? true : null"
                                        (change)="onPaymentMethodChanged($event.target.value)">
                                    <label class="form-check-label" for="{{method.id}}">
                                        {{method.title}}
                                    </label>
                                </div>
                            </div>
                            <div class="row mt-4" *ngIf="assignmentForm.get('paymentMethodId').value === 'a970611a-fb8c-4b3a-82e4-3132a43ae22d'">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="mb-3">Payment information</label>

                                        <div class="form-control form-obrame-input"
                                            style='height: 2.4em; padding-top: .7em;' #cardElement>
                                        </div>
                                        <div class="text-danger">
                                            {{cardErrors}}
                                        </div>
                                    </div>
                                </div>
                            </div> <!-- end row -->

                            <app-error-alert [errorMessage]="errorMessage"
                                [hideError]="hideErrorMessage"></app-error-alert>
                            <div class="d-grid gap-2 col-12 mx-auto mt-5 pt-5">
                                <!-- <p>{{!assignmentForm.valid}} {{!assignmentForm.dirty}} {{isLoading}} {{!isTeamMemberSelected}} {{!isPropertySelected}} {{!isPaymentValid}}</p> -->
                                <button type="submit" class="btn btn-obrame-primary py-2"
                                    [disabled]="!assignmentForm.valid || !assignmentForm.dirty || isLoading || !isTeamMemberSelected || !isPropertySelected || !isPaymentValid">
                                    Create The Request
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                        *ngIf="isLoading"></span>
                                </button>
                                <button type="button" class="btn btn-secondary btn-obrame-secondary"
                                    [routerLink]='["../propertyOwner"]' [disabled]="isLoading">
                                    Cancel
                                </button>
                            </div>

                        </div>
                    </div>
                </form>
            </section>
        </div>
    </div>
</div>