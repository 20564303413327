import { PriceRateModel } from './priceRate.model';
import { TimeModel } from './time.model';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class AssignmentModel {

  constructor(
    public assignmentDate: NgbDateStruct,
    public startTime: TimeModel,
    public duration: number,
    public propertyId: string,
    public memberId: string,
    public comments: string,
    public priceRateGroupId: string,
    public priceRates: PriceRateModel[],
    public assignmentTotalPrice: number,
    public paymentIntentId: string,
    public paymentId: string,
    public paymentMethodId: string,
  ) { }
}
