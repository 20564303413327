<main class="container">
    <div class="row">
        <div class="col-4">
            <app-profile-card [portfolioId]="model.portfolioId"></app-profile-card>
        </div>

        <div class="col-8">

            <div [formGroup]="orderForm">
                <h3 class="pb-4 mt-5 font-italic border-bottom">Detalles de la Reserva</h3>

                <div class="form-group">
                    <label for="exampleInputEmail1">Día de la cita</label>
                    <div class="input-group">
                        <input readonly="true" formControlName="orderDate" class="form-control" placeholder="Choose date" name="dp" ngbDatepicker #d="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                                <fa-icon [icon]="faCalendar"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1">Hora de inicio</label>
                    <ngb-timepicker formControlName="orderTime" [minuteStep]="30"></ngb-timepicker>
                </div>
                <p class="my-4">Precio por hora: {{model.price}} Euros</p>
                <div class="form-group">
                    <label for="exampleFormControlSelect1">Duración (en horas)</label>
                    <select (change)="onReCalculate($event.target.value)" formControlName="duration" class="form-control" id="exampleFormControlSelect1">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </select>
                </div>
                <app-address-select label="Dirección de destino" (addressIdEvent)="onAddressSelected($event)">
                </app-address-select>

                <div class="form-group">
                    <label for="decriptionInput">Descripción o deseos</label>
                    <textarea formControlName="wishes" class="form-control" id="decriptionInput" rows="3"></textarea>
                </div>

                <div class="form-group">
                    <label>Información del pago</label>
                    <div class="form-control" style='height: 2.4em; padding-top: .7em;' #cardElement>
                    </div>
                    <div class="text-danger">
                        {{cardErrors}}
                    </div>
                </div>


                <h3 class="border-bottom mt-5">Total: {{model.total}} euros</h3>

                <div class="form-group form-check mt-5">
                    <input type="checkbox" class="form-check-input" id="acceptCheckBox" [formControl]="acceptanceInput">
                    <label class="form-check-label" for="acceptCheckBox">
                        Los detalles del pedido son correctos. He leído los términos y la política de cancelación y los
                        acepto.
                    </label>
                </div>
                <div class="alert alert-danger mt-5" role="alert" *ngIf="showAddressError">
                    Por favor seleccione la dirección del trabajo para poder ordenar al profesional.
                </div>
                <div class="alert alert-danger mt-5" role="alert" *ngIf="showPaymentError">
                    {{paymentError}}
                </div>
                <div class="alert alert-success mt-5 py-4" role="alert" *ngIf="showSuccessMessage">
                    Gracias por su orden. El profesional ha recibido su solicitud y le responderá lo antes posible. Verifique su correo electrónico para la confirmación o vea sus
                    <a [routerLink]='["/profile/reservations"]'>pedidos actuales</a>.
                </div>

                <button [disabled]="!isPaymentValid || !addressSelected || !acceptanceInput.value || isLoading || showSuccessMessage" class="btn btn-primary btn-lg btn-block mb-5" type="button" (click)="onOrder()" *ngIf="showOrderButton">
                    Ordenar el Trabajo
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        *ngIf="isLoading"></span>
                </button>
                <div class="alert alert-danger" role="alert" *ngIf="showErrorMessage">
                    {{errorMessage}}
                </div>

            </div>
        </div>
    </div>

</main>