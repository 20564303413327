import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BirthdayPickerService {

  constructor() { }

  getYears(): Array<number> {
    const maxValue = new Date().getFullYear() - 18;
    const minValue = maxValue - 60;
    return this.getRange(minValue, maxValue).reverse();
  }
  getDays(): Array<number> {
    return this.getRange(1, 31);
  }
  getMonths(): Array<number> {
    return this.getRange(1, 12);
  }

  private getRange(min: number,  max: number): Array<number> {
    const days = new Array<number>();

    for (let i = min; i < (max + 1); i++) {
      days.push(i);
    }
    return days;
  }

}
