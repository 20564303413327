import { Component, OnInit, Input } from '@angular/core';
import { PortfolioService } from 'src/app/professional/portfolio/portfolio.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileCardModel } from 'src/app/models/profileCard.model';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.css']
})
export class ProfileCardComponent implements OnInit {

  @Input() portfolioId = '';
  @Input() model: ProfileCardModel;

  constructor(private _portfolioService: PortfolioService) { }

  ngOnInit() {
    // if (this.portfolioId !== '' && this.portfolioId !== null) {
    //   this.getPortfolioCard(this.portfolioId);
    // }
  }

  getPortfolioCard(portfolioId: string) {
    this._portfolioService.getPortfolioCard(portfolioId)
      .subscribe(
        (data: ProfileCardModel) => this.model = data,
        (error: HttpErrorResponse) => this.onReceiveCodeError(error)
      );
  }
  onReceiveCodeError(error: HttpErrorResponse) {
    console.log(error.message);
    // this.showErrors = true;
  }

}
