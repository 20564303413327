import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { RegisterModel } from '../../models/register.model';
import { AccountService } from '../account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { faEnvelope, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

function passwordMatcher(c: AbstractControl) {
    const passwordC = c.get('password');
    const passwordConfC = c.get('passwordConfirm');
    if (passwordC.pristine || passwordConfC.pristine) {
        return null;
    }
    if (passwordC.value === passwordConfC.value) {
        return null;
    }
    return { 'match': true };
}

@Component({
    selector: 'app-account-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

    faEnvelope = faEnvelope;
    alertIcon = faTriangleExclamation;

    registerForm: UntypedFormGroup;
    registration: RegisterModel;
    email: UntypedFormControl;
    firstName: UntypedFormControl;
    lastName: UntypedFormControl;
    password: UntypedFormControl;
    noMarketing: UntypedFormControl;

    registrationCompleted = true;
    hideErrorMessage = true;
    errorMessage: string;
    isLoading = false;

    constructor(private fb: UntypedFormBuilder, private router: Router, private accountService: AccountService) { }

    ngOnInit(): void {
        this.email = new UntypedFormControl('', [Validators.required, Validators.email]);
        this.firstName = new UntypedFormControl('', [Validators.required, Validators.minLength(2)]);
        this.lastName = new UntypedFormControl('', [Validators.required, Validators.minLength(2)]);
        this.password = new UntypedFormControl('', [Validators.required, Validators.min(8)]);
        this.noMarketing = new UntypedFormControl('', []);


        this.registerForm = this.fb.group({
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.password,
            noMarketing: this.noMarketing
            // passwordConfirm: ['', [Validators.required]]
        });
    }

    onSave(): void {
        this.isLoading = true;
        console.log('in on save');
        // if (this.registerForm.dirty && this.registerForm.valid) {
        console.log('Form is valid');
        // Copy the form values over the  product object values
        const r = Object.assign({}, this.registration, this.registerForm.value);
        console.log(this.registerForm.value);
        this.accountService.registerAccount(r)
            .subscribe(
                (data: RegisterModel) => this.onSaveComplete(data),
                (error: HttpErrorResponse) => this.onError(error)
            );
        // }
    }

    onSaveComplete(data: RegisterModel): void {
        // this.registerForm.disable();
        this.hideErrorMessage = true;
        console.log('Registration is done: ' + data);
        this.router.navigate(['/register/confirmation']);
    }
    onError(error: HttpErrorResponse) {
        this.hideErrorMessage = false;
        this.isLoading = false;
        if (error.status === 400) {
            this.errorMessage = error?.error[0]?.description;
        } else if (error.status === 500 || error.status === 0) {
            this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
        }
        console.log(error);
    }

    // Enable/disable form control
    // public toggleFormState() {
    //   this.formDisabled = !this.formDisabled;
    //   const state = this.formDisabled ? 'disable' : 'enable';

    //   Object.keys(this.registerForm.controls).forEach((controlName) => {
    //     this.registerForm.controls[controlName][state](); // disables/enables each form control based on 'this.formDisabled'
    //   });
    // }

}
