import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register/register.component';
import { ProfessionalComponent } from './professional.component';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/auth-guard.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/auth.interceptor';
import { SharedModule } from '../shared/shared.module';
import { ProfileInfoResolver } from '../profile/info/profile-info-resolver.service';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ProfessionalServicesResolver } from './professional-resolver.service';
import { ProfessionalService } from './professional.service';
import { PortfolioService } from './portfolio/portfolio.service';
import { ProGuardGuard } from './pro-guard.guard';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCheck, faTimes, faComments } from '@fortawesome/free-solid-svg-icons';
import { OrderActionModalComponent } from './order-action-modal/order-action-modal.component';
import { AcceptanceComponent } from './portfolio/acceptance/acceptance.component';
import { ActivationComponent } from './portfolio/activation/activation.component';


@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'professional',
        canActivate: [AuthGuard, ProGuardGuard],
        component: ProfessionalComponent
      },
      {
        path: 'professional/register',
        component: RegisterComponent,
        canActivate: [AuthGuard, ProGuardGuard],
        resolve: {profileInfo: ProfileInfoResolver}
      },
      {
        path: 'professional/portfolio',
        component: PortfolioComponent,
        canActivate: [AuthGuard, ProGuardGuard],
        resolve: {professionalServices: ProfessionalServicesResolver}
      }
    ]),
    CommonModule,
    SharedModule
  ],
  declarations: [RegisterComponent, ProfessionalComponent, PortfolioComponent, OrderActionModalComponent, AcceptanceComponent, ActivationComponent],
  providers: [
    ProfessionalServicesResolver,
    ProfessionalService,
    PortfolioService,
    ProGuardGuard,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ]
})

export class ProfessionalModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faCheck, faTimes, faComments);
  }
}
