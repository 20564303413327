import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardModel } from 'src/app/models/dashboard.model';


import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private serviceUrl = environment.apiUrl + 'dashboard';

  constructor(private http: HttpClient) { }

  getDashboard(): Observable<DashboardModel> {
    return this.http.get<DashboardModel>(`${this.serviceUrl}/getDashboard`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
}
