import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from 'src/app/profile/profile.service';
import { faCloudUploadAlt, faXmark, faUser } from '@fortawesome/free-solid-svg-icons';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.css']
})
export class ProfilePictureComponent implements OnInit {
  faUpload = faCloudUploadAlt;
  faXmark = faXmark;
  faUser = faUser;

  // the selected file from modal
  thePictureFile: any;
  // the image shown in the modal
  imageShow: any = '';
  newImageSelected = false;
  // The main image which is also button link
  @Input() profilePictureUrl = '';
  @Input() userId = '';
  @Input() readMode = true;
  @Output() hasProfilePicturEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  hasModelPicture = false;
  hasProfilePicture = false;
  isLoading = false;

  closeResult: string;

  public progress: number;
  public message: string;
  errorMessage = "";

  constructor(private modalService: NgbModal, private profileService: ProfileService) { }

  ngOnInit(): void {
    if (this.profilePictureUrl !== '' && this.profilePictureUrl !== null) {
      this.hasProfilePicture = true;
      this.imageShow = this.profilePictureUrl;
    } else {
      if (this.userId) {
        this.getProfilePictureUrl();
      }
    }
  }

  getProfilePictureUrl() { 
    this.profileService.getProfilePictureById(this.userId, "picture").subscribe(
      (data : any) => this.getPictureUrlState(data.url),
      (error: HttpErrorResponse) => this.onError(error)
    );
  }

  getPictureUrlState(url: string){
    if (url === '') {
      this.hasProfilePicture = false;
    }else{
      this.profilePictureUrl = url;
      this.imageShow = url;
      this.hasProfilePicture = true;
    }
  }

  open(content) {
    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onFileChanged(event) {
    this.hasModelPicture = true;
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    this.thePictureFile = event.target.files[0];
    // tslint:disable-next-line:no-shadowed-variable
    reader.onload = (event) => {
      this.imageShow = (event.target as FileReader).result;
    };
  }

  onSaveButton() {
    this.isLoading = true;
    const formData = new FormData();
    formData.append(this.thePictureFile.name, this.thePictureFile);

    // for (const file of files) {
    //  formData.append(file.name, file);
    // }

    this.profileService.setProfilePicture(formData)
      .subscribe(
        newPicUrl => this.onProfilePictureSave(newPicUrl),
        (error: any) => {
          console.log(error);
        },
      );
  }

  onProfilePictureSave(newImageUrl): void {
    this.isLoading = false;
    this.modalService.dismissAll();
    this.newImageSelected = true;
    //this.profilePictureUrl = this.imageShow;
    this.hasProfilePicturEvent.emit(true);
  }

  onError(error: HttpErrorResponse) {
    // this.hideErrorMessage = false;
    // this.isLoading = false;
    if (error.status === 400) {
      this.errorMessage = error?.error[0]?.description;
    } else if (error.status === 500 || error.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
    console.log(error);
  }

}
