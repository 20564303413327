import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfessionalService } from '../professional.service';
import { ProfessionalServiceModel } from 'src/app/models/professionalService.model';
import { PortfolioModel } from 'src/app/models/portfolio.model';
import { PortfolioService } from './portfolio.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SelectModel } from 'src/app/models/select.model';
import { AddressModel } from 'src/app/models/address.model';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  proServiceModles: ProfessionalServiceModel[];
  selectedModel: ProfessionalServiceModel;

  levelOfExperiences: SelectModel[];
  operatingZones: AddressModel[];

  isServiceAccepted = false;
  isServiceActive = false;
  canActivate = false;

  showSuccessMessage = false;
  isServiceLoading = true;
  isLoading = false;
  errorMessage = '';
  maxPitchLenght = 255;

  portfolioForm: UntypedFormGroup;
  id: UntypedFormControl;
  hourRate: UntypedFormControl;
  quickPitch: UntypedFormControl;
  levelOfExperieceId: UntypedFormControl;

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private service: ProfessionalService,
    private portfolioService: PortfolioService, private ref: ChangeDetectorRef) { }

  ngOnInit() {

    this.route.data.subscribe(data => {
      this.onProfessionalServicesRetrived(data.professionalServices);
    });
    this.hourRate = new UntypedFormControl('', [Validators.required, Validators.min(7)]);
    this.quickPitch = new UntypedFormControl('', [Validators.maxLength(this.maxPitchLenght)]);
    this.portfolioForm = this.buildPorfolioForm();
  }

  onProfessionalServicesRetrived(retrivedServices: ProfessionalServiceModel[]): void {
    if (retrivedServices && retrivedServices.length > 0) {
      this.proServiceModles = retrivedServices;
      this.selectedModel = this.proServiceModles[0];

      this.getLevelOfExperiences();
      this.getPortfolioFromBackend(this.selectedModel.id);
    }
  }

  getLevelOfExperiences() {
    this.service.getLevelsOfExperience()
      .subscribe(
        experiences => this.createLevelOfExperieceSelect(experiences),
        error => this.errorMessage = error as any
      );
  }

  createLevelOfExperieceSelect(selects: SelectModel[]) {
    this.levelOfExperiences = new Array();
    this.levelOfExperiences.push(new SelectModel('', 'Selecciona'));
    selects.forEach(element => {
      this.levelOfExperiences.push(new SelectModel(element.id, element.title));
    });
  }

  buildPorfolioForm(): UntypedFormGroup {
    return this.fb.group({
      id: [],
      hourRate: this.hourRate,
      quickPitch: this.quickPitch,
      levelOfExperieceId: [],
      serviceid: ['serviceid', null]
    });
  }

  getPortfolioFromBackend(serviceId) {
    console.log('Getting portfolio from backend');
    this.portfolioService.getPortfolio(serviceId).subscribe(
      (data: PortfolioModel) => this.portfolioReceived(data),
      (error: any) => {
        console.log(error);
        // this.hideLoginError = false;
      }
    );
  }

  portfolioReceived(model: PortfolioModel) {
    this.operatingZones = [];
    if (model) {
      this.isServiceAccepted = model.isAccepted;
      this.isServiceActive = model.isActive;
      if (model.isAccepted) {
        this.portfolioForm.patchValue({
          id: model.id,
          hourRate: model.hourRate,
          quickPitch: model.quickPitch,
          levelOfExperieceId: model.levelOfExperieceId,
          serviceid: model.serviceId
        });
        this.canActivate = this.onCanActivate(model);
        this.operatingZones = model.operatingZones;
      }
    }
    this.isServiceLoading = false;
  }

  onSelect(proService: ProfessionalServiceModel) {
    this.isServiceLoading = true;
    this.showSuccessMessage = false;
    this.isServiceAccepted = false;
    this.selectedModel = proService;
    this.getPortfolioFromBackend(proService.id);
  }

  onCurrentServiceAccepted(portfolio: PortfolioModel) {
    this.portfolioReceived(portfolio);
  }

  onCurrentServiceActivated(portfolio: PortfolioModel) {
    this.portfolioReceived(portfolio);
  }

  onLocationSelected(location: AddressModel) {
    console.dir('location selected portfolio');
    console.log(this.operatingZones);
    this.operatingZones.push(location);
    this.ref.detectChanges();
  }
  onRemoveZone(zone: AddressModel) {
    this.operatingZones.forEach((item, index) => {
      if (item === zone) {
        this.operatingZones.splice(index, 1);
        this.ref.detectChanges();
      }
    });
  }

  onCanActivate(model: PortfolioModel): boolean {
    let returnValue = false;
    if (model) {
      returnValue = model.operatingZones.length > 0 && model.isAccepted && model.hourRate > 0;
    }
    return returnValue;
  }

  onSave(): void {
    this.isLoading = true;
    // console.log('in on save');
    // Copy the form values over the address object values
    // const portfolioValues = Object.assign({}, this.selectedServicePortfolioModel, this.portfolioForm.value);
    const portfolioValues = Object.assign({}, this.portfolioForm.value, { isAccepted: this.isServiceAccepted, isActive: this.isServiceActive, operatingZones: this.operatingZones });
    console.log(this.portfolioForm.value);

    this.portfolioService.setPortfolio(portfolioValues)
      .subscribe(
        updatedModel => this.onSaveComplete(updatedModel),
        (error: HttpErrorResponse) => this.onReceiveCodeError(error)
      );
  }
  onSaveComplete(portfolioModel: PortfolioModel) {
    // console.log('Save compeleted');
    this.showSuccessMessage = true;
    this.portfolioReceived(portfolioModel);
    this.canActivate = this.onCanActivate(portfolioModel);
    this.isLoading = false;
  }

  onReceiveCodeError(error: HttpErrorResponse) {
    console.log(error.message);
    // this.showErrors = true;
  }

}
