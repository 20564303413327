<main role="main" class="container">
    <div class="row">
        <div class="col-8">
            <h3 class="pb-4 mt-5 mb-2 font-italic border-bottom">Solicitudes de trabajo</h3>
            <div class="media text-muted pt-3" *ngFor="let order of currentOrders">
                <span class="border rounded calendar-icon align-middle mr-2 p-2">{{order.appointmentDate}}</span>
                <div class="media-body pb-4 mb-0 small lh-125 border-bottom border-gray">
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <strong class="text-gray-dark">A las {{order.appointmentTime}}, <b>{{order.duration}}h</b>
                          <span class="badge badge-success ml-2" *ngIf="order.isAccepted">Aceptada</span></strong>
                        <app-order-action-modal [orderModel]="order" [isCommentMode]="order.isReviewed" (actionPressed)="onOrderAction($event)"></app-order-action-modal>
                    </div>
                    <span class="d-block">{{order.customerAddress.houseAddress}} {{order.customerAddress.housePortal}}
            {{order.customerAddress.houseNumber}}, {{order.customerAddress.city}}</span>



                </div>
            </div>

        </div>
        <div class="col-4">
            <div class="d-flex justify-content-between align-items-center border-bottom">
                <h3 class="pb-3 mt-5 mb-2 font-italic ">Servicios Activos</h3>
                <a class="mt-3" routerLink="/professional/portfolio">Añadir</a>
            </div>

            <div class="list-group mt-3">
                <a *ngFor="let service of activeServices" routerLink="/professional/portfolio" class="list-group-item list-group-item-action ">{{service.serviceTitle}}</a>
            </div>
        </div>
    </div>
</main>