<ng-template [ngIf]="isCommentMode" [ngIfElse]="actionMode">
    <button type="button" class="btn btn-secondary" (click)="onTransferToDiscussion()">
    <fa-icon [icon]="['fas', 'comments']"></fa-icon>
  </button>
</ng-template>

<ng-template #actionMode>
    <div class="btn-group mr-2" role="group" aria-label="First group">
        <button type="button" class="btn btn-light" (click)="onOrderAction(content, true)">
      <fa-icon [icon]="['fas', 'check']"></fa-icon>
    </button>
        <button type="button" class="btn btn-light" (click)="onOrderAction(content, false)">
      <fa-icon [icon]="['fas', 'times']"></fa-icon>
    </button>
    </div>
</ng-template>


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{modelStateText}} el trabajo</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <dl class="dl-horizontal">
            <dt>Prestando Servicio</dt>
            <dd>{{orderModel.serviceTitle}}</dd>
            <dt>Nombre del cliente</dt>
            <dd>{{orderModel.customerName}}</dd>
            <dt>Ubicación</dt>
            <!-- <dd>{{orderModel.customerAddress.houseAddress}} {{orderModel.customerAddress.houseNumber}}, {{orderModel.customerAddress.postCode}} {{orderModel.customerAddress.city}}</dd> -->
            <dt>Hora de inicio</dt>
            <dd>{{orderModel.appointmentDate}} a las {{orderModel.appointmentTime}}</dd>
            <dt>Ingresos</dt>
            <dd>{{orderModel.total}} € ({{orderModel.duration}}h)</dd>
        </dl>
        <form novalidate [formGroup]="orderActionForm">
            <div class="form-group">
                <label for="houseNumber">Mensaje a la clienta</label>
                <textarea class="form-control" id="commentsControl" formControlName="comments" rows="3"></textarea>
                <div class="invalid-feedback">
                    Dile algo al cliente para comenzar la conversación.
                </div>
            </div>
            <ng-template [ngIf]="!isJobAccepted">
                <div class="form-group">
                    <label for="reasonControl">Razón</label>
                    <select class="form-control" id="reasonControl">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
                </div>
            </ng-template>
        </form>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">{{modelStateText}}</button>
    </div>
</ng-template>