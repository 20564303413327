<section class="pb-5">
    <h1 class="display-4 my-5"><span class="obrame-txt-hightlight-yellow">Obrame</span> Pricing</h1>
    <div class="row">
        <div class="col-12 col-lg-2 text-center">
            <div class="col-12 col-lg-6 mb-5">
                <div
                    class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                    <fa-icon [icon]="faInfo" size="2x"></fa-icon>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-10">
            <p>
                Discover the perfect plan to suit your needs and unlock the full potential of our services.
                We offer a
                range of subscription options tailored to meet the unique requirements of individuals,
                businesses, and
                organizations.
            </p>
            <p>
                Our transparent pricing structure ensures you know exactly what you're paying for and
                enables you to
                select the subscription that aligns with your budget. Explore the details of each plan to
                understand the
                included features, usage limits, and additional benefits.
            </p>

        </div>
    </div>
</section>
<section class="obrame-private-section">
    <div class="row justify-content-center">
        <div class="col-md-12 col-lg-11 p-4">
            <ul ngbNav #nav="ngbNav" class="nav-tabs" [activeId]="selectedTabId">
                <li *ngFor="let sub of models; index as i;" [ngbNavItem]="sub.id">
                    <button class="mx-1" ngbNavLink [disabled]="sub.isDisabled">{{sub.moduleTypeTitle}}</button>
                    <ng-template ngbNavContent>
                        <div class="row row-cols-1 row-cols-md-3 mb-3 text-center mt-5">
                            <div class="col d-flex align-items-stretch"
                                *ngFor="let template of sub.templates index as i; first as isFirst">
                                <div class="card obrame-card mb-4 rounded-3 shadow-sm"
                                    [class.border-primary]="template.isPromoted ">
                                    <div class="card-header py-3"
                                        [ngClass]="template.isPromoted ? 'card-header py-3 text-bg-primary border-primary': 'card-header py-3'">
                                        <h4 class="my-0 fw-normal">{{template.title}}</h4>
                                    </div>
                                    <div class="card-body">
                                        <h1 class="card-title pricing-card-title">{{template.price}} €<small
                                                class="text-body-secondary fw-light">/mo</small></h1>
                                        <p>{{template.description}}</p>
                                        <ng-template [ngIf]="sub.isPurchased" [ngIfElse]="buyButton">
                                            <div>
                                                Welcome back, friend.
                                            </div>
                                        </ng-template>

                                        <ng-template #buyButton>
                                            <button type="button"
                                                [routerLink]="['/profile', 'subscription', 'checkout', template.id]"
                                                [ngClass]="template.isPromoted ? 'w-100 btn btn-lg btn-obrame-primary': 'w-100 btn btn-lg btn-obrame-secondary'">
                                                {{ template.isPromoted ? 'Get Started' : 'Sign up for free'
                                                }}</button>
                                        </ng-template>

                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card mb-4 rounded-3 shadow-sm">
                                    <div class="card-header py-3">
                                        <h4 class="my-0 fw-normal">Enterprise</h4>
                                    </div>
                                    <div class="card-body">
                                        <h1 class="card-title pricing-card-title">X <small
                                                class="text-body-secondary fw-light">/mo</small></h1>
                                        <p>Customize your enterprise solution with our flexible
                                            subscription. Request an
                                            offer and
                                            tailor our services to meet your specific needs.</p><br>

                                        <button type="button"
                                            class="w-100 btn btn-lg btn-obrame-secondary">Contact
                                            us</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>


            <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
                <!-- <div class="col" *ngFor="let sub of model; index as i; first as isFirst">
            
                            </div> -->


            </div>
        </div>
    </div>
</section>