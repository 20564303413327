import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DiscussionModel } from '../models/discussion.model';
import { DiscussionMessageModel } from '../models/discussionMessage.model';
import { InboxModel } from '../models/inbox.model';

@Injectable()
export class InboxService {

    private serviceUrl = environment.apiUrl + 'discussion';
    constructor(private http: HttpClient) { }

    getInbox(amount: number, skip: number): Observable<InboxModel> {
        return this.http.get<InboxModel>(`${this.serviceUrl}/?amount=${amount}&skip=${skip}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    getDiscussion(id: string): Observable<DiscussionModel> {
        return this.http.get<DiscussionModel>(`${this.serviceUrl}/${id}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    getMessages(id: string, amount: number, skip: number): Observable<DiscussionMessageModel[]> {
        return this.http.get<DiscussionMessageModel[]>(`${this.serviceUrl}/${id}/messages/?amount=${amount}&skip=${skip}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    setMessage(discussionId: string, message: string): Observable<DiscussionMessageModel> {
        //const model = new DiscussionMessageModel(null, message, null, null, null, null);
        return this.http.put<DiscussionMessageModel>(`${this.serviceUrl}/${discussionId}`, {"Message": message}, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

}
