import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ProfilePictureComponent } from './profilePicture/profile-picture.component';
import { BirthdayPickerComponent } from './birthday-picker/birthday-picker.component';
import { PhoneNumberEditComponent } from './phone-number-edit/phone-number-edit.component';
import { LocationPickerComponent } from './location-picker/location-picker.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { NgbModule, NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { DualCardComponent } from './dual-card/dual-card.component';
import { ErrorAlertComponent } from './error-alert/error-alert.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FooterComponent } from './footer/footer.component';

import { AddressComponent } from './address/address.component';
import { AddressSelectComponent } from './address/address-select/address-select.component';
import { AuthTokenService } from './auth-token.service';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CardSelectorComponent } from './card-selector/card-selector.component';
import { ToastsContainer } from './toast/toasts-container.component';
import { PickerControlComponent } from './picker-control/picker-control.component';
import { TimelineComponent } from './timeline/timeline.component';
import { ProfileInfoComponent } from './profile-info/profile-info.component';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        FontAwesomeModule,
        RouterModule,
        NgbToast
    ],
    declarations: [
        ProfilePictureComponent,
        BirthdayPickerComponent,
        PhoneNumberEditComponent,
        LocationPickerComponent,
        ProfileCardComponent,
        DualCardComponent,
        ErrorAlertComponent,
        FooterComponent,
        AddressComponent,
        AddressSelectComponent,
        BreadcrumbComponent,
        ToastsContainer,
        CardSelectorComponent,
        PickerControlComponent,
        TimelineComponent,
        ProfileInfoComponent
    ],
    exports: [
        AddressComponent,
        PickerControlComponent,
        AddressSelectComponent,
        PhoneNumberEditComponent,
        ProfilePictureComponent,
        ProfileInfoComponent,
        BirthdayPickerComponent,
        LocationPickerComponent,
        ProfileCardComponent,
        DualCardComponent,
        ErrorAlertComponent,
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        FormsModule,
        FontAwesomeModule,
        FooterComponent,
        BreadcrumbComponent,
        ToastsContainer,
        TimelineComponent,
        CardSelectorComponent
    ],
    providers: [
        AuthTokenService
    ]
})

export class SharedModule {


      constructor(private library: FaIconLibrary) {
        library.addIcons(faPlus);
      }
}
