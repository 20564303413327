import { Injectable } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateUtilService {

  constructor() { }

  public generateDateText(date: any): string {
    if (date) { return date.day + '/' + date.month + '/' + date.year; } else { return ''; }
  }

  public generateDateValue(date: any): string {
    if (date) { return date.day + '-' + date.month + '-' + date.year; } else { return ''; }
  }

  public parseDate(str: string): NgbDate {
    if (str) {
      const m = str.match(/^(\d{1,2})-(\d{1,2})-(\d{4})$/);
      return (m) ? new NgbDate(+m[3], +m[2], +m[1]) : null;
    } else {
      const now = new Date();
      return new NgbDate(now.getFullYear(), now.getMonth(), now.getDate());
    }
   
  }

  public convertToDate(ngbDate: NgbDateStruct): Date {
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day + 1);
  }
}
