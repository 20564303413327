<button type="button" class="btn btn-obrame-secondary position-relative" (click)="open(filterModal)">
    <fa-icon [icon]="faFilter" [fixedWidth]="true" class="fixed"></fa-icon> Filters
    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
        {{filterCount}}
        <span class="visually-hidden">unread messages</span>
    </span>
</button>

<ng-template #filterModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Assignment Filters</h4>
        <button type="button" class="btn btn-secondary btn-obrame-secondary" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <fa-icon [icon]="faXmark"></fa-icon>
        </button>
    </div>
    <div class="modal-body d-flex">
        <div class="form-group">
            <label for="buttonDate" class="col-form-label">Start Date</label>
            <div class="input-group">
                <input id="startDate" readonly="true" class="form-control" placeholder="Choose date" name="dpStart"
                    ngbDatepicker #dStart="ngbDatepicker" [(ngModel)]="startDate">
                <div class="input-group-append">
                    <button id="buttonDate" class="btn btn-outline-secondary calendar" (click)="dStart.toggle()"
                        type="button">
                        <fa-icon [icon]="faCalendar"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="form-group mx-5">
            <label for="buttonDate" class="col-form-label">End Date</label>
            <div class="input-group">
                <input id="inputDate" readonly="true" class="form-control" placeholder="Choose date" name="dpEnd"
                    ngbDatepicker #dEnd="ngbDatepicker" [(ngModel)]="endDate">
                <div class="input-group-append">
                    <button id="buttonDate" class="btn btn-outline-secondary calendar" (click)="dEnd.toggle()"
                        type="button">
                        <fa-icon [icon]="faCalendar"></fa-icon>
                    </button>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-obrame-primary" (click)="appyFilters()">Apply</button>
    </div>
</ng-template>