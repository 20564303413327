<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <h1 class="display-4 text-center my-5">Create Your Free <span class="obrame-txt-hightlight-yellow">Obrame
                </span> Account</h1>
            <div class="d-flex justify-content-center align-items-center">
                <form novalidate (ngSubmit)="onSave()" [formGroup]="registerForm">
                    <h2 class="display-5 my-5">Account Information</h2>

                    <div class="col-12 col-lg-6 mt-4">
                        <label class="form-label" for="inputEmail">Email address</label>
                        <input type="email" class="form-control form-obrame-input" id="InputEmail"
                            aria-describedby="emailHelp" formControlName="email"
                            [ngClass]="{'is-invalid': registerForm.get('email').dirty && !registerForm.get('email').valid }">
                        <small id="emailHelp" class="form-text text-muted">
                            Your e-mail will be your Obrame ID. We will send you a verification email to activate the
                            account.
                        </small>
                        <div class="invalid-feedback">Please check your email format.</div>
                    </div>

                    <div class="col-12 col-lg-6 mt-4">
                        <label class="form-label" for="Password">Password</label>
                        <input type="password" class="form-control form-obrame-input" id="inputPassword"
                            aria-describedby="passwordHelp" formControlName="password"
                            [ngClass]="{'is-invalid': password.invalid && (password.dirty || password.touched)}">

                        <small id="passwordHelp" class="form-text text-muted mt-3">
                            Your password must be between 8 and 20 characters long, contain letters, numbers, and
                            special
                            characters, and must not contain spaces or emojis.
                        </small>
                        <div class="invalid-feedback">Please enter a valid password.</div>
                    </div>

                    <h2 class="display-5 my-5">Personal Information</h2>

                    <div class="row g-2">
                        <div class="col-md">
                            <label class="form-label" for="firstName">First name</label>
                            <input type="text" class="form-control form-obrame-input" id="firstName" value=""
                                formControlName="firstName"
                                [ngClass]="{'is-invalid': registerForm.get('firstName').dirty && !registerForm.get('firstName').valid }">
                            <div class="invalid-feedback">Please insert a valid name.</div>
                        </div>
                        <div class="col-md">
                            <label class="form-label" for="lastName">Last name</label>
                            <input type="text" class="form-control form-obrame-input" id="lastName" value=""
                                formControlName="lastName"
                                [ngClass]="{'is-invalid': registerForm.get('lastName').dirty && !registerForm.get('lastName').valid }">

                            <div class="invalid-feedback">Please insert a valid last name.</div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md mt-2">
                            <small id="emailHelp" class="form-text text-muted">Make sure it matches the name on your government ID.</small>
                        </div>
                    </div>

                    <h2 class="display-5 my-5">Date of birth</h2>
                    <p>
                        You must be at least 18 years old. This information will be private and no one else will see it.
                    </p>
                    <app-birthday-picker [parentForm]="registerForm"></app-birthday-picker>

                    <div *ngIf="!registrationCompleted" class="alert alert-secondary" role="alert">
                        Registration completed, please verify your email to activate your account.
                    </div>


                    <div class="alert alert-danger mt-5" role="alert" [hidden]="hideErrorMessage">
                        <div
                            class="d-inline-flex align-items-center justify-content-center p-4 me-3 fs-4 flex-shrink-0 rounded-circle obrame-login-icon-bg">
                            <fa-icon [icon]="alertIcon"></fa-icon>
                        </div>
                        {{errorMessage}}
                    </div>

                    <div class="d-grid col-8 mx-auto mt-5 pt-5 text-center">

                        <button type="submit" class="btn btn-obrame-primary"
                            [disabled]="!registerForm.valid || !registerForm.dirty || isLoading">
                            Agree and Register
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                *ngIf="isLoading"></span>
                        </button>
                        <p class="form-text mt-5">By selecting Agree and register, I agree to Obrames’s Terms of
                            Service, Payments Terms of Service, and Nondiscrimination Policy and acknowledge the 
                            <a href="https://www.obrame.com/terms/privacy_policy" target="_blank">Privacy Policy</a>.</p>


                    </div>

                </form>
            </div>
        </div>
    </div>
</div>