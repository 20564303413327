import { Component, OnInit } from '@angular/core';
import { DiscussionModel } from '../models/discussion.model';
import { Router, ActivatedRoute } from '@angular/router';
import { InboxService } from './inbox.service';
import { InboxModel } from '../models/inbox.model';

@Component({
    selector: 'app-inbox',
    templateUrl: './inbox.component.html',
    styleUrls: ['./inbox.component.css']
})
export class InboxComponent implements OnInit {

    model: InboxModel;
    // the resolver sets 10 and 0 so the next load will be 10 and 10
    amount = 10;
    skip = 10;


    constructor(private router: Router, private route: ActivatedRoute, private inboxService: InboxService) { }

    ngOnInit() {
        this.route.data.subscribe(data => {
            this.onDataRetrived(data['inboxData']);
        });
    }
    onDataRetrived(retrivedModel: InboxModel): void {
        console.log('discussions retrived');
        this.model = retrivedModel;

    }

}
