import { Component, Input, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbOffcanvas, OffcanvasDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, catchError, map, of, tap } from 'rxjs';
import { faStar, faBell, faBars, faUser, faPeopleGroup, faTableColumns, faMapLocationDot, faClipboardCheck, faMoneyCheck, faClipboard } from '@fortawesome/free-solid-svg-icons';

import { AccountService } from 'src/app/account/account.service';
import { ContentService } from 'src/app/shared/content.service';
import { SideBarNavigationModel } from 'src/app/models/sideBarNavigation.model';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent {

  faBell = faBell;
  faBars = faBars;
  faUser = faUser;
  faPeopleGroup = faPeopleGroup;
  faTableColumns = faTableColumns;
  faMapLocationDot = faMapLocationDot;
  faClipboardCheck = faClipboardCheck;
  faMoneyCheck = faMoneyCheck;
  faClipboard = faClipboard;
  faStar = faStar;


  // initialize a private variable _data, it's a BehaviorSubject
  private _data = new BehaviorSubject<boolean>(false);
  // change data to use getter and setter
  @Input()
  set data(value) {
    // set the latest value for _data BehaviorSubject
    this._data.next(value);
  };

  get data() {
    // get the latest value from _data BehaviorSubject
    return this._data.getValue();
  }

  model: SideBarNavigationModel;

  isLoggedIn = false;
  //Small screen menu button 
  navbarOpen = false;

  constructor(private accountService: AccountService, private offcanvasService: NgbOffcanvas, private router: Router, private contentService: ContentService) { }

  ngOnInit(): void {
    this._data
      .subscribe(x => {
        this.isLoggedIn = this.data;
        if (this.isLoggedIn) {
          this.getNavData();
        }
      });
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  getNavData() {
    this.contentService.getSideBarNavigation().pipe(
      // tap(_ => { console.log("Getting members"); this.isLoading = true; }),
      map(response => this.onNavDataComplete(response)),
      catchError(this.handleError<SideBarNavigationModel>("GetTopNavigation"))
    ).subscribe();

  }

  onNavDataComplete(navData: SideBarNavigationModel) {
    this.model = navData;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  onLogout(): void {
    this.offcanvasService.dismiss(OffcanvasDismissReasons.ESC);
    this.accountService.logout();
    this.router.navigateByUrl('/login');
  }

  openMobileNav(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: 'end' });
  }

  navigate(page: string) {
    this.offcanvasService.dismiss(OffcanvasDismissReasons.ESC);
    this.router.navigate([`/${page}`]);
  }
}
