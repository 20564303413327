

export class AssignmentActionModel {

  constructor(
    public id: string,
    public action: string,
    public message: string
  ) { }
}
