import { ResolveFn } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { SubscriptionService } from '../subscription.service';
import { inject } from '@angular/core';
import { SubscriptionCheckoutModel } from 'src/app/models/subscriptionCheckout.model';


export const SubCheckoutResolver: ResolveFn<SubscriptionCheckoutModel> = (
  route,
  state,
  service: SubscriptionService = inject(SubscriptionService)): Observable<SubscriptionCheckoutModel> =>

  service.getSubscriptionCheckout(route.paramMap.get('id')).pipe(
    map(service => {
      if (service) {
        return service;
      }
      console.log(`No onboarding service was found`);
      return null;
    }),
    catchError(error => {
      console.log(`Retrieval error: ${error}`);
      return of(null);
    })
);