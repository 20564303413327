import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OnboardingComponent } from './onboarding.component';
import { OnboardingBasicDataComponent } from './basic-data/basic-data.component';
import { OnboardingService } from './onboarding.service';
import { AuthGuard } from 'src/app/shared/auth-guard.service';
import { OnboardingResolver } from './onboarding-resolver.service';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [OnboardingComponent, OnboardingBasicDataComponent],
  imports: [
    RouterModule.forChild([
      {
        path: 'onboarding',
        component: OnboardingComponent,
        canActivate: [AuthGuard],
        resolve: { onboardingData: OnboardingResolver }
      }
    ]),
    CommonModule,
    SharedModule
  ],
  providers: [
    OnboardingService,
  ]
})
export class OnboardingModule { }
