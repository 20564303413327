<div class="container-fluid">
    <div class="row">
        <div class="col-12">

            <h1 class="display-4 text-center my-5"><span class="obrame-txt-hightlight-yellow">Obrame</span> Account
                Activation</h1>

            <ng-template [ngIf]="showSuccess">
                <div class="row pt-5">
                    <div class="col-3 d-flex justify-content-center">
                        <div class="col-10 col-sm-8 col-lg-6">
                            <div
                                class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                                <fa-icon [icon]="faCheck" size="2x"></fa-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-9">
                        <h3 class="mb-4">Email address confirmed</h3>
                        <p>Your email address has been confirmed and your Obrame account is now active. You can now Sign in
                            and start using the platform. To get started, please <strong><a [routerLink]="['/login']">Sign in</a></strong> from the link, and begin
                            the onboarding process.<br>
                        </p>

                        <p>If you have any questions or need assistance, please feel free to contact us. We're here to
                            help!</p>

                    </div>
                </div>

            </ng-template>
            <ng-template [ngIf]="showFailure">
                <div class="row pt-5">
                    <div class="col-3 d-flex justify-content-center">
                        <div class="col-10 col-sm-8 col-lg-6">
                            <div
                                class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg-error">
                                <fa-icon [icon]="faXmark" size="2x"></fa-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-9 col-lg-8">
                        <h3 class="mb-4">Expired Activation Link or Missing Account</h3>
                        <p>
                            We're sorry, but either your activation link has expired or the account you're trying to
                            access doesn't exist. If you have recently registered with Obrame platform, please check
                            your email for a new activation link.
                        </p>
                        <p>
                            If you can't find the email, please check your spam or junk folder. If you continue to
                            have
                            trouble, feel free to reach out to us for assistance.
                        </p>
                    </div>
                </div>
            </ng-template>

        </div>
    </div>
</div>