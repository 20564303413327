import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/auth.interceptor';
import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from '../shared/auth-guard.service';
import { ProfileProgressGuard} from '../profile/profileProgress.guard';
import { SidebarComponent } from './sidebar/sidebar.component';

import { PropertyComponent } from './property/property.component';
import { PropertyEditComponent } from './property/property-edit/property-edit.component';
import { PropertyEditResolver } from './property/property-edit/property-edit-resolver.service';
import { PropertiesResolver } from './property/property-resolver.service';
import { ServiceProviderComponent } from './service-provider/service-provider.component';
import { ServiceProviderResolver } from './service-provider/service-provider-resolver.service';
import { NgbDropdownModule, NgbToastModule, NgbTooltip, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { AssignmentsModule } from './assignments/assignments.module';
import { DashboardResolver } from './dashboard/dashboard-resolver.service';
import { SPDashboardComponent } from './dashboard/spdashboard/spdashboard.component';
import { PODashboardComponent } from './dashboard/podashboard/podashboard.component';
import { OnboardingModule } from './onboarding/onboarding.module';
import { TeamsModule } from './teams/teams.module';





@NgModule({
  declarations: [
    DashboardComponent,
    SidebarComponent,
    PropertyComponent,
    PropertyEditComponent,
    ServiceProviderComponent,
    SPDashboardComponent,
    PODashboardComponent
  ],
  imports: [
    RouterModule.forChild([
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard, ProfileProgressGuard],
        resolve: { dashboardData: DashboardResolver }
      },
      {
        path: 'propertyManagement',
        component: PropertyComponent,
        canActivate: [AuthGuard, ProfileProgressGuard],
        resolve: {  propertiesData: PropertiesResolver },
      },
      {
        path: 'propertyManagement/property',
        component: PropertyEditComponent,
        canActivate: [AuthGuard, ProfileProgressGuard],

      },
      {
        path: 'propertyManagement/property/:id',
        component: PropertyEditComponent,
        canActivate: [AuthGuard, ProfileProgressGuard],
        resolve: {  propertyData: PropertyEditResolver }
      },
      {
        path: 'serviceProvider',
        component: ServiceProviderComponent,
        canActivate: [AuthGuard, ProfileProgressGuard],
        resolve: {  moduleServiceData: ServiceProviderResolver }
      }
    ]),
    SharedModule,
    NgbTypeaheadModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbToastModule,
    AssignmentsModule,
    OnboardingModule,
    TeamsModule
  ],
  providers: [
    PropertyEditResolver,
    PropertiesResolver,
    ServiceProviderResolver,
    DashboardResolver,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  exports: [
    SidebarComponent
  ]
})
export class PrivateModule { }
