import { Component, OnInit, Input } from '@angular/core';
import { PortfolioCardModel } from 'src/app/models/portfolioCard.model';

@Component({
  selector: 'app-dual-card',
  templateUrl: './dual-card.component.html',
  styleUrls: ['./dual-card.component.css']
})
export class DualCardComponent implements OnInit {


  @Input() models: PortfolioCardModel[];
  constructor() { }

  ngOnInit() {

  }

}
