import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { OnboardingModel } from "src/app/models/onboarding.model";
import { OnboardingService } from "./onboarding.service";
import { inject } from "@angular/core";
import { Observable, catchError, map, of } from "rxjs";


export const OnboardingResolver: ResolveFn<OnboardingModel> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: OnboardingService = inject(OnboardingService)
): Observable<OnboardingModel> => service.getOnboarding().pipe(
        map(service => {
            if (service) {
                return service;
            }
            console.log(`No onboarding service was found`);
            return null;
        }),
        catchError(error => {
            console.log(`Retrieval error: ${error}`);
            return of(null);
        })
    );
