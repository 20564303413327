import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfessionalComponent } from './professional/professional.component';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/auth-guard.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/auth.interceptor';
import { SharedModule } from '../shared/shared.module';
import { OrderService } from './order.service';
import { ProfessionalOrderResolver } from './professional/professional-resolver.service';
import { ProfessionalOrderDetailsComponent } from './professional/professional-order-details/professional-order-details.component';
import { ProfessionalOrderDetailsResolver } from './professional/professional-order-details/professional-order-details-resolver.service';
import { NgbDateUtilService } from '../shared/ngb-date-utilities/ngb-date-util.service';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'orders/professional/:portfolioid',
        canActivate: [AuthGuard],
        component: ProfessionalComponent,
        resolve: {professionalOrderData: ProfessionalOrderResolver}
      },
      {
        path: 'orders/professional/details/:orderid',
        canActivate: [AuthGuard],
        component: ProfessionalOrderDetailsComponent,
        resolve: {professionalOrderData: ProfessionalOrderDetailsResolver}
      }]),
    SharedModule
  ],
  declarations: [ProfessionalComponent, ProfessionalOrderDetailsComponent],
  providers: [
    OrderService,
    NgbDateUtilService,
    ProfessionalOrderResolver,
    ProfessionalOrderDetailsResolver,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}]
})
export class OrdersModule { }
