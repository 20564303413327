import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { fbind } from 'q';
import { ProfileModel } from 'src/app/models/profile.model';
import { AddressModel } from 'src/app/models/address.model';
import { ProfileService } from 'src/app/profile/profile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SelectModel } from 'src/app/models/select.model';
import { AddressService } from 'src/app/profile/addresses/address.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressComponent } from 'src/app/shared/address/address.component';

import { faPlus } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-professional-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  faPlus = faPlus;
  model: ProfileModel;
  profileAddresses: SelectModel[];
  addressModel: AddressModel;
  addressControl: UntypedFormControl;
  closeResult: string;

  hasProfilePicture = false;
  showErrors = false;
  showSuccess = false;

  isAddressLoading = false;
  isLoading = false;

  portfolioUrl = '/professional/portfolio';

  constructor(private router: Router, private route: ActivatedRoute, private addressService: AddressService,
              private profileService: ProfileService, private modalService: NgbModal) { }

  ngOnInit() {
    this.model = this.route.snapshot.data.profileInfo;
    this.addressControl = new UntypedFormControl('', [Validators.required]);
    this.getAddresses();
    if (this.model && this.model.profilePictureId !== '00000000-0000-0000-0000-000000000000') {
      this.hasProfilePicture = true;
    }
  }

  onProfilePictureSlectionCompleted(isSelected: boolean) {
    this.hasProfilePicture = isSelected;
  }

  onPhoneVerificationCompleted(isValidated: boolean) {
    if (isValidated) {
      this.getProfile();
    }
  }


  private getAddresses() {
    this.isAddressLoading = true;
    this.addressService.getAddresses().subscribe(
      addresses => this.transformAddressToSelect(addresses)
      // error => this.errorMessage = <any>error
    );
  }
  private getProfile() {
    this.profileService.getProfile().subscribe(
      updatedProfile => this.profileUpdated(updatedProfile)
      // error => this.errorMessage = <any>error
    );
  }
  private profileUpdated(updatedProfile: ProfileModel) {
    this.model = updatedProfile;
  }

  transformAddressToSelect(addresses: AddressModel[]) {
    this.profileAddresses = new Array();
    if (addresses && addresses.length > 0) {
      this.profileAddresses.push(new SelectModel('', 'Selecciona su dirección'));
      addresses.forEach(element => {
        const title = `${element.street_Name} ${element.street_Number}, ${element.postal_Code}, ${element.city}`;
        this.profileAddresses.push(new SelectModel(element.id, title));
      });
    } else {
      const title = '- Por favor crea una nueva dirección -';
      this.profileAddresses.push(new SelectModel('', title));
    }
    this.isAddressLoading = false;
  }

  onOpenNewAddress() {
    this.modalService.open(AddressComponent, { size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.getDismissReason(reason);
    });
  }

  private getDismissReason(reason: any) {
    // console.dir('dissmiss' + reason);
    if (reason === 'saved') {
      this.getAddresses();
    }
  }

  onContinue() {
    this.isLoading = true;
    this.addressService.setWorkAddress(this.addressControl.value).subscribe(
      updatedAddress => this.addressUpdated(updatedAddress)
      // error => this.errorMessage = <any>error
    );
  }

  private addressUpdated(address: AddressModel) {
    if (address && address.isWork) {
      this.router.navigate([this.portfolioUrl]);
    }
  }
}
