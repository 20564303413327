<section class="pb-5">
    <h1 class="display-4 my-5">Change <span class="obrame-txt-hightlight-yellow">Obrame</span> Password</h1>
    <div class="row">
        <div class="col-12 col-lg-2 text-center">
            <div class="col-12 col-lg-6 mb-5">
                <div
                    class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                    <fa-icon [icon]="faInfo" size="2x"></fa-icon>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-10">
            <p>
                Protect your Obrame account with a strong and unique password. Safeguarding your personal information
                begins with regularly updating your password. Utilize this simple tool to modify your password and
                reinforce the security of your account.
            </p>
            <p>
                Changing your password is quick and effortless. Follow the intuitive steps provided to create a new
                password that meets the highest security standards. Our system ensures that your new password is
                encrypted and securely stored, offering you peace of mind in an ever-evolving digital landscape.
            </p>

        </div>
    </div>
</section>

<section class="pb-5">
    <div class="row">
        <div class="col-12 col">
            <form novalidate [formGroup]="passwordForm" (ngSubmit)="onSave()">

                <div class="d-flex justify-content-center ">
                    <div class="col-12 col-md-6">

                        <div>
                            <label class="form-label" for="inputCurrentPwd">Current Password</label>
                            <input type="password" class="form-control form-obrame-input" id="inputCurrentPwd"
                                placeholder="" aria-describedby="currentPwdHelp" formControlName="currentPassword"
                                [ngClass]="{'is-invalid': passwordForm.get('currentPassword').dirty && !passwordForm.get('currentPassword').valid }">

                            <small id="currentPwdHelp" class="form-text text-muted">
                                Type the current password.*
                            </small>
                        </div>

                        <div class="mt-4">
                            <label class="form-label" for="inputpassword">New Password</label>
                            <input type="password" class="form-control form-obrame-input" id="inputpassword"
                                placeholder="" aria-describedby="passwordHelp" formControlName="password"
                                [ngClass]="{'is-invalid': passwordForm.get('password').dirty && !passwordForm.get('password').valid }">

                            <small id="passwordHelp" class="form-text text-muted">
                                Type your new password.
                            </small>
                            <div class="invalid-feedback">
                                Please insert a valid password.
                            </div>
                        </div>

                        <div class="mt-4">
                            <label class="form-label" for="inputPasswordRetype">Retype Password</label>
                            <input type="password" class="form-control form-obrame-input" id="inputPasswordRetype"
                                placeholder="" aria-describedby="passwordConfirmdHelp" formControlName="passwordConfirm"
                                [ngClass]="{'is-invalid': passwordForm.get('passwordConfirm').dirty && !passwordForm.get('passwordConfirm').valid }">

                            <small id="passwordHelp" class="form-text text-muted">
                                Retype your new password
                            </small>
                            <div class="invalid-feedback">
                                Passwords does not match
                            </div>
                        </div>

                        <div class="alert alert-danger" role="alert" [hidden]="hideErrorMessage">
                            <div
                                class="d-inline-flex align-items-center justify-content-center p-4 me-3 fs-4 flex-shrink-0 rounded-circle obrame-login-icon-bg">
                                <fa-icon [icon]="alertIcon"></fa-icon>
                            </div>
                            {{errorMessage}}
                        </div>

                        <div class="alert alert-success" role="alert" [hidden]="hideSuccessMessage">
                            Your password has been changed successfully!
                        </div>

                        <div class="row justify-content-center mt-5 mb-5">
                            <button type="submit" class="btn btn-obrame-primary py-2"
                                [disabled]="isLoading || !passwordForm.valid || !passwordForm.dirty">
                                Save Password
                                <span class="spinner-border spinner-border-sm ml-2 mb-1" role="status"
                                    aria-hidden="true" *ngIf="isLoading"></span>
                            </button>

                        </div>

                    </div>
                </div>
            </form>
        </div>
    </div>
</section>