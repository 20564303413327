import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCity, faTableColumns, faLocationDot, faUsers, faClipboardCheck, faStar, faUser, faClipboard, faListCheck } from '@fortawesome/free-solid-svg-icons';
import { Observable, map, of, tap } from 'rxjs';
import { DashboardModel } from 'src/app/models/dashboard.model';
import { OnboardingModel } from 'src/app/models/onboarding.model';




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  model: DashboardModel;
  faLocationDot = faLocationDot;
  faUsers = faUsers;
  faClipboardCheck = faClipboardCheck;

  models$: Observable<OnboardingModel | null> = of(null);
 
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  constructor() { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.model = data.dashboardData;
    });

    // this.route.data.pipe(map(({ onboardingData }) => onboardingData), tap(onboardingData => console.log('data: ', onboardingData))).subscribe(val => console.log("at Subscriber " + val));;
    // console.log(this.models$);
  }

}
