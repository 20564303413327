import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faLocationDot, faUser } from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PickerControlItemModel } from 'src/app/models/pickerControlItem.model';
import { PropertyModuleService } from 'src/app/private/property/property-module.service';
import { TeamsService } from 'src/app/private/teams/teams.service';

@Component({
	selector: 'app-picker-control',
	templateUrl: './picker-control.component.html',
	styleUrls: ['./picker-control.component.css']
})
export class PickerControlComponent {

	@Input() type: string;
	@Input() includeIcon: boolean;
	@Input() includeContent: boolean;
	@Output() selectedItemEvent: EventEmitter<PickerControlItemModel> = new EventEmitter<PickerControlItemModel>();

	model: PickerControlItemModel[];
	selectedModel: PickerControlItemModel;

	title = '';
	inputPlaceholder = '';
	inputSelectedValue = '';
	closeResult = '';
	pageSize = 10;
	page = 1;
	buttonIcon = faLocationDot;

	constructor(private modalService: NgbModal, private propertyService: PropertyModuleService, private teamsService: TeamsService) { }

	ngOnInit(): void {
		//Before opening
		switch (this.type) {
			case "property":
				this.title = "My Properties";
				this.inputPlaceholder = "Select a Property";
				break;
			case "member":
				this.title = "Team members";
				this.inputPlaceholder = "Select a Member";
				this.buttonIcon = faUser;
				break;
			default:
				this.title = "Error: Invalid type"
				break;
		}
	}

	open(content) {
		if (!this.model) {
			switch (this.type) {
				case "property":
					this.getMyProperties();
					break;
				case "member":
					this.getMyTeamMembers();
					break;

				default:
					this.title = "Error: Invalid type"
					break;
			}
		}

		this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then(

			(result) => {
				this.closeResult = `Closed with: ${result}`;
				this.getDismissReason(result);
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}
	private getDismissReason(reason: any) {

		if (reason === "select") {
			this.selectedItemEvent.emit(this.selectedModel);

			if (this.includeContent) {
				this.inputSelectedValue = `${this.selectedModel.title} (${this.selectedModel.content})`;
			} else {
				this.inputSelectedValue = `${this.selectedModel.title}`;
			}
			
		}
	}
	private getMyProperties() {
		this.propertyService.getMyPropertyList(this.pageSize, this.page).pipe(
		//	tap(data => console.log('tap ' + data)),
			map(properties => {
				this.model = properties
			}),
			catchError(error => {
				console.log(`Retrieval error: ${error}`);
				return of(null);
			})
		).subscribe();
	}
	private getMyTeamMembers(){
		this.teamsService.getMyTeamMembers(this.pageSize, this.page).pipe(
				map(properties => {
					this.model = properties
				}),
				catchError(error => {
					console.log(`Retrieval error: ${error}`);
					return of(null);
				})
			).subscribe();
	}
	onSelect(selectedItem: PickerControlItemModel): void {
		this.selectedModel = selectedItem;

	}

}
