<div class="container">
    <div class="row">
        <div class="col-12">
            <section class="pb-5">
                <h1 class="display-4 my-5">Welcome to the <span class="obrame-txt-hightlight-yellow">Obrame</span>
                    Onboarding Process</h1>

                <div class="row pt-5">
                    <div class="col-12 col-lg-2 text-center">
                        <div class="col-12 col-lg-6 mb-5">
                            <div
                                class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                                <fa-icon [icon]="faInfo" size="2x"></fa-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-10">
                        <p>
                            Welcome to your profile, and congratulations on your Obrame account. Let’s get started by
                            filling up the required information. This information will provide Obrame with the necessary
                            information to set up your account and operate the best service.
                        </p>
                        <p>
                            You can always change these information form your profile and add more services to your
                            account.
                        </p>

                    </div>
                </div>
            </section>

            <section class="my-5" *ngIf="isStageOneVisible">
                <div class="row pt-5">
                    <div class="col-12 col-lg-2 text-center">
                        <div class="col-12 col-lg-6 mb-5">
                            <div
                                class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                                <fa-icon [icon]="faPhone" size="2x"></fa-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-10">
                        <h2 class="display-5 pb-4">Phone number for important notifications</h2>
                        <p>
                            Let's update your phone number phone important notifications and verifications. Bear in mind
                            that you can have only phone number attached to your account, and you can change the number
                            anytime in the future.
                        </p>
                        <p>
                            Please provide us your phone number in the box bellow to receive the verification code to
                            verify your number.
                        </p>

                    </div>
                </div>
                <div class="row mt-5">
                    <div class="offset-lg-2 col-12">
                        <app-phone-number-edit [isReadMode]="model.phoneNumberConfirmed"
                            [phoneNumber]="model.phoneNumber"
                            (phoneVerificationEvent)="onPhoneVerificationCompleted($event)">
                        </app-phone-number-edit>
                    </div>
                </div>
                <ng-template [ngIf]="model.phoneNumberConfirmed">
                    <div class="row pt-5">
                        <div class="col-12 col-lg-2 text-center">
                            <div class="col-12 col-lg-6 mb-5">
                                <div
                                    class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                                    <fa-icon [icon]="faBriefcase" size="2x"></fa-icon>
                                </div>
                            </div>
                        </div>
                        <div class="col-10" [formGroup]="onboardingForm">
                            <h2 class="display-5 pb-4">Business Name</h2>
                            <p>
                                Let's give a name to your business activity. Despite your activity, you are working as a
                                company or
                                person; we want to have a name for your business.
                            </p>
                            <div class="col-12 col-lg-6 mt-4">
                                <label class="form-label" for="inputBusinessName">Business Name</label>
                                <input type="text" class="form-control form-obrame-input mb-3" id="inputBusinessName"
                                    placeholder="" aria-describedby="businessNameHelp" formControlName="businessName">

                                <small id="businessNameHelp" class="form-text text-muted">
                                    The name could be "Lisa's Cleaning" or your company's name, "My Business plc".
                                </small>
                                <div class="invalid-feedback">The Business name is required.</div>
                            </div>
                        </div>
                    </div>

                    <div class="row pt-5">
                        <div class="col-12 col-lg-2 text-center">
                            <div class="col-12 col-lg-6 mb-5">
                                <div
                                    class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                                    <fa-icon [icon]="faPersonCircleCheck" size="2x"></fa-icon>
                                </div>
                            </div>
                        </div>
                        <div class="col-10">
                            <h2 class="display-5 pb-4">Obrame service Activation</h2>
                            <p>
                                Please choose your main functionality on Obrame. If you own properties and also provide
                                services to other owners you can add the second module after the onboarding to your
                                account.
                            </p>
                            <div class="col-12 mt-4" *ngIf="serviceModuleCards">
                                <app-card-selector [models]="serviceModuleCards"
                                    (cardSelectedEvent)="serviceSelected($event)">
                                </app-card-selector>

                            </div>
                        </div>
                    </div>

                    <div class="row pt-5">
                        <div class="col-12 col-lg-2 text-center">
                            <div class="col-12 col-lg-6 mb-5">
                                <div
                                    class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-circle-icon-bg">
                                    <fa-icon [icon]="faSliders" size="2x"></fa-icon>
                                </div>
                            </div>
                        </div>
                        <div class="col-10">
                            <h2 class="display-5 pb-4">Are you working as a company or individual?</h2>
                            <p>
                                Managing operations can be done as an individual or a company member. Please select the
                                operating model, and provide us with the information required so we can set up your
                                profile.
                                If you are working as a individial you can later join a company.
                            </p>
                            <div class="col-12 mt-4">
                                <app-card-selector [models]="operatingCardModels"
                                    (cardSelectedEvent)="operatingModelSelected($event)">
                                </app-card-selector>
                                <app-onboarding-basic-data [countriesModel]="countriesModel"
                                    [isCompanyVisible]="isCompanyvisible"
                                    [parentForm]="onboardingForm"></app-onboarding-basic-data>

                            </div>
                        </div>
                    </div>

                    <div class="d-grid col-6 mx-auto mt-5 pt-5">
                        <button type="button" (click)="onStageOneAction()" class="btn btn-obrame-primary py-2"
                            [disabled]="IsButtonDisabled() || isLoading">
                            Let's Choose Your Plan
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                *ngIf="isLoading"></span>
                        </button>
                    </div>
                </ng-template>
            </section>
        </div>
    </div>
</div>