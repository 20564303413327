import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProfessionalOrderModel } from '../models/professionalOrder.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class OrderService {

  private serviceUrl = environment.apiUrl + 'order/';

  constructor(private http: HttpClient) { }

  getOrder(id: string): Observable<ProfessionalOrderModel> {
    return this.http.get<ProfessionalOrderModel>(`${this.serviceUrl}${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getProfessionalOrder(model: ProfessionalOrderModel): Observable<ProfessionalOrderModel> {
    return this.http.post<ProfessionalOrderModel>(this.serviceUrl + 'calculateProfessionalOrder', model, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
  setProfessionalOrder(model: ProfessionalOrderModel): Observable<void> {
    return this.http.post<void>(this.serviceUrl + 'setProfessionalOrder', model, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

}
