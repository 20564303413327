<div class="card mt-5 py-4" *ngIf="models else loading">
    <div class="row text-center">
        <div class="col-6" *ngFor="let model of models">
            <app-profile-picture class="mt-3" [readMode]="true" [profilePictureUrl]="model.profilePictureUrl "></app-profile-picture>
            <h5>{{model.firstName}}</h5>
        </div>
    </div>
</div>


<ng-template #loading>
    <div>Loading...</div>
</ng-template>