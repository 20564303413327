import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { Observable, catchError, map, of, pipe } from 'rxjs';
import { SubscriptionService } from '../subscription.service';
import { SubscriptionListModel } from 'src/app/models/subscriptionList.model';

export const MySubscriptionsResolver: ResolveFn<SubscriptionListModel[]> = (
  route,
  state,
  service: SubscriptionService = inject(SubscriptionService)): Observable<SubscriptionListModel[]> =>

  service.getMySubscriptions().pipe(
    map(service => {
      if (service) {
        return service;
      }
      console.log(`No onboarding service was found`);
      return null;
    }),
    catchError(error => {
      console.log(`Retrieval error: ${error}`);
      return of(null);
    })
);