import { TimeModel } from './time.model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class ProfessionalOrderModel {

  constructor(
    public portfolioId: string,
    public orderDate: NgbDate,
    public orderTime: TimeModel,
    public addressId: string,
    public duration: number,
    public price: number,
    public total: number,
    public wishes: string,
    public paymentIntentId: string,
    public paymentSuccessId: string
  ) {  }
}
