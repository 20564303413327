import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AddressModel } from 'src/app/models/address.model';
import { PropertyModel } from 'src/app/models/property.model';
import { SelectModel } from 'src/app/models/select.model';
import { ContentService } from 'src/app/shared/content.service';
import { PropertyModuleService } from '../property-module.service';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-property-edit',
  templateUrl: './property-edit.component.html',
  styleUrls: ['./property-edit.component.css']
})
export class PropertyEditComponent implements OnInit {

  faPenToSquare = faPenToSquare;

  model: PropertyModel;
  areaUnits: SelectModel[];
  propertyId: string;

  propertyForm: UntypedFormGroup;
  title: UntypedFormControl;
  houseNumber: UntypedFormControl;
  houseSize: UntypedFormControl;
  houseSizeUnit: UntypedFormControl;
  numberOfRooms: UntypedFormControl;

  isEditMode = true;
  isLoading = false;
  isAddressSelected = false;
  hideErrorMessage = true;
  errorMessage = "";
  buttonText = "Create";



  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private router: Router,
    private propertyMService: PropertyModuleService, private contentService: ContentService) { }

  ngOnInit(): void {

    this.propertyId = this.route.snapshot.params['id'];
    //check lead Id here
    if (this.propertyId) {
      this.isEditMode = true;
    }


    if (!this.model) {
      this.model = new PropertyModel("", "", 0, 0, "", "", 0, "", null);
    }
    this.geSystemUnits();

    this.propertyForm = this.fb.group({
      title: new UntypedFormControl('', [Validators.required, Validators.required]),
      houseNumber: new UntypedFormControl(''),
      houseSize: new UntypedFormControl('', [Validators.maxLength(3), Validators.min(10), Validators.max(1000)]),
      houseSizeUnit: new UntypedFormControl(''),
      numberOfRooms: new UntypedFormControl('', [Validators.max(20), Validators.min(1)])
    });

    this.route.data.subscribe(data => {
      this.onPropertyRetrived(data.propertyData);
    });

  }

  setEditMode() {
    if (this.isEditMode) {
      this.isEditMode = false;
    } else {
      this.isEditMode = true;
    }
  }

  setCancel() {
    console.log(this.propertyId)
    if (this.propertyId) {
      if (this.isEditMode) {
        this.isEditMode = false;
      } else {
        this.isEditMode = true;
      }
    } else {
      this.router.navigate(['/propertyManagement']);
    }
  }

  onPropertyRetrived(property: PropertyModel): void {
    if (this.propertyForm) {
      this.propertyForm.reset();
    }
    if (property) {
      this.isEditMode = false;
      this.buttonText = "Save";
      this.isAddressSelected = true;
      this.model = property;

      // Update the data on the form
      this.propertyForm.patchValue({
        title: this.model.title,
        houseNumber: this.model.houseNumber,
        houseSize: this.model.houseSize,
        houseSizeUnit: this.model.houseSizeUnitId,
        numberOfRooms: this.model.numberOfRooms
      });
    }

  }

  geSystemUnits() {
    this.contentService.getSystemunits("Area").subscribe(
      units => this.areaUnits = units,
      (error: any) => this.onError(error)
    );
  }

  onAddressSelected(addressModel: AddressModel) {
    this.model.address = addressModel;
    this.isAddressSelected = true;
  }

  onSave(): void {
    this.isLoading = true;

    if (this.propertyForm.dirty && this.propertyForm.valid) {
      console.log('Form is valid');
      // Copy the form values over the  product object values
      const p = Object.assign({}, this.model, this.propertyForm.value);

      this.propertyMService.createModuleProperty(p)
        .subscribe(
          (data: PropertyModel) => this.onSaveComplete(data),
          (error: HttpErrorResponse) => this.onError(error)
        );
    }
  }

  onSaveComplete(data: PropertyModel): void {
    // this.registerForm.disable();
    this.hideErrorMessage = true;
    console.log('Registration is done: ' + data);
    this.router.navigate(['/propertyManagement']);
  }
  onError(error: HttpErrorResponse) {
    this.hideErrorMessage = false;
    this.isLoading = false;

    if (error.status === 400) {
      if (error.error.errors["Address.Street_Number"]) {
        //console.error('An unexpected error occurred:', error.error.errors["Address.Street_Number"]);
        this.errorMessage = " A street number is required in the address. E.g. Street 1";
      }else{
        this.errorMessage = "Please fil all required fields.";
      }
    } else if (error.status === 500 || error.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
    console.log(error);
  }
}
