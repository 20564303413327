import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignmentComponent } from './assignment/assignment.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthGuard } from 'src/app/shared/auth-guard.service';
import { ProfileProgressGuard } from '../../profile/profileProgress.guard';
import { OnboardingResolver } from '../onboarding/onboarding-resolver.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'src/app/shared/auth.interceptor';
import { PrivateModule } from '../private.module';
import { AssignmentPropertyOwnerComponent } from './assignment-property-owner/assignment-property-owner.component';
import { AssignmentPropertyOwnerResolver } from './assignment-property-owner/assignment-property-owner-resolver.service';
import { AssignmentServiceProviderComponent } from './assignment-service-provider/assignment-service-provider.component';
import { AssignmentServiceProviderResolver } from './assignment-service-provider/assignment-service-provider-resolver.service';
import { AssignmentProcessComponent } from './assignment-process/assignment-process.component';
import { AssignmentProcessResolver } from './assignment-process/assignment-process-resolver.service';
import { AssignmentResolver } from './assignment/assignment-resolver.service';

import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { AssignmentFiltersComponent } from './assignment-filters/assignment-filters.component';


@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'assignments/propertyOwner', component: AssignmentPropertyOwnerComponent, canActivate: [AuthGuard, ProfileProgressGuard], resolve: { assignmentData: AssignmentPropertyOwnerResolver } },
      { path: 'assignments/servicePorvider', component: AssignmentServiceProviderComponent, canActivate: [AuthGuard, ProfileProgressGuard], resolve: { assignmentData: AssignmentServiceProviderResolver } },
      { path: 'assignments/assignment', component: AssignmentComponent, canActivate: [AuthGuard, ProfileProgressGuard], resolve: { assignmentData: AssignmentResolver } },
      { path: 'assignments/assignment/:id', component: AssignmentProcessComponent, canActivate: [AuthGuard, ProfileProgressGuard], resolve: { assignmentData: AssignmentProcessResolver } },
    ]),
    SharedModule,
    MatFormFieldModule, 
    MatSelectModule
  ],
  declarations: [
    AssignmentComponent,
    AssignmentPropertyOwnerComponent,
    AssignmentServiceProviderComponent,
    AssignmentProcessComponent,
    AssignmentFiltersComponent
  ],
  providers: [
    AssignmentPropertyOwnerResolver,
    AssignmentServiceProviderResolver,
    AssignmentProcessResolver,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class AssignmentsModule { }
