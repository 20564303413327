import { Component, OnInit, Input } from '@angular/core';
import { BirthdayPickerService } from './birthday-picker.service';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-birthday-picker',
  templateUrl: './birthday-picker.component.html',
  styleUrls: ['./birthday-picker.component.css']
})
export class BirthdayPickerComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;

  days: Array<number>;
  months: Array<number>;
  years: Array<number>;

  constructor(private birthdayService: BirthdayPickerService) { }

  ngOnInit(): void {

    this.parentForm.addControl('year', new UntypedFormControl('', Validators.required));
    this.parentForm.addControl('month', new UntypedFormControl('', Validators.required));
    this.parentForm.addControl('day', new UntypedFormControl('', Validators.required));

    this.days = this.birthdayService.getDays();
    this.months = this.birthdayService.getMonths();
    this.years = this.birthdayService.getYears();
  }

}
