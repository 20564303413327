<div class="card" *ngIf="model else loading">
    <app-profile-picture class="mt-3" [readMode]="true" [profilePictureUrl]="model.profilePictureUrl"></app-profile-picture>
        
    <div class="card-body">
        <h5 class="card-title text-center">{{model.firstName}} {{model.lastName}}</h5>
        <!-- <p class="card-text">{{model.quickPitch}}</p> -->
    </div>
</div>
<ng-template #loading>
    <div>Loading...</div>
</ng-template>