import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PortfolioService } from '../portfolio.service';
import { PortfolioModel } from 'src/app/models/portfolio.model';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css']
})
export class ActivationComponent implements OnInit {

  @Input() isActiveMode: boolean;
  @Input() canActivate: boolean;
  @Input() serviceId: string;
  @Output() portfolioEvent: EventEmitter<PortfolioModel> = new EventEmitter<PortfolioModel>();

  isLoading = false;

  constructor(private portfolioService: PortfolioService) { }

  ngOnInit(): void {
  }

  onActivation(setActive: boolean) {
    this.portfolioService.setActivation(this.serviceId, setActive).subscribe(
      (data: PortfolioModel) => this.returnModel(data),
      (error: any) => {
        console.log(error);
        // this.hideLoginError = false;
      }
    );
  }
  returnModel(model: PortfolioModel) {
    this.portfolioEvent.emit(model);
  }

}
