import { Component, Input, OnInit } from '@angular/core';
import { SelectModel } from 'src/app/models/select.model';

import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-onboarding-basic-data',
  templateUrl: './basic-data.component.html',
  styleUrls: ['./basic-data.component.css']
})
export class OnboardingBasicDataComponent implements OnInit {
  @Input() isCompanyVisible: boolean;
  @Input() parentForm: FormGroup;
  @Input() countriesModel: SelectModel[]

  personalInfoForm = new FormGroup({
    countryOfRecidenceId: new FormControl('', Validators.required)
    });
  companyInfoForm = new FormGroup({
    companyName: new FormControl('',Validators.required),
    companyBusinessId: new FormControl('', Validators.required),
    companyCountryOfRegistrationId: new FormControl('', Validators.required)
  });

  constructor() { }

  ngOnInit(): void {
    this.parentForm.addControl('personalInfoForm', this.personalInfoForm);
    this.parentForm.addControl('company', this.companyInfoForm);
  }

}
