import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SelectModel } from '../models/select.model';
import { environment } from 'src/environments/environment';
import { SideBarNavigationModel } from '../models/sideBarNavigation.model';
import { CardItemModel } from '../models/cardItem.model';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  private serviceUrlCountries = environment.apiUrl + 'content/getCountries';
  private serviceUrlSideBarNav = environment.apiUrl + 'content/getSideBarNavigation';
  private serviceUrlServiceModules = environment.apiUrl + 'content/getServiceModules';
  private serviceUrlUnitSystem = environment.apiUrl + 'content/getSystemUnits';
  private serviceUrlSkills = environment.apiUrl + 'content/getSkills';
  private serviceUrlAllSkills = environment.apiUrl + 'content/getAllSkills';

  constructor(private http: HttpClient) { }

  getCountires(): Observable<SelectModel[]> {
    return this.http.get<SelectModel[]>(this.serviceUrlCountries, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getSideBarNavigation(): Observable<SideBarNavigationModel> {
    return this.http.get<SideBarNavigationModel>(this.serviceUrlSideBarNav, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getServiceModules(): Observable<CardItemModel[]> {
    return this.http.get<CardItemModel[]>(this.serviceUrlServiceModules, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getSystemunits(unitOf): Observable<SelectModel[]> {
    return this.http.get<SelectModel[]>(this.serviceUrlUnitSystem + '/' + unitOf, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  // getSkills(skillName): Observable<SelectModel[]> {
  //   return this.http.get<SelectModel[]>(this.serviceUrlSkills + '/' + skillName, {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   });
  // }

  // getAllSkills(): Observable<SelectModel[]> {
  //   return this.http.get<SelectModel[]>(this.serviceUrlAllSkills + '/', {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   });
  // }


}
