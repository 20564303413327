<main role="main" class="container">
    <div class="row">
        <div class="col-12">
            <h3 class="pb-4 my-4 font-italic border-bottom"> El servicio que quieres entregar</h3>
            <p>Revise todos los servicios disponibles y edite el portafolio para que coincida con sus habilidades. Cuanto más cuente con detalles sobre usted mismo, más atraerá a los clientes para solicitar sus servicios.
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h3 class="pb-4 my-5 font-italic border-bottom">Los Srvicios Disponibles</h3>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-3">
            <ul class="nav nav-fill nav-pills flex-column">
                <li class="nav-item" *ngFor="let proService of proServiceModles">
                    <a class="nav-link active" [class.active]="proService === selectedModel" (click)="onSelect(proService)">{{ proService.title }}</a>
                </li>
            </ul>
        </div>
        <div class="col-9" *ngIf="!isServiceLoading else loading">
            <div class="row">
                <div class="col-12">
                    <h4 class="border-bottom">{{selectedModel.title}}</h4>
                    <p>{{selectedModel.description}}</p>
                    <h4 class="border-bottom mt-5">Habilidades Requeridas y Expectativas</h4>
                    <p>{{selectedModel.skillsBasic}}</p>
                    <p>{{selectedModel.skillsAdvanced}}</p>
                    <p>{{selectedModel.requirements}}</p>
                </div>
            </div>

            <form novalidate (ngSubmit)="onSave()" [formGroup]="portfolioForm">

                <div class="row">
                    <div class="col-12">
                        <app-acceptance [isAccepted]="isServiceAccepted" [serviceId]="selectedModel.id" (portfolioEvent)="onCurrentServiceAccepted($event)"></app-acceptance>
                    </div>
                </div>

                <div class="row" *ngIf="isServiceAccepted">
                    <div class="col-12">
                        <h4 class="border-bottom mt-3">Tu tarifa</h4>
                        <div class="row">
                            <div class="col-2">
                                <input type="number" class="form-control " id="rateControl" placeholder="7" [formControl]="hourRate" min="7" max="500" [ngClass]="{ 'is-invalid': hourRate.invalid && (hourRate.dirty || hourRate.touched)}">
                                <div class="invalid-feedback">
                                    La tarifa por hora no puede ser inferior a 7 euros.
                                </div>
                            </div>
                            <div class="col-10">La mayoría de los usuarios con su experiencia contratados a € / h</div>

                        </div>
                        <h4 class="border-bottom mt-4 mb-3">El Lanzamiento Rápido</h4>
                        <div class="form-group">
                            <label for="quickPitchControl"><span [ngClass]="{ 'text-danger': quickPitch?.value?.length > 255}">{{quickPitch?.value?.length}}</span>/{{maxPitchLenght}}</label>
                            <textarea class="form-control" id="quickPitchControl" rows="4" spellcheck="false" [formControl]="quickPitch" [ngClass]="{ 'is-invalid': quickPitch.invalid && (quickPitch.dirty || quickPitch.touched)}"></textarea>
                            <div class="invalid-feedback">
                                Su descripción / lanzamiento rápido no puede tener más de 255 caracteres.
                            </div>
                        </div>
                        <h4 class="border-bottom mt-4 mb-3">El Nivel de Experiencia</h4>
                        <div class="form-group mb-5">
                            <label for="skillLevelControl"></label>
                            <select id="skillLevelControl" class="form-control" formControlName="levelOfExperieceId">
                                <option *ngFor="let s of levelOfExperiences" [value]="s.id">{{s.title}}</option>
                            </select>
                        </div>
                        <h4 class="border-bottom mt-4 mb-3">La Zona de Trabajo</h4>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>La ciudad o un código postal</label>
                                    <app-location-picker [resetAfterSelect]="true" searchScope="regions" (location)="onLocationSelected($event)" placeHolder="Busca su ubicación">
                                    </app-location-picker>
                                </div>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let zone of operatingZones">
                                        <p>
                                            <span *ngIf="zone.postal_Code">{{zone.postal_Code}}, </span>
                                            <span>{{zone.city}}</span>
                                        </p>
                                        <button type="button" class="btn btn-danger btn-xs pull-right remove-item" (click)="onRemoveZone(zone)">
                                            X
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-6"></div>
                        </div>
                        <hr class="my-4">
                        <div class="alert alert-danger" role="alert" *ngIf="operatingZones?.length === 0">
                            El servicio actual no tiene zona de trabajo y no aparecerá en la búsqueda. ¡Por favor agregue una ciudad o código postal!
                        </div>
                        <div class="alert alert-warning" role="alert" *ngIf="operatingZones?.length === 0">
                            Para poder activar el servicio, asegúrese de tener un precio y al menos una zona de trabajo.
                        </div>
                        <div class="alert alert-success" role="alert" *ngIf="showSuccessMessage">
                            La modificación de la cartera fue exitosa!
                        </div>
                        <button type="submit" class="btn btn-primary mr-3">Guardar la información <span
                                class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                *ngIf="isLoading"></span></button>
                        <app-activation [isActiveMode]="isServiceActive" [canActivate]="canActivate" [serviceId]="selectedModel.id" (portfolioEvent)="onCurrentServiceActivated($event)">
                        </app-activation>
                    </div>
                </div>
            </form>
        </div>

    </div>
</main>

<ng-template #loading>
    <div class="col-9 justify-content-center">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</ng-template>