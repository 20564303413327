<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <h1 class="display-4 text-center my-5">Sign-in to your <span class="obrame-txt-hightlight-yellow">Obrame </span> account</h1>
            <div class="d-flex justify-content-center align-items-center">
                <form novalidate (ngSubmit)="onLogin()" [formGroup]="loginForm">
                    <div class="mb-3">
                        <label for="inputEmail" class="form-label">E-mail address</label>
                        <input type="email" class="form-control form-obrame-input" id="inputEmail" aria-describedby="emailHelp" size="50"
                            formControlName="email"
                            [ngClass]="{'is-invalid': email.invalid && (email.dirty || email.touched)}">
                        <div id="emailHelp" class="form-text">Your personal email address which is your Obrame Id.</div>
                    </div>
                    <div class="mb-3">
                        <label for="inputPassword" class="form-label">Password</label>
                        <input type="password" class="form-control form-obrame-input" id="inputPassword" formControlName="password">
                    </div>
                    <div class="mt-4 mb-3">
                        <a class="obrame-link" [routerLink]='["/reset"]'>Forgot your password?</a>
                    </div>

                    <div class="alert alert-danger" role="alert" [hidden]="hideLoginError">
                        <div
                            class="d-inline-flex align-items-center justify-content-center p-4 me-3 fs-4 flex-shrink-0 rounded-circle obrame-login-icon-bg">
                            <fa-icon [icon]="alertIcon"></fa-icon>
                        </div>
                        {{errorMessage}}
                    </div>

                    <div class="d-grid mt-4">
                        <button type="submit" class="btn btn-obrame-primary py-2"
                            [disabled]="!loginForm.valid || !loginForm.dirty || isLoading">
                            Sign In
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                *ngIf="isLoading"></span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>