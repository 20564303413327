import { Injectable } from '@angular/core';
import { AddressGoogleModel } from 'src/app/models/adderssGoogle.model';

import { AddressModel } from 'src/app/models/address.model';

@Injectable({
  providedIn: 'root'
})
export class AddressParseService {



  constructor() { }

  public addAddress(address: Array<AddressGoogleModel>): AddressModel {
    let addressModel: AddressModel = new AddressModel('', '', false, false, false, '', '', '', '', '', '', '', '', '', '');
    this.parseAddress(addressModel, address);
    return addressModel;
  }
  public editAddress(addressModel: AddressModel, address: Array<AddressGoogleModel>): AddressModel {
    this.parseAddress(addressModel, address);
    return addressModel;
  }

  private parseAddress(addressModel: AddressModel, address: Array<AddressGoogleModel>): AddressModel {

    if (!Array.isArray(address)) {
      throw Error('Address Components is not an array');
    }
    if (!address.length) {
      throw Error('Address Components is empty');
    }

    for (let i = 0; i < address.length; i++) {
      const component: AddressGoogleModel = address[i];

      if (this.isStreetNumber(component)) {
        addressModel.street_Number = component.long_name;
      }

      if (this.isStreetName(component)) {
        addressModel.street_Name = component.long_name;
      }

      if (this.isCity(component)) {
        addressModel.city = component.long_name;
      }

      if (this.isCountry(component)) {
        addressModel.country = component.long_name;
      }

      if (this.isState(component)) {
        addressModel.state = component.long_name;
      }

      if (this.isPostalCode(component)) {
        addressModel.postal_Code = component.long_name;
      }
    }
    return addressModel;
  }

  private isStreetNumber(component: AddressGoogleModel): boolean {
    return component.types.includes('street_number');
  }

  private isStreetName(component: AddressGoogleModel): boolean {
    return component.types.includes('route');
  }

  private isCity(component): boolean {
    return component.types.includes('locality');
  }

  private isState(component): boolean {
    return component.types.includes('administrative_area_level_1');
  }

  private isCountry(component): boolean {
    return component.types.includes('country');
  }

  private isPostalCode(component): boolean {
    return component.types.includes('postal_code');
  }
}
