import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TeamInvitationModel } from 'src/app/models/teamInvitation.model';
import { TeamsService } from '../teams.service';



@Injectable()
export class TeamInviteResolver  {

  constructor(private teamService: TeamsService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TeamInvitationModel> {

    let invitationId = route.params.id;

    return this.teamService.getInvitationData(invitationId).pipe(
      map(model => {
        if (model) {
          return model;
        }
        console.log(`No invitation was found`);
        return null;
      }),
      catchError(error => {
        console.log(`Retrieval error: ${error}`);
        this.router.navigate(['/']);
        return of(null);
      })
    );
  }

}
