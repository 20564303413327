import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faShoppingCart, faInfo } from '@fortawesome/free-solid-svg-icons';
import { SubscriptionListModel } from 'src/app/models/subscriptionList.model';

@Component({
  selector: 'app-my-subscriptions',
  templateUrl: './my-subscriptions.component.html',
  styleUrls: ['./my-subscriptions.component.css']
})
export class MySubscriptionsComponent {
  model: SubscriptionListModel[];
  faShoppingCart = faShoppingCart;
  faInfo = faInfo;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ subscriptionData }) => {
      this.model = subscriptionData;
    })
  }
}
