import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from '../shared/shared.module';
import { ProfileInfoComponent } from './info/profile-info.component';
import { ProfilePasswordComponent } from './password/profile-password.component';
import { AuthGuard } from '../shared/auth-guard.service';
import { ProfileComponent } from './profile.component';
import { ProfileService } from './profile.service';
import { ProfileInfoResolver } from './info/profile-info-resolver.service';
import { AuthInterceptor } from '../shared/auth.interceptor';
import { AddressesComponent } from './addresses/addresses.component';
import { AddressesResolver } from './addresses/address-resolver.service';
import { NgbActiveModal, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { AddressRemoveComponent } from './addresses/address-remove/address-remove.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SubscriptionTemplateResolver } from './subscription/subscription-template.resolver';
import { SubCheckoutComponent } from './subscription/sub-checkout/sub-checkout.component';
import { SubCheckoutResolver } from './subscription/sub-checkout/sub-checkout.resolver';
import { MySubscriptionsComponent } from './subscription/my-subscriptions/my-subscriptions.component';
import { MySubscriptionsResolver } from './subscription/my-subscriptions/my-subscription.resolver';


@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'profile',
        canActivate: [AuthGuard],
        component: ProfileComponent,
        children: [
          {
            path: '',
            redirectTo: 'info',
            pathMatch: 'full'
          },
          {
            path: 'info',
            component: ProfileInfoComponent,
            resolve: {profileInfoData: ProfileInfoResolver}
          },
          {
            path: 'password',
            component: ProfilePasswordComponent
          },
          {
            path: 'mysubscriptions',
            component: MySubscriptionsComponent,
            resolve: {subscriptionData: MySubscriptionsResolver}
          },
          {
            path: 'subscriptions',
            component: SubscriptionComponent,
            resolve: {subscriptionTemplates: SubscriptionTemplateResolver}
          },
          {
            path: 'subscription/:id',
            component: SubscriptionComponent,
            resolve: {subscriptionTemplates: SubscriptionTemplateResolver}
          },
          {
            path: 'subscription/checkout/:id',
            component: SubCheckoutComponent,
            resolve: {subscriptionTemplate: SubCheckoutResolver}
          }
        ]
      }
    ]),
    SharedModule,
    NgbToastModule
  ],
  declarations: [
    ProfileComponent,
    ProfileInfoComponent,
    ProfilePasswordComponent,
    AddressesComponent,
    AddressRemoveComponent,
    SubscriptionComponent,
    SubCheckoutComponent,
    MySubscriptionsComponent,
  ],
  providers: [
    NgbActiveModal,
    ProfileService,
    AddressesResolver,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ]
})

export class ProfileModule {}
