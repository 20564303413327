import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from 'src/app/shared/content.service';
import { faLink, faCopy, faEnvelope, faTriangleExclamation, faInfo } from '@fortawesome/free-solid-svg-icons';
import { CardItemModel } from 'src/app/models/cardItem.model';
import { SelectModel } from 'src/app/models/select.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Clipboard } from '@angular/cdk/clipboard';
import { TeamsService } from '../teams.service';
import { TeamInviteModel } from 'src/app/models/teamInvite.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { Observable, catchError, map, of, tap } from 'rxjs';

@Component({
  selector: 'app-teams-invite',
  templateUrl: './teams-invite.component.html',
  styleUrls: ['./teams-invite.component.css']
})
export class TeamsInviteComponent {
  faLink = faLink;
  faCopy = faCopy;
  faEnvelope = faEnvelope;
  alertIcon = faTriangleExclamation;
  faInfo = faInfo;




  pageModel: TeamInviteModel;
  emailInvitationForm: UntypedFormGroup;
  email: UntypedFormControl;



  isLoading = false;
  isEdit = false;
  hideErrorMessage = true;
  hideSuccessMessage = true;
  errorMessage = "";
  successMessage = ""

  constructor(private route: ActivatedRoute, private router: Router, private fb: UntypedFormBuilder,
    private teamService: TeamsService, private toastService: ToastService) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.fillInviteForm(data.inviteData);
    });
  }

  fillInviteForm(invite: TeamInviteModel) {

    if (invite) {
      this.isEdit = true
      this.pageModel = invite;

      this.emailInvitationForm = this.fb.group({
        email: new UntypedFormControl(invite?.email, [Validators.email])
      });
    } else {
      this.emailInvitationForm = this.fb.group({
        email: new UntypedFormControl("", [Validators.required, Validators.email])
      });
    }

  }

  sendInvitation() {
    this.hideErrorMessage = true;
    if (this.emailInvitationForm.dirty && this.emailInvitationForm.valid) {
      this.isLoading = true;

      if (this.isEdit) {
        const invitationObject = Object.assign(this.pageModel, this.emailInvitationForm.value);
        this.teamService.updateInvite(invitationObject.id, invitationObject).subscribe(
          (data: TeamInviteModel) => this.onInviteComplete(data),
          (error: HttpErrorResponse) => this.onError(error)
        );
      } else {
        const invitationObject = Object.assign({}, this.emailInvitationForm.value);

        this.teamService.setInvitationRequest(invitationObject).pipe(
          tap(_ => { console.log("Setting sub"); this.isLoading = true; }),
          map(response => this.onInviteComplete(response)),
          catchError(this.handleError<TeamInviteModel>("SetInvite"))
        ).subscribe();
      }
    }
  }

  onInviteComplete(sentInvitation: TeamInviteModel) {
    if (this.isEdit) {
      this.toastService.show('The invitation was updated successfully!', { classname: 'obrame-toast-success', delay: 4000 });
      this.router.navigate(['/teams/members']);
    } else {
      this.isLoading = false;
      this.hideSuccessMessage = false;
      this.emailInvitationForm.reset();
      this.successMessage = `Your invitation to the e-mail was successfully sent to <b>${sentInvitation.email}</b>. You can use the same form to invite more team members.`
    }
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.isLoading = false;
      this.hideErrorMessage = false;
      this.errorMessage = error?.error;
      
      // TODO: send the error to remote logging infrastructure
      console.error(error.error);
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  onError(error: HttpErrorResponse) {
    this.hideErrorMessage = false;
    this.isLoading = false;
    if (error.status === 400) {
      this.errorMessage = error?.error || error?.error[0]?.description;
    } else if (error.status === 500 || error.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
    console.log(error);
  }

}
