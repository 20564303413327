import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { LoginModel } from '../../models/login.model';
import { AccountService } from '../account.service';
import { TokenModel } from '../../models/token.model';
import { HttpErrorResponse } from '@angular/common/http';
import {faUser, faLock, faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-account-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  faUser = faUser;
  faLock = faLock;
  alertIcon = faTriangleExclamation

  loginForm: UntypedFormGroup;
  email: UntypedFormControl;
  password: UntypedFormControl;
  loginInfo: LoginModel;
  returnUrl: string;
  hideLoginError = true;
  errorMessage: string;
  isLoading = false;

  constructor(private fb: UntypedFormBuilder, private _router: Router, private _route: ActivatedRoute, private _accountService: AccountService) { }

  ngOnInit(): void {
    this.email = new UntypedFormControl('', [Validators.required, Validators.email]);
    this.password = new UntypedFormControl('', [Validators.required, Validators.minLength(6)]);

    this.loginForm = new UntypedFormGroup({
      email: this.email,
      password: this.password
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams.returnUrl || '/dashboard';
  }

  onLogin(): void {
    this.isLoading = true;
    // Copy the form values over the  login object values
    const l = Object.assign({}, this.loginInfo, this.loginForm.value);

    this._accountService.login(l)
      .subscribe(
        (data: TokenModel) => this.onLoginComplete(data),
        (error: HttpErrorResponse) => this.onError(error)
      );

  }

  onLoginComplete(data): void {
    if (data && data.token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('userToken', data.token);
      // Using to subscribe if the user is logged in
      this._accountService.setIsLogin();
      // redirecting back to the address user came from
      this._router.navigate([this.returnUrl]);
    }
  }
  onError(httpError: HttpErrorResponse) {
    console.log('Login subscribe error: ');
    console.log(httpError);
    // reseting the login form
    this.loginForm.reset();
    this.hideLoginError = false;
    this.isLoading = false;


    if (httpError.status === 400) {

      switch (httpError.error) {
        case 'credentials':
          this.errorMessage = 'Incomplete login, please check your credentials!';
          break;
        case 'mailOrPasswordIncorrect':
          this.errorMessage = 'Incomplete login, please check your credentials!';
          break
        case 'mailNotConfirmed':
          // tslint:disable-next-line: max-line-length
          this.errorMessage = 'Your email address has not been confirmed. Please check your inbox for a validation email or contact support for assistance.';
          break;
        default:
          this.errorMessage = httpError?.statusText;
          break;
      }
    } else if (httpError.status === 500 || httpError.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
  }

}
