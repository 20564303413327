import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AddressModel } from 'src/app/models/address.model';
import { AddressService } from './address.service';



@Injectable()
export class AddressesResolver  {

    constructor(private addressService: AddressService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AddressModel[]> {
        return this.addressService.getAddresses().pipe(
            map(address => {
                //  if (address) {
                return address as AddressModel[];
                //   }
                //   return null;
            }),
            catchError(error => {
                console.log(`Retrieval error: ${error}`);
                this.router.navigate(['/']);
                return of(null);
            })
        );
    }
}
