import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SelectModel } from 'src/app/models/select.model';
import { TeamInvitationModel } from 'src/app/models/teamInvitation.model';
import { TeamInviteModel } from 'src/app/models/teamInvite.model';
import { TeamMemberModel } from 'src/app/models/teamMember.model';
import { TeamMemberTableModel } from 'src/app/models/teamMemberTable.model';
import { PickerControlItemModel } from 'src/app/models/pickerControlItem.model';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {
  private teamServiceUrl = environment.apiUrl + 'teams';

  constructor(private http: HttpClient) { }


  getMembers(page: number, pageSize: number): Observable<TeamMemberTableModel> {
    return this.http.get<TeamMemberTableModel>(`${this.teamServiceUrl}/getMembers?page=${page}&pageSize=${pageSize}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  setInvitationRequest(newInvitation: TeamInviteModel): Observable<TeamInviteModel> {
    return this.http.post<TeamInviteModel>(this.teamServiceUrl + '/setInvitationRequest', newInvitation, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getTeams(page: number, pageSize: number): Observable<TeamMemberTableModel> {
    return this.http.get<TeamMemberTableModel>(`${this.teamServiceUrl}/getTeams?page=${page}&pageSize=${pageSize}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getMyInvitations(): Observable<TeamInvitationModel[]> {
    return this.http.get<TeamInvitationModel[]>(this.teamServiceUrl + '/getMyInvitations', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getInvitation(id: string): Observable<TeamInvitationModel> {
    return this.http.get<TeamInvitationModel>(this.teamServiceUrl + '/getInvitation/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getInvitationData(id: string): Observable<TeamInvitationModel> {
    return this.http.get<TeamInvitationModel>(this.teamServiceUrl + '/getInvitationData/' + id, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getTeamInvitations(page: number, pageSize: number): Observable<TeamMemberTableModel> {
    return this.http.get<TeamMemberTableModel>(`${this.teamServiceUrl}/getTeamInvitations?page=${page}&pageSize=${pageSize}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }





  updateInvite(id: string, model: TeamInviteModel): Observable<TeamInviteModel> {
    return this.http.put<TeamInviteModel>(`${this.teamServiceUrl}/updateInvitationRequest/${model.id}`, model, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  setInvitation(newInvitation: TeamInvitationModel): Observable<TeamInvitationModel> {
    return this.http.post<TeamInvitationModel>(this.teamServiceUrl + '/setInvitation', newInvitation, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }






  getMyTeamMembers(pageSize: number, page: number): Observable<PickerControlItemModel[]> {
    return this.http.get<PickerControlItemModel[]>(`${this.teamServiceUrl}/getMyTeamMembers?page=${page}&pageSize=${pageSize}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }



  setTeamMembershipCancellation(membership: TeamMemberModel): Observable<TeamMemberModel> {
    return this.http.post<TeamMemberModel>(`${this.teamServiceUrl}/setTeamMembershipCancellation`, membership, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  setCancelInvitationRequest(membership: TeamInviteModel): Observable<TeamInviteModel> {
    return this.http.delete<TeamInviteModel>(`${this.teamServiceUrl}/cancelInvitationRequest/${membership.id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

}
