<div class="form-group">
    <label for="addressInput" *ngIf="showLable">{{label}}</label>
    <div class="input-group">
        <span class="spinner-border spinner-border-sm mr-2 mt-3" role="status" aria-hidden="true" *ngIf="isAddressLoading"></span>
        <select class="form-control mr-1" id="addressInput" [formControl]="addressControl" (ngModelChange)="onChange($event)" [ngClass]="{ 'is-invalid': addressControl.dirty && !addressControl.valid}">
            <option *ngFor="let address of profileAddresses" [value]="address.id">{{address.title}}</option>
        </select>
        <button class="btn btn-primary" (click)="onOpenNewAddress()">
            <fa-icon [icon]="faPlus"></fa-icon>
        </button>
    </div>
    <small id="phoneNumberHelp" class="form-text text-muted">
        La información de su dirección es privada y no la compartiremos con terceros.
    </small>
    <div class="invalid-feedback">
        por favor revise su formato de correo electrónico.
    </div>

</div>