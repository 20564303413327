import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map} from 'rxjs/operators';

import { ProfessionalServiceModel } from '../models/professionalService.model';
import { ProfessionalService } from './professional.service';



@Injectable()
export class ProfessionalServicesResolver  {

  constructor(private proService: ProfessionalService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProfessionalServiceModel[]> {
    return this.proService.getServices().pipe(
      map(proservices => {
          if (proservices) {
              return proservices;
          }
          console.log(`No Professional service was found`);
          this.router.navigate(['/']);
          return null;
      }),
      catchError(error => {
          console.log(`Retrieval error: ${error}`);
          this.router.navigate(['/']);
          return of(null);
      })
  );
  }

}
