import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faCalendar, faInfo, faCreditCard, faMoneyBill, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { NgbDateStruct, NgbDatepickerConfig, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { PickerControlItemModel } from 'src/app/models/pickerControlItem.model';
import { NgbDateUtilService } from 'src/app/shared/ngb-date-utilities/ngb-date-util.service';
import { AssignmnetService } from '../assignmnet.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { AssignmentModel } from 'src/app/models/assignment.model';
import { environment } from 'src/environments/environment';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { TimeModel } from 'src/app/models/time.model';
import { PaymentMethodModel } from 'src/app/models/paymentMethod.model';

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.css']
})
export class AssignmentComponent {

  faCalendar = faCalendar;
  faInfo = faInfo;


  isLoading = false;
  isEdit = false;
  hideErrorMessage = true;
  hideSuccessMessage = true;
  errorMessage = "";
  successMessage = ""
  isTeamMemberSelected = false;
  isPropertySelected = false;


  stripeKey = environment.stripeApiKey;
  @ViewChild('cardElement', { static: false }) cardElement: ElementRef;
  stripe: stripe.Stripe;
  card;
  cardErrors;
  paymentError = '';
  isPaymentValid = true;


  current = new Date();

  model = new AssignmentModel(null, null, 0, "", "", "", "", null, 0, "", "", "");
  assignmentForm: UntypedFormGroup;
  assignmentDateControl: FormControl<NgbDateStruct | null>;
  startTimeControl: FormControl;
  duration: UntypedFormControl;
  comments: UntypedFormControl;
  paymentMethodControl: UntypedFormControl;

  paymentMethods: PaymentMethodModel[] = [];
  cardMethodId = 'a970611a-fb8c-4b3a-82e4-3132a43ae22d';
  cashMethodId = 'bbf54573-369b-4d95-98cc-bbfd87ccf565';


  constructor(private route: ActivatedRoute, private router: Router, private calendarConfig: NgbDatepickerConfig,
    private fb: UntypedFormBuilder, private dateUtil: NgbDateUtilService, private assignmentService: AssignmnetService, private toastService: ToastService) {

    //The calendar config will be assigned to the calendar automatically 
    this.calendarConfig.minDate = {
      year: this.current.getFullYear(), month:
        this.current.getMonth() + 1, day: this.current.getDate()
    };
    this.calendarConfig.outsideDays = 'hidden';
  }

  ngOnInit(): void {

    this.route.data.subscribe(data => {
      this.setPaymentMethods(data.assignmentData);
    });

    this.assignmentDateControl = new FormControl<NgbDateStruct | null>(null, [Validators.required]);
    this.startTimeControl = new FormControl<NgbTimeStruct | null>(null, (control: FormControl<NgbTimeStruct | null>) => {
      const value = control.value;

      if (!value) {
        control.setErrors({ required: true });
        return null;
      }
      // if (value.hour < 6) {
      //   control.setErrors({ tooEarly: true });
      //   return { tooEarly: true };
      // }
      // if (value.hour > 20) {
      //   control.setErrors({ tooLate: true });
      //   return { tooLate: true };
      // }
      return null;
    });

    this.buildForm(this.model);

  }

  ngAfterViewInit() {
    if (this.model.paymentMethodId === this.cardMethodId) {
      this.initStripeElements();
    }
  }

  setPaymentMethods(paymentMethods: PaymentMethodModel[]) {
    this.paymentMethods = paymentMethods;

    paymentMethods.forEach(element => {
      switch (element.iconTitle) {
        case "faMoneyBill":
          element.faIcon = faMoneyBill;
          break;
        case "faCreditCard":
          element.faIcon = faCreditCard;
          break;
        // case "faPaypal":
        //element.faIcon = faPaypal;
        // break;
        default:
          element.faIcon = faQuestion;
          break;
      }
    });
    //this.paymentMethodControl = new UntypedFormControl(this.paymentMethods[0].id);
    this.model.paymentMethodId = this.paymentMethods[0].id;
  }

  buildForm(formModel: AssignmentModel) {
    this.assignmentForm = new UntypedFormGroup({
      assignmentDate: this.assignmentDateControl,
      startTime: this.startTimeControl,
      duration: new UntypedFormControl(formModel.duration, [Validators.required]),
      comments: new UntypedFormControl(formModel.comments),
      paymentMethodId: new UntypedFormControl(formModel.paymentMethodId),
    });
  }

  patchForm(formModel: AssignmentModel) {
    this.assignmentForm.patchValue({
      assignmentDate: { year: formModel.assignmentDate.year, month: formModel.assignmentDate.month, day: formModel.assignmentDate.day },
      startTime: this.startTimeControl.value,
      duration: formModel.duration,
      comments: formModel.comments,
      paymentMethodId: formModel.paymentMethodId
    });
  }

  onPropertySelected(item: PickerControlItemModel) {

    this.model.propertyId = item.id;
    this.isPropertySelected = true;
  }
  onTeamMemberSelected(item: PickerControlItemModel) {
    this.model.memberId = item.id;
    this.isTeamMemberSelected = true;
    this.calculate();
  }

  onPaymentMethodChanged(e) {
    // console.log(e);
    switch (e) {
      case 'bbf54573-369b-4d95-98cc-bbfd87ccf565'://cash
        this.isPaymentValid = true;
        break;
      case 'a970611a-fb8c-4b3a-82e4-3132a43ae22d'://card
        //Not working
        this.initStripeElements();
        break;
      case '0c3e86ff-312e-48a5-9723-6fbf06d08d9d'://paypal
        break;
    }

  }

  onReCalculate(workLoad: number) {
    //console.log('Choosing hour ' + workLoad);
    this.model.duration = workLoad;
    this.calculate();
  }
  calculate() {
    // console.log('calculating price');
    // console.log(this.assignmentForm.value);
    let o = Object.assign({}, this.model, this.assignmentForm.value);
    //Getting ngbdatestruct and converting it to date

    if (this.model.memberId && this.model.duration) {
      this.assignmentService.calculateAssignmentPriceRate(o).pipe(
        tap(_ => {
          // console.log(`${o} Calculating the assignment price`);
          this.isLoading = true;
          this.hideErrorMessage = true;
        }),
        map(response => this.onReCalculated(response)),
        catchError(this.handleError<AssignmentModel>('CalculateAssignmentPriceRate'))
      ).subscribe();
    }

  }

  onReCalculated(assignmentData: AssignmentModel) {
    //console.log('Re-calculated');
    this.model = assignmentData;
    //console.log(assignmentData);
    this.patchForm(assignmentData);
    this.isLoading = false;
  }

  onSave(): void {
    this.isLoading = true;
    const self = this;
    //console.log(this.checkoutModel.paymentIntentId);

    if (this.model.assignmentTotalPrice > 0) {
      switch (this.model.paymentMethodId) {
        case this.cardMethodId:
          this.stripe.confirmCardPayment(this.model.paymentIntentId, { payment_method: { card: this.card } })
            .then(function (result) {
              if (result.error) {
                // Show error to your customer
                self.isLoading = false;
                self.hideErrorMessage = false;
                self.errorMessage = result.error.message;
              } else {
                // The payment succeeded!
                self.onPaymentSucceed(result.paymentIntent.id, 'card');
                //console.log('payment successed');
                //console.log('https://dashboard.stripe.com/test/payments/' + result.paymentIntent.id);
              }
            });
          break;
        case this.cashMethodId:
          self.onPaymentSucceed(null, this.cashMethodId);
          break;
      }
    } else {
      self.onPaymentSucceed(null);
    }


  }

  onPaymentSucceed(paymentId: string, paymentMethod: string = 'card') {
    //console.log('In Payment successed');
    if (this.assignmentForm.dirty && this.assignmentForm.valid) {
      const newAssignment = Object.assign({}, this.model, this.assignmentForm.value);
      newAssignment.paymentId = paymentId;
      //console.log(newAssignment);

      this.assignmentService.setAssignment(newAssignment).pipe(
        tap(_ => {
          //console.log("Setting the new assignment");
          this.isLoading = true;
        }),
        map(response => this.onSaveComplete(response)),
        catchError(this.handleError<AssignmentModel>('setAssignment'))
      ).subscribe();
    }
  }

  onSaveComplete(data: AssignmentModel) {
    this.isLoading = false;
    this.toastService.show('The Assign,ent was created successfully!', { classname: 'obrame-toast-success', delay: 4000 });
    this.router.navigate(['/assignments/propertyOwner']);
  }

  onError(error: HttpErrorResponse) {
    this.hideErrorMessage = false;
    this.isLoading = false;
    if (error.status === 400) {
      if (error?.error == 'InvalidProfileOrPrice') {
        this.errorMessage = "The selected member has not define hour price. Please select another member or discuss that with your team member."
      } else {
        this.errorMessage = error?.error[0]?.description;
      }

    } else if (error.status === 500 || error.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
    console.log(error);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.hideErrorMessage = false;
      this.isLoading = false;

      if (error?.error == 'InvalidProfileOrPrice') {
        this.errorMessage = "The selected member has not define hour price. Please select another member or discuss that with your team member."
      } else {
        this.errorMessage = error?.error;
      }


      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  initStripeElements() {
    //This part will be run if the server returns payment intent id
    this.stripe = Stripe(this.stripeKey, { locale: 'en' });
    const elements = this.stripe.elements();
    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);
    this.card.addEventListener('change', event => {
      if (this.model.assignmentTotalPrice > 0) {
        this.isPaymentValid = false;
        if (event && !event.error && event.complete) {
          this.isPaymentValid = true;
        } else {
          this.cardErrors = event.error && event.error.message;
        }
      } else {
        this.isPaymentValid = true;
      }
      //console.log('Init stripe:' + this.isPaymentValid);
    });
  }
}
