<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <h1 class="display-4 text-center my-5">Reset <span class="obrame-txt-hightlight-yellow">Obrame</span>
                Password</h1>

            <p class=" text-center my-5">Enter the email associated with your Obrame account and we will send an email
                with
                instructions to reset your password.</p>
            <div class="d-flex justify-content-center align-items-center">
                <form novalidate (ngSubmit)="onReset()" [formGroup]="resetForm">
                    <div class="mb-3">
                        <label for="inputEmail" class="form-label">E-mail</label>
                        <input type="email" id="inputEmail" class="form-control form-obrame-input"
                            aria-describedby="emailHelp" size="50" formControlName="email"
                            [ngClass]="{'is-invalid': email.invalid && (email.dirty || email.touched)}">
                        <div class="invalid-feedback">
                            Please check your email format.
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <ng-template [ngIf]="showSuccess">
                            <div class="alert alert-success" role="alert">
                                <h2 class="display-5 mb-4">Check Your E-mail</h2>

                                <p>Please check your email. We have sent you an email that contains a link to reset your
                                    password. <br><em>Did not received the email? check your spam filter or try another
                                        email
                                        address.</em></p>
                                <a [routerLink]='["/login"]'>Back To Sign In Page</a>
                            </div>
                        </ng-template>
                        <ng-template [ngIf]="showFailure">
                            <div class="alert alert-danger" role="alert">
                                <b class="display-5"><fa-icon [icon]="alertIcon"></fa-icon> Operation was
                                    unsuccessful.</b>
                                <p class="mt-3">{{errorMessage}}</p>
                            </div>
                        </ng-template>
                    </div>
                    <div class="d-grid my-3">
                        <button type="submit" class="btn btn-obrame-primary py-2"
                            [disabled]="!resetForm.valid || !resetForm.dirty || isLoading">
                            Send
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                *ngIf="isLoading"></span>
                        </button>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>