import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamInvitationModel } from 'src/app/models/teamInvitation.model';
import { TeamsService } from '../teams.service';
import { faCheck, faXmark, faInfo } from '@fortawesome/free-solid-svg-icons';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, map, of, tap } from 'rxjs';


@Component({
  selector: 'app-team-invitation',
  templateUrl: './team-invitation.component.html',
  styleUrls: ['./team-invitation.component.css']
})
export class TeamInvitationComponent {

  faCheck = faCheck;
  faXmark = faXmark;
  faInfo = faInfo;

  invitationModel: TeamInvitationModel;

  isLoading = false;
  hideErrorMessage = true;
  errorMessage = "";

  constructor(private route: ActivatedRoute, private router: Router, private teamService: TeamsService) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.invitationModel = data.invitationData;
    });
  }

  onAcceptInvitation() {
    this.isLoading = true;

    this.invitationModel.IsAccepted = true;
    this.teamService.setInvitation(this.invitationModel).pipe(
      tap(_ => { console.log("onCancelInvitation"); this.isLoading = true; }),
      map(response => this.onActionComplete(response)),
      catchError(this.handleError<TeamInvitationModel>("onAcceptInvitation"))
    ).subscribe();
  }

  onCancelInvitation() { 
    this.invitationModel.IsAccepted = false;
    this.invitationModel.IsRejected = true;
    this.teamService.setInvitation(this.invitationModel).pipe(
      tap(_ => { console.log("onCancelInvitation"); this.isLoading = true; }),
      map(response => this.onActionComplete(response)),
      catchError(this.handleError<TeamInvitationModel>("onCancelInvitation"))
    ).subscribe();
  }

  onActionComplete(data){
    this.isLoading = false;
    this.router.navigate(['/teams/myteams']);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.isLoading = false;
      this.errorMessage = error?.error;
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);


      this.hideErrorMessage = false;
      this.errorMessage = error.message;

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  onError(error: HttpErrorResponse) {
    this.hideErrorMessage = false;
    this.isLoading = false;
    if (error.status === 400) {
      this.errorMessage = error?.error[0]?.description;
    } else if (error.status === 500 || error.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
    console.log(error);
  }

}
