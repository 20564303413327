<!-- https://freefrontend.com/bootstrap-timelines/ -->
<!-- https://www.bootdey.com/snippets/view/dark-timeline#html -->

<div class="timeline border-top">
    <ng-template [ngIf]="model" [ngIfElse]="loading">
        <div class="timeline-row" *ngFor="let tEvent of model;">
            <div class="timeline-time">
                {{tEvent.createdTime}}<small>{{tEvent.createdDate}}</small>
            </div>
            <div class="timeline-content">
                <i class="icon-attachment"></i>
                <h4>{{tEvent.title}}</h4>
                <p>{{tEvent.description}}</p>
                <p class="blockquote-footer" *ngIf="tEvent.message">{{tEvent.message}}</p>
                <!-- <div class="thumbs">
                <img class="img-fluid rounded" src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Maxwell Admin">
                <img class="img-fluid rounded" src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="Maxwell Admin">
                <img class="img-fluid rounded" src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="Maxwell Admin">
            </div> -->
                <div class="">
                <span class="badge badge-pill">{{tEvent.createdBy}}</span>
            </div>
            </div>
        </div>
    </ng-template>
    <ng-template #loading>
        <div class="timeline-row">
            <div class="timeline-content">
                <p class="m-0">Loading...</p>
            </div>
        </div>
    </ng-template>


</div>