import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable()

export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    console.log(`Auth interceptor - ${req.url} `);

    const token = localStorage.getItem('userToken');
    if (token && req.headers.get('No-Auth') !== 'True') {
      req = req.clone({
        setHeaders: { 'Authorization': `Bearer ${token} ` }
      });
    }
    return next.handle(req).pipe(catchError(err => this.handleError(err)));
  }

  handleError(err: any) {
    if (err instanceof HttpErrorResponse) {
      switch ((err as HttpErrorResponse).status) {
        case 401:
          localStorage.removeItem('userToken');
          this.router.navigate(['/login']);
          break;
        case 500:
          this.router.navigate(['/error']);
          break;
        default:
          return throwError(err);
      }
    } else {
      return throwError(err);
    }
  }
}
