import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


import { ProfessionalOrderModel } from 'src/app/models/professionalOrder.model';
import { OrderService } from '../order.service';
import { TimeModel } from 'src/app/models/time.model';
import { NgbDateUtilService } from 'src/app/shared/ngb-date-utilities/ngb-date-util.service';




@Injectable()
export class ProfessionalOrderResolver  {

  constructor(private orderService: OrderService, private router: Router, private ngbDateUtil: NgbDateUtilService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProfessionalOrderModel> {

    let portfolioId = route.params.portfolioid;
    if (portfolioId.indexOf('?') > -1) {
      portfolioId = portfolioId.substring(0, portfolioId.indexOf('?'));
    }

    var appointmentDate = route.queryParamMap.get('appointmentDate');

    if (portfolioId) {
      
      if (!appointmentDate) {
        appointmentDate = this.getQueryParamFromMalformedURL(state.url, "appointmentDate");
      }
      const model = new ProfessionalOrderModel(portfolioId, this.ngbDateUtil.parseDate(appointmentDate), new TimeModel(8, 0), '', 1, 0, 0, '', null, null);

      return this.orderService.getProfessionalOrder(model).pipe(
        map(proOrderModel => {
          if (proOrderModel) {
            return proOrderModel;
          }
          console.log(`Professional was not found.`);
          this.router.navigate(['/']);
          return null;
        }),
        catchError(error => {
          console.log(`Retrieval error: ${error}`);
          this.router.navigate(['/']);
          return of(null);
        })
      );
    } else {
      console.log(`No Portfolio found from query string.`);
      this.router.navigate(['/']);
    }
  }

  getQueryParamFromMalformedURL(url, name) {

    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(decodeURIComponent(url)); // or window.location.href
    if (!results) {
      return '';
    }
    return results[1] || '';
  }
}
