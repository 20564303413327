<main role="main" class="container">
  <div class="row">
    <div class="col-12">
      <h3 class="pb-4 my-5 font-italic border-bottom">Últimas conversaciones</h3>

      <ul class="list-unstyled" *ngIf="model else loading">
        <li class="media border-bottom mb-3" *ngFor="let discussion of model.discussions">
         
          <img *ngIf="discussion.participantPicture else svg" class="bd-placeholder-img mr-3"  width="64" height="64" src="{{discussion.participantPicture}}" alt="{{discussion.participantName}}">

          <div class="media-body">
            <h5 class="mt-0 mb-1"> <a [routerLink]="['discussion', discussion.id]">{{discussion.participantName}}</a></h5>
            <time class="text-secondary">{{discussion.updatedOn}}</time>
            <p>{{discussion.latestMessage}}</p>
          </div>
        </li>
      </ul>

      <ng-template #loading>
        <div>Loading...</div>
      </ng-template>
      <ng-template #svg>
        <svg class="bd-placeholder-img mr-3" width="64" height="64" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 64x64"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect><text x="50%" y="50%" fill="#dee2e6" dy=".3em">64x64</text></svg>
      </ng-template>
    </div>
  </div>
</main>
