import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ProfileService } from '../profile.service';
import { ChangePasswordModel } from '../../models/changePassword.model';

import {passwordMatchValidator} from 'src/app/utilities/form-validators'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { HttpErrorResponse } from '@angular/common/http';

import { faInfo } from '@fortawesome/free-solid-svg-icons';
  

@Component({
  selector: 'app-profile-password',
  templateUrl: './profile-password.component.html',
})

export class ProfilePasswordComponent implements OnInit {

  faInfo = faInfo;

  constructor(private _profileService: ProfileService) { }

  alertIcon = faTriangleExclamation;
  isLoading = false;
  errorMessage = "";

  passwordForm: UntypedFormGroup;
  currentPassword: UntypedFormControl;
  password: UntypedFormControl;
  passwordConfirm: UntypedFormControl;
  passwordModel: ChangePasswordModel;
  hideSuccessMessage = true;
  hideErrorMessage = true;

  ngOnInit(): void {
    this.currentPassword = new UntypedFormControl('', [Validators.required, Validators.minLength(8)]);
    this.password = new UntypedFormControl('', [Validators.required, Validators.minLength(8)]);
    this.passwordConfirm = new UntypedFormControl('', [Validators.required, Validators.minLength(8), passwordMatchValidator(this.password)]);

    this.passwordForm = new UntypedFormGroup({
      currentPassword: this.currentPassword,
      password: this.password,
      passwordConfirm: this.passwordConfirm
    });
  }




  onSave(): void {
    console.log('in on save');

    // Copy the form values over the  login object values
    const _p = Object.assign({}, this.passwordModel, this.passwordForm.value);

    this._profileService.setPassword(_p)
      .subscribe(
        () => this.onChangeCompleted(),
        (error: HttpErrorResponse) => this.onError(error)
      );

  }
  onChangeCompleted(): void {
    // reseting the form first
    this.passwordForm.reset();
    this.hideSuccessMessage = false;
  }

  onError(error: HttpErrorResponse) {
    this.hideErrorMessage = false;
    this.isLoading = false;
    if (error.status === 400) {
      this.errorMessage = `Unable to change the password, ${error?.error}.`;
    } else if (error.status === 500 || error.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
    console.log(error);
  }


}
