<div class="text-center">

    <ng-container *ngIf="!readMode else pictures">
        <a (click)="open(content)" class="profilePicOpenModal">
            <ng-container *ngTemplateOutlet="pictures"></ng-container>
        </a>
    </ng-container>

</div>


<ng-template #pictures>
    <div class="col-3" *ngIf="!hasProfilePicture && !newImageSelected">
        <div
            class="d-inline-flex align-items-center justify-content-center p-3 flex-shrink-0 rounded-circle obrame-profile-bg">
            <fa-icon [icon]="faUser" size="5x"></fa-icon>
        </div>
    </div>

    <img *ngIf="hasProfilePicture && !newImageSelected" [src]="profilePictureUrl" class="rounded-circle"
        style="width: 150px; height: 150px;">

    <img *ngIf="newImageSelected" [src]="imageShow" class="rounded-circle" style="width: 150px; height: 150px;">
</ng-template>

<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header ">
        <h4 class="modal-title " id="modal-basic-title ">Profile Picture</h4>
        <button type="button " class="btn btn-secondary btn-obrame-secondary" aria-label="Close"
            (click)="d('Cross click')">
            <fa-icon [icon]="faXmark"></fa-icon>
        </button>
    </div>
    <div class="modal-body ">
        <div class="row" *ngIf="!hasModelPicture && !hasProfilePicture">
            <div class="col-12 text-center">
                <div
                    class="d-inline-flex align-items-center justify-content-center p-3 flex-shrink-0 rounded-circle obrame-profile-bg">
                    <fa-icon [icon]="faUser" size="5x"></fa-icon>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="hasModelPicture">
            <div class="col-12 text-center">
                <img [src]="imageShow" height="200" class="profilePicture rounded-circle"
                    style="width: 150px; height: 150px;">
            </div>
        </div>
        <div class="row" *ngIf="hasProfilePicture && !hasModelPicture">
            <div class="col-12 text-center">
                <img *ngIf="hasProfilePicture && !newImageSelected" [src]="profilePictureUrl" class="rounded-circle"
                    style="width: 150px; height: 150px;">
            </div>
        </div>


        <div class="row">
            <div class="col-12 text-center">
                <label for="file-upload" class="btn btn-primary btn-obrame-success">
                    <fa-icon [icon]="faUpload"></fa-icon> Select a Picture
                </label>
                <input id="file-upload" type="file" accept="image/*" (change)="onFileChanged($event)" />
            </div>
        </div>


        <span style="font-weight:bold;color:green;" *ngIf="progress > 0 && progress < 100">
            {{progress}}%
        </span>

        <span style="font-weight:bold;color:green;" *ngIf="message">
            {{message}}
        </span>

    </div>
    <div class="modal-footer ">
        <button type="button " class="btn btn-obrame-primary" (click)="onSaveButton()" [disabled]="isLoading">
            Save
            <span class="spinner-border spinner-border-sm ml-2 mb-1" role="status" aria-hidden="true"
                *ngIf="isLoading"></span>
        </button>
    </div>
</ng-template>