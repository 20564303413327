import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InboxService } from '../inbox.service';
import { DiscussionModel } from '../../models/discussion.model';
import { DiscussionMessageModel } from 'src/app/models/discussionMessage.model';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Component({
    selector: 'app-discussion',
    templateUrl: './discussion.component.html',
    styleUrls: ['./discussion.component.css']
})
export class DiscussionComponent implements OnInit {

    model: DiscussionModel;
    messages: DiscussionMessageModel[];
    discussionId = '';
    amount = 10;
    skip = 0;
    newMessage = '';

    constructor(private router: Router, private route: ActivatedRoute, private inboxService: InboxService) { }

    ngOnInit() {
        const id: Observable<string> = this.route.params.pipe(map(p => p.id));
        id.subscribe(param => { this.discussionId = param; });

        // Get the discussion data
        this.route.data.subscribe(data => {
            this.onDiscussionRetrived(data.discussionServices);
        });
        // Get the first set of messages
        this.retriveMessages(this.amount, this.skip);
        this.skip += this.amount;
    }

    onDiscussionRetrived(retrivedModel: DiscussionModel): void {
        console.log('discussion model retrived');
        this.model = retrivedModel;
    }

    retriveMessages(amount: number, skip: number) {
        this.inboxService.getMessages(this.discussionId, amount, skip).subscribe(
            messages => this.messages = messages,
            (error: any) => {
                console.log(error);
                // this.hideLoginError = false;
            },
        );
    }

    sendMessage() {
        if (this.discussionId) {
            this.inboxService.setMessage(this.discussionId, this.newMessage).subscribe(
                message => this.setNewMessage(message),
                (error: any) => {
                    console.log(error);
                    // this.hideLoginError = false;
                },
            );
        } else {
            console.log('Invalid discussion id.');
        }

    }
    setNewMessage(message: DiscussionMessageModel) {
        this.messages.unshift(message);
    }

}
