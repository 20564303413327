import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

import { FormControl } from '@angular/forms';
import { AddressParseService } from '../address/address-parse.service';
import { AddressModel } from 'src/app/models/address.model';

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.css']
})
export class LocationPickerComponent implements OnInit {

  @Input() placeHolder: string;
  @Input() initialValue: string;
  // @Input() searchScope: string;
  @Input() resetAfterSelect: boolean;

  @Output() locationPickerEvent: EventEmitter<AddressModel> = new EventEmitter<AddressModel>();
  @ViewChild('locationSearch', { static: true })
  public searchElementRef: ElementRef;


  constructor(private addressParseService: AddressParseService) { }

  ngOnInit() {
    // console.log(this.searchScope);
    // this.setLocationAutoComplete();
    // console.log('reset after select' + this.resetAfterSelect);
  }

  ngAfterViewInit(): void {
    this.getPlaceAutocomplete();
  }
  getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
      // componentRestrictions: { country: ['se', 'de', 'gb', 'fr', 'it', 'es', 'pl', 'ro', 'nl', 'be', 'cz', 'gr', 'pt', 'hu', 'at', 'rs', 'ch', 'bg', 'dk', 'fi', 'sk', 'no', 'ie', 'hr', 'ba', 'lt', 'mk', 'si', 'lv', 'ee', 'me', 'lu', 'is', 'ad', 'mc', 'mt', 'li', 'sm', 'ae' ] },
      componentRestrictions: { country: ['es', 'de', 'uk', 'it', 'fr'] },
      types: ['establishment', 'geocode'],
      fields: ["address_components", "geometry"]
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        //User did not select a prediction; reset the input
        console.log("no place selected");
      }else{
        //display details about the valid place
        const address = this.addressParseService.addAddress(place.address_components);
        this.locationPickerEvent.emit(address);
      }
      
    })
  }

  // setLocationAutoComplete(): void {
  //   let scope = {};
  //   switch (this.searchScope) {
  //     case 'city':
  //       scope = {
  //         types: ['(cities)']
  //       };
  //       break;
  //     case 'regions':
  //       scope = {
  //         types: ['(regions)']
  //       };
  //       break;
  //     default:
  //       scope = {};
  //       break;
  //   }

  // }
}
