import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { AccountService } from './account.service';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ConfirmationResolver } from './confirmation/confirmation-resolver.service';
import { RegisterConfirmationComponent } from './register/register-confirmation/register-confirmation.component';
import { ResetComponent } from './reset/reset.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordResolver } from './reset-password/reset-password-resolver.service';


@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'register', component: RegisterComponent },
      { path: 'reset', component: ResetComponent },
      { path: 'reset/:code', component: ResetPasswordComponent, resolve: { resetServices: ResetPasswordResolver } },
      { path: 'login', component: LoginComponent },
      { path: 'account/confirmation/:userid/:code', component: ConfirmationComponent },
      { path: 'register/confirmation', component: RegisterConfirmationComponent }
    ]),
    SharedModule
  ],
  declarations: [
    RegisterComponent,
    LoginComponent,
    ConfirmationComponent,
    RegisterConfirmationComponent,
    ResetComponent,
    ResetPasswordComponent
  ],
  providers: [
    ConfirmationResolver,
    ResetPasswordResolver,
    AccountService
  ]
})

export class AccountModule { }
