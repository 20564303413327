<div class="d-flex align-items-center p-1">
    <div
        class="d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 rounded-circle obrame-login-icon-bg">
        <ng-container *ngIf="!profilePictureUrl else picture">
            <fa-icon [icon]="faBars" class="px-2"></fa-icon>
        </ng-container>
        <ng-template #picture>
            <img [src]="profilePictureUrl" width="27" height="27" class="rounded-circle">
        </ng-template>
    </div>
</div>