import { Component, OnInit } from '@angular/core';
import { PortfolioService } from './portfolio/portfolio.service';
import { PortfolioProServiceModel } from '../models/portfolioProService.model';
import { ProfessionalOrderListModel } from '../models/professionalOrderList.model';
import { ProfessionalService } from './professional.service';
import { ProfessionalOrderAcceptanceModel } from '../models/professionalOrderAcceptance.model';



@Component({
  selector: 'app-professional',
  templateUrl: './professional.component.html',
  styleUrls: ['./professional.component.css']
})
export class ProfessionalComponent implements OnInit {

  currentOrders: ProfessionalOrderListModel[];
  activeServices: PortfolioProServiceModel[];
  errorMessage = '';


  constructor(private portfolioService: PortfolioService, private proService: ProfessionalService) { }

  ngOnInit() {
    this.getActiveServices();
    this.loadCurrentOrders();
  }

  getActiveServices() {
    this.portfolioService.getActiveServices()
      .subscribe(
        services => this.activeServices = services,
        error => this.errorMessage = <any>error
      );
  }

  loadCurrentOrders() {
    console.log('Loading reservations');
    this.proService.getProfessionalOrders()
      .subscribe(
        orders => this.currentOrders = orders,
        error => this.errorMessage = <any>error
      );
  }

  onOrderAction(model: ProfessionalOrderAcceptanceModel) {

    this.proService.setOrderAcceptance(model)
      .subscribe(
        () => this.onAcceptanceResult(),
        error => this.errorMessage = <any>error
      );
  }
  onAcceptanceResult() {
    console.dir('onAcceptanceResult: re-loading orders');
    this.loadCurrentOrders();
  }
}

