import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressModel } from 'src/app/models/address.model';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { AddressParseService } from './address-parse.service';
import { AddressService } from 'src/app/profile/addresses/address.service';


@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {

  closeResult: string;
  @Input() model: AddressModel;
  predefinedAddress: string;

  isNewMode = false;
  modalTitle: string;
  isAddressSelected = false;
  showAddressErrorMsg = false;

  showErrors = false;
  isLoading = false;

  addressForm: UntypedFormGroup;
  inputTitle: UntypedFormControl;
  inputAdditional: UntypedFormControl;
  inputInstructions: UntypedFormControl;

  constructor(private fb: UntypedFormBuilder, private addressParseService: AddressParseService, private addressService: AddressService,
    private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    console.log('address component loaded');
    this.setFormcontrols();
    this.addressForm = this.generateAddressForm();
    this.setFormState(this.model);
  }

  private setFormState(model: AddressModel) {
    if (model) {
      this.modalTitle = 'Modificar la direccion: ' + model.title;
      this.isNewMode = false;
    } else {
      this.modalTitle = 'Añadir una nueva dirección';
      this.isNewMode = true;
    }
  }
  private setFormcontrols() {
    this.inputTitle = new UntypedFormControl('', [Validators.required]);
    this.inputAdditional = new UntypedFormControl('');
    this.inputInstructions = new UntypedFormControl('');

    if (!this.isNewMode && this.model) {
      // tslint:disable-next-line: max-line-length
      this.predefinedAddress = `${this.model.street_Name}, ${this.model.street_Number}, ${this.model.postal_Code}, ${this.model.city}, ${this.model.country}`;
      // Update the data on the form
      this.inputTitle.setValue(this.model.title);
      this.inputAdditional.setValue(this.model.additional_Address);
      this.inputInstructions.setValue(this.model.instructions);
    }
  }
  generateAddressForm(): UntypedFormGroup {
    return this.fb.group({
      title: this.inputTitle,
      additional_Address: this.inputAdditional,
      instructions: this.inputInstructions
    });
  }

  onLocationSelected(location: AddressModel) {
    this.model = location;
    this.isAddressSelected = true;
  }

  onSave() {
    // console.dir('in address save');
    this.isLoading = true;
    if (this.addressForm.dirty && this.addressForm.valid && this.isAddressSelected) {

      this.showErrors = false;
      this.model.isDeleted = false;
      this.model.isVerified = false;
      const address = Object.assign({}, this.model, this.addressForm.value);

      // console.log(address);

      this.addressService.createAddress(address).subscribe(
        () => this.onAddressSaved(),
        (error: any) => {
          console.log(error);
        },
      );
    } else {
      this.setFormAsDirty();
    }
  }

  onUpdate() {
    this.isLoading = true;
    if (this.addressForm.dirty && this.addressForm.valid) {

      this.showErrors = false;
      const address = Object.assign({}, this.model, this.addressForm.value);
      this.addressService.updateAddress(address).subscribe(
        val => {
         // console.log('PUT call successful value returned in body', val);
        },
        response => {
         console.log('PUT call in error', response);
        },
        () => {
          // console.log('The PUT observable is now completed.');
          this.onAddressSaved();
        }
      );
    } else {
      this.setFormAsDirty();
    }
  }

  private setFormAsDirty() {
    this.isLoading = false;
    this.inputTitle.markAsDirty();
    this.inputAdditional.markAsDirty();
    this.inputTitle.markAsDirty();
    this.inputInstructions.markAsDirty();
    this.showErrors = true;
  }

  onAddressSaved() {
    // console.log('dismissing');
    this.isLoading = false;
    this.modalService.dismissAll('saved');
  }

}
