import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AssignmnetService } from '../assignmnet.service';
import { AssignmentDisplaytModel } from 'src/app/models/assignmentDisplay.model';
import { FilterAssignmentModel } from 'src/app/models/filterAssignment.model'; // Import the FilterAssignmentModel class

@Injectable()
export class AssignmentPropertyOwnerResolver {

  constructor(private assignmentService: AssignmnetService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AssignmentDisplaytModel[]> {

    // Calculate today's date
    const today = new Date();
    // Calculate the date 30 days from today
    const endDate = new Date();
    endDate.setDate(today.getDate() + 30);
    // Create a FilterAssignmentModel object
    const filter = new FilterAssignmentModel(10, 1, today, endDate, []);

    return this.assignmentService.getPropertyOwnerAssignments(filter).pipe(
      map(model => {
        if (model) {
          return model;
        }
        console.log(`No assignments was found`);
        return null;
      }),
      catchError(error => {
        console.log(`Retrieval error: ${error}`);
        this.router.navigate(['/']);
        return of(null);
      })
    );
  }

}
