import { AddressModel } from "./address.model";

export class PropertyModel {

  constructor(
    public id: string,
    public title: string,
    public houseNumber: number,
    public houseSize: number,
    public houseSizeUnit: string,
    public houseSizeUnitId: string,
    public numberOfRooms: number,
    public addressTitle: string,
    public address: AddressModel

  ) {  }
}
