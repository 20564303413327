<div class="personalSection" [formGroup]="personalInfoForm">
    <h3 class="mt-5">Personal Information</h3>
    <div class="row g-2 mt-5">
        <div class="col-md col-lg-6">
            <label class="form-label" for="residencyCountryInput">Country of Residence</label>
            <select class="form-select form-obrame-select" id="residencyCountryInput"
                formControlName="countryOfRecidenceId" aria-describedby="residencyCountryHelp">
                <option *ngFor="let country of countriesModel" [value]="country.id">{{country.title}}</option>
            </select>

            <small id="residencyCountryHelp" class="form-text text-muted">
                The country of your primary residence and taxation.
            </small>

        </div>
    </div>
</div>

<div id="companySection" *ngIf="isCompanyVisible" [formGroup]="companyInfoForm">
    <h3 class="my-5">Company Information</h3>

    <!-- [ngClass]="{'is-invalid': registerForm.get('lastName').dirty && !registerForm.get('lastName').valid }"> -->
    <div class="row g-2">
        <div class="col-md">
            <label class="form-label" for="floatingInputCompanyName">Company's name</label>
            <input type="text" class="form-control form-obrame-input" id="floatingInputCompanyName" placeholder=""
                aria-describedby="companyNameHelp" formControlName="companyName">
            <small id="companyNameHelp" class="form-text text-muted">
                The legal name of the company in the registries.
            </small>
            <div class="invalid-feedback">The company name is required.</div>

        </div>
        <div class="col-md">
            <label class="form-label" for="lastName">Company ID</label>
            <input type="text" class="form-control form-obrame-input" id="floatingInputcompanyId" placeholder=""
                value="" formControlName="companyBusinessId">

            <small id="companyNameHelp" class="form-text text-muted">
                The official ID number of the company issued by the government.
            </small>
            <div class="invalid-feedback">The ID of the company is required.</div>

        </div>
    </div>

    <div class="row g-2 mt-5">
        <div class="col-md col-lg-6">

            <label class="form-label" for="companyCountryInput">Country of Registeration</label>
            <select class="form-select form-obrame-select" id="companyCountryInput"
                formControlName="companyCountryOfRegistrationId" aria-describedby="companyCountryHelp">
                <option *ngFor="let country of countriesModel" [value]="country.id">{{country.title}}</option>
            </select>

            <small id="companyCountryHelp" class="form-text text-muted">
                The country where the company is registered.
            </small>

        </div>
    </div>
</div>