import { ResolveFn } from '@angular/router';

import { inject } from '@angular/core';
import { Observable, catchError, map, of, pipe } from 'rxjs';
import { TeamsService } from '../teams.service';
import { TeamMemberTableModel } from 'src/app/models/teamMemberTable.model';

export const MytTeamsResolver: ResolveFn<TeamMemberTableModel> = (
  route,
  state,
  service: TeamsService = inject(TeamsService)): Observable<TeamMemberTableModel> =>

  service.getTeams(1, 10).pipe(
    map(teams => {
      if (teams) {
        return teams;
      }
      return null;
    }),
    catchError(error => {
      console.log(`Retrieval error: ${error}`);
      return of(null);
    })
);
