<!-- <input id="locationPickerInput" placeholder="{{placeHolder}}" autocorrect="off" aria-describedby="locationlHelp" autocapitalize="off" spellcheck="off" type="text" class="form-control obrame-form-floating-input" #locationSearch> -->

<label class="form-label" for="streetAddressAutocompelete">Street Address</label>
<input type="text" class="form-control form-obrame-input" id="streetAddressAutocompelete"
    aria-describedby="streetAddressHelp" autocorrect="off" autocapitalize="off" spellcheck="off"
    value="{{initialValue}}" #locationSearch>

<small id="streetAddressHelp" class="form-text text-muted">
    The street address of the property. E.g. Street 1, 123455 City
</small>
<div class="invalid-feedback">The street address is required.</div>