import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map} from 'rxjs/operators';
import { DashboardModel } from 'src/app/models/dashboard.model';
import { DashboardService } from './dashboard.service';
import { DashboardOverviewModel } from 'src/app/models/dashboardOverview.model';




@Injectable()
export class DashboardResolver  {

  constructor(private dashboardService: DashboardService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DashboardModel> {
    return this.dashboardService.getDashboard().pipe(
      map(model => {
          if (model) {
              return model;
          }
          console.log(`No dashboard was found`);
          this.router.navigate(['/']);
          return null;
      }),
      catchError(error => {
          console.log(`Retrieval error: ${error}`);
          this.router.navigate(['/']);
          return of(null);
      })
  );
  }

}
