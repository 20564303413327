import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faPeopleRoof, faBuilding, faCity, faClipboardList, faUser, faQuestion, faLink, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { CardItemModel } from 'src/app/models/cardItem.model';

@Component({
  selector: 'app-card-selector',
  templateUrl: './card-selector.component.html',
  styleUrls: ['./card-selector.component.css']
})
export class CardSelectorComponent implements OnInit {

  @Input() models: CardItemModel[];
  @Output() cardSelectedEvent: EventEmitter<CardItemModel> = new EventEmitter<CardItemModel>();
  selectedModel?: CardItemModel;

  constructor() { }

  ngOnInit(): void {
    if (this.models) {
      this.selectedModel = this.models[0];

      this.models.forEach(element => {
        switch (element.icon) {
          case "faUser":
            element.faIcon = faUser;
            break;
          case "faBuilding":
            element.faIcon = faBuilding;
            break;
          case "faClipboardList":
            element.faIcon = faClipboardList;
            break;
          case "faCity":
            element.faIcon = faCity;
            break;
          case "faEnvelope":
            element.faIcon = faEnvelope;
            break;
          case "faLink":
            element.faIcon = faLink;
            break;
          case "faPeopleRoof":
            element.faIcon = faPeopleRoof;
            break;
          default:
            element.faIcon = faQuestion;
            break;
        }
      });
    }
  }
  onSelectButton(model: CardItemModel) {
    this.selectedModel = model;
    this.cardSelectedEvent.emit(model);
  }

}
