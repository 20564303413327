<main class="container justify-content-center" role="main">
    <div class="row">
        <div class="col-12">
            <h3 class="pb-4 mt-4 mb-4 font-italic border-bottom">Foto de Perfil</h3>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-3">
            <app-profile-picture [readMode]="false" [profilePictureUrl]="model.profilePictureUrl" (hasProfilePicturEvent)="onProfilePictureSlectionCompleted($event)"></app-profile-picture>
        </div>
        <div class="col-6">
            <p>Por favor, actualice o verifique su imagen de perfil que transmite claramente quién es usted.</p>
            <p>Nuestro proceso automático validará su imagen y se quejará si la calidad de su foto no es suficiente.</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h3 class="pb-4 mt-4 mb-4 font-italic border-bottom">Numero de telèfono</h3>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-8">
            <app-phone-number-edit [isReadMode]="model.phoneNumberVerified" [phoneNumber]="model.phoneNumber" (phoneVerificationEvent)="onPhoneVerificationCompleted($event)">
            </app-phone-number-edit>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h3 class="pb-4 mt-4 mb-4 font-italic border-bottom">Dirección registrada</h3>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-8 ">
            <p>
                Esta información será verificada para verificar su identidad oficialmente. Seleccione una dirección de su perfil o agregue una nueva dirección.</p>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-8">
            <div class="form-group">
                <div class="input-group">
                    <span class="spinner-border spinner-border-sm mr-2 mt-3" role="status" aria-hidden="true" *ngIf="isAddressLoading"></span>
                    <select class="form-control mr-1" id="workAddressSelect" [formControl]="addressControl" [ngClass]="{ 'is-invalid': addressControl.dirty && !addressControl.valid}">
                        <option *ngFor="let address of profileAddresses" [value]="address.id">{{address.title}}</option>
                    </select>
                    <button class="btn btn-primary" (click)="onOpenNewAddress()">
                        <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                </div>
                <small id="phoneNumberHelp" class="form-text text-muted">
                    La información de su dirección es privada y no la compartiremos con terceros.
                </small>
                <div class="invalid-feedback">
                    por favor revise su formato de correo electrónico.
                </div>

            </div>
        </div>
    </div>
    <hr class="my-4">
    <div class="row justify-content-center">
        <div class="col-8">
            <button type="button" class="btn btn-primary btn-lg" (click)="onContinue()" [disabled]="!(model.phoneNumberVerified && this.addressControl.valid && hasProfilePicture)">
                Guardar y Continuar
                <span class="spinner-border spinner-border-sm ml-2 mb-1" role="status" aria-hidden="true"
                    *ngIf="isLoading"></span>
            </button>
        </div>
    </div>
</main>