import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TeamMembersComponent } from './team-members/team-members.component';
import { TeamsInviteComponent } from './teams-invite/teams-invite.component';
import { ProfileProgressGuard } from 'src/app/profile/profileProgress.guard';
import { AuthGuard } from 'src/app/shared/auth-guard.service';
import { TeamInviteResolver } from './teams-invite/team-invite-resolver.service';
import { TeamInvitationResolver } from './team-invitation/team-invitation-resolver.service';
import { TeamMembersResolver } from './team-members/team-members-resolver.service';
import { TeamMembershipsResolver } from './team-memberships-resolver.service';
import { TeamInvitationComponent } from './team-invitation/team-invitation.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'src/app/shared/auth.interceptor';
import { RouterModule } from '@angular/router';
import { MyTeamsComponent } from './my-teams/my-teams.component';
import { MytTeamsResolver } from './my-teams/my-teams-resolver.service';



@NgModule({
  declarations: [
    TeamsInviteComponent,
    TeamInvitationComponent,
    TeamMembersComponent,
    MyTeamsComponent
  ],
  imports: [
    RouterModule.forChild([
      {
        path: 'teams/myteams',
        component: MyTeamsComponent,
        canActivate: [AuthGuard, ProfileProgressGuard],
        resolve: { teamsData: MytTeamsResolver }
      },
      {
        path: 'teams/members',
        component: TeamMembersComponent,
        canActivate: [AuthGuard, ProfileProgressGuard],
        resolve: { teamsData: TeamMembersResolver }
      },
      {
        path: 'teams/invite',
        component: TeamsInviteComponent,
        canActivate: [AuthGuard, ProfileProgressGuard],

      },
      // {
      //   path: 'teams/invite/:id',
      //   component: TeamsInviteComponent,
      //   canActivate: [AuthGuard, ProfileProgressGuard],
      //   resolve: { inviteData: TeamInviteResolver }
      // },
      {
        path: 'teams/invitation/:id',
        component: TeamInvitationComponent,
        canActivate: [AuthGuard, ProfileProgressGuard],
        resolve: { invitationData: TeamInvitationResolver }
      }
    ]),
    CommonModule,
    SharedModule
  ],
  providers: [
    TeamInvitationResolver,
    TeamMembersResolver,
    TeamMembershipsResolver,
    TeamInviteResolver,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ]
})
export class TeamsModule { }
