import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SelectModel } from '../models/select.model';
import { ProfessionalServiceModel } from '../models/professionalService.model';
import { environment } from 'src/environments/environment';
import { ProfessionalOrderListModel } from '../models/professionalOrderList.model';
import { ProfessionalOrderAcceptanceModel } from '../models/professionalOrderAcceptance.model';


@Injectable()
export class ProfessionalService {

  private serviceUrl = environment.apiUrl + 'professionalServices';
  private serviceUrlOrder = environment.apiUrl + 'order';

  constructor(private http: HttpClient) { }

  getServices(): Observable<ProfessionalServiceModel[]> {
    return this.http.get<ProfessionalServiceModel[]>(this.serviceUrl, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
  getLevelsOfExperience(): Observable<SelectModel[]> {
    return this.http.get<SelectModel[]>(this.serviceUrl + '/levelsOfExperience', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getProfessionalOrders(): Observable<ProfessionalOrderListModel[]> {
    return this.http.get<ProfessionalOrderListModel[]>(this.serviceUrlOrder + '/getProfessionalOrders', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  setOrderAcceptance(model: ProfessionalOrderAcceptanceModel): Observable<boolean> {
    return this.http.post<boolean>(this.serviceUrlOrder + '/setProfessionalOrderAcceptance', model, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

}
