<div class="container">
    <div class="row">
        <div class="col-12">
            <section>
                <h1 class="display-4 my-5">Teams</h1>
                <div
                    class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-3 mb-4 obrame-action-row">
                    <div class="btn-toolbar mb-2 mb-md-0">
                        <div class="btn-group me-2">
                            <button type="button" (click)="getData('members')"
                                [className]="activeMode == 'members' ? 'btn btn-obrame-secondary btn-obrame-active' : 'btn btn-obrame-secondary'">
                                <fa-icon [icon]="faUsers" [fixedWidth]="true" class="fixed"></fa-icon> Members
                            </button>
                            <button type="button" (click)="getData('invitations')"
                                [className]="activeMode == 'invitations' ? 'btn btn-obrame-secondary btn-obrame-active' : 'btn btn-obrame-secondary'">
                                <fa-icon [icon]="faEnvelope" [fixedWidth]="true" class="fixed"></fa-icon> Invitations
                            </button>
                        </div>
                    </div>
                    <a class="btn btn-primary btn-obrame-success" [routerLink]='["/teams/invite"]'>
                        <fa-icon [icon]="faUserPlus" class="px-2"></fa-icon>
                        Invite Member
                    </a>
                </div>
            </section>
            <section>
                <ng-template [ngIf]="activeMode == 'members'" [ngIfElse]="templateInvitations">
                    <div class="row">
                        <div class="col-md-10 col-lg-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <caption>Total number of members: {{tableModel.count}}</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col">Business</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">State</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let member of tableModel.members">
                                            <th scope="row">{{member.businessTitle}}</th>
                                            <td>{{member.firstName}} {{member.lastName}}</td>
                                            <td>{{member.state}}</td>
                                            <td class="overflow-hidden">
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-obrame-secondary btn-sm"
                                                        ngbDropdownToggle>
                                                        <fa-icon [icon]="faEllipsis" [fixedWidth]="true"
                                                            class="fixed"></fa-icon>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <button (click)="onTeamCancellation(member)"
                                                            ngbDropdownItem>Remove from
                                                            Team</button>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [collectionSize]="tableModel.count" [(page)]="currentPage" [maxSize]="5"
                            [pageSize]="pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                            (pageChange)="getData('members')"></ngb-pagination>
                    </div>
                </ng-template>
                <ng-template #templateInvitations>
                    <div class="row">
                        <div class="col-md-10 col-lg-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <caption>Total number of pending invitations: {{tableModel.count}}</caption>
                                    <thead>
                                        <tr>
                                            <th scope="col">Email</th>
                                            <th scope="col">State</th>
                                            <th scope="col">Invitation Date
                                            </th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let member of tableModel.members">
                                            <th scope="row">{{member.email}}</th>
                                            <td>{{member.state}}</td>
                                            <td>{{member.invitationSentOn}}</td>
                                            <td class="overflow-hidden">
                                                <div ngbDropdown container="body" *ngIf="member.state === 'Pending'">
                                                    <button type="button" class="btn btn-obrame-secondary btn-sm"
                                                        ngbDropdownToggle>
                                                        <fa-icon [icon]="faEllipsis" [fixedWidth]="true"
                                                            class="fixed"></fa-icon>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <button (click)="onReSendInvitation(member)"
                                                            ngbDropdownItem>Send
                                                            Again</button>
                                                        <button (click)="onRemoveInvitation(member)"
                                                            ngbDropdownItem>Cancel
                                                            Invitation</button>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <ngb-pagination [collectionSize]="tableModel.count" [(page)]="currentPage" [maxSize]="5"
                        [pageSize]="pageSize" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                        (pageChange)="getData('invitations')"></ngb-pagination>
                </ng-template>
            </section>
        </div>
    </div>
</div>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>