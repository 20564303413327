import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectModel } from 'src/app/models/select.model';
import { ContentService } from 'src/app/shared/content.service';
import { faCreditCard, faReceipt, faArrowLeft, faInfo } from '@fortawesome/free-solid-svg-icons';
import { SubscriptionCheckoutModel } from 'src/app/models/subscriptionCheckout.model';
import { environment } from 'src/environments/environment';
import { SubscriptionService } from '../subscription.service';
import { Observable, catchError, map, of, tap } from 'rxjs';

declare var Stripe: stripe.StripeStatic;

@Component({
  selector: 'app-sub-checkout',
  templateUrl: './sub-checkout.component.html',
  styleUrls: ['./sub-checkout.component.css']
})
export class SubCheckoutComponent {
  faCreditCard = faCreditCard;
  faReceipt = faReceipt;
  faArrowLeft = faArrowLeft;
  faInfo = faInfo;
  

  stripeKey = environment.stripeApiKey;
  @ViewChild('cardElement', { static: false }) cardElement: ElementRef;
  stripe: stripe.Stripe;
  card;
  cardErrors;
  paymentError = '';
  isPaymentValid = false;

  checkoutModel: SubscriptionCheckoutModel;
  countriesModel: SelectModel[];
  errorMessage = '';
  isLoading = false;
  hideErrorMessage = true;

  checkoutForm: UntypedFormGroup;

  constructor(private activatedRoute: ActivatedRoute, private contentService: ContentService, private fb: UntypedFormBuilder,
    private subService: SubscriptionService, private router: Router,) { }

  ngOnInit() {

    this.checkoutForm = this.fb.group({
      legalName: new UntypedFormControl('', Validators.required),
      taxId: new UntypedFormControl('', Validators.required),
      streetAddress: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl('', Validators.required),
      province: new UntypedFormControl(''),
      postalCode: new UntypedFormControl('', Validators.required),
      countryId: new UntypedFormControl('', Validators.required),
      orderNotes: new UntypedFormControl('')
    });

    this.activatedRoute.data.subscribe(({ subscriptionTemplate }) => {
      this.checkoutModel = subscriptionTemplate;
      this.generateForms(subscriptionTemplate);

    });
    this.getCountries();


  }
  ngAfterViewInit() {
    if (this.checkoutModel.paymentIntentId) {
      this.initStripeElements();
    }
  }

  generateForms(formModel: SubscriptionCheckoutModel) {

    if (!formModel.isCompany) {
      this.checkoutForm.controls['legalName'].removeValidators(Validators.required);
      this.checkoutForm.controls['taxId'].removeValidators(Validators.required);
    }
    if (this.checkoutModel.template.price == 0) {
      this.isPaymentValid = true;
    }
    this.checkoutForm.patchValue({
      legalName: formModel.businessInfo.legalName,
      taxId: formModel.businessInfo.taxId,
      streetAddress: formModel.businessInfo.streetAddress,
      city: formModel.businessInfo.city,
      province: formModel.businessInfo.province,
      postalCode: formModel.businessInfo.postalCode,
      countryId: formModel.businessInfo.countryId,
      orderNotes: formModel.orderNotes
    });
  }

  onActivateSubscription() {

    this.isLoading = true;

    const self = this;
    //console.log(this.checkoutModel.paymentIntentId);

    if (this.checkoutModel.template.price > 0) {
      this.stripe.confirmCardPayment(this.checkoutModel.paymentIntentId, { payment_method: { card: this.card } })
        .then(function (result) {
          if (result.error) {
            // Show error to your customer
            self.isLoading = false;
            self.hideErrorMessage = false;
            self.errorMessage = result.error.message;
          } else {
            // The payment succeeded!
            self.onPaymentSucceed(result.paymentIntent.id);
            //console.log('payment successed');
            //console.log('https://dashboard.stripe.com/test/payments/' + result.paymentIntent.id);
          }
        });
    } else {
      self.onPaymentSucceed(null);
    }
  }

  onPaymentSucceed(paymentId: string) {
    this.checkoutModel.paymentSuccessId = paymentId;
    //console.log("Sucess id:" + paymentId);

    this.checkoutModel.businessInfo = Object.assign({}, this.checkoutModel.businessInfo, this.checkoutForm.value);


    this.subService.setSubscription(this.checkoutModel).pipe(
      tap(_ => { console.log("Setting sub"); this.isLoading = true; }),
      map(response => this.onActionComplete(response)),
      catchError(this.handleError<SubscriptionCheckoutModel>('setSubscription'))
    ).subscribe();
  }

  onActionComplete(data) {
    this.isLoading = false;
    this.router.navigate(['profile/mysubscriptions']);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);


      this.hideErrorMessage = false;
      this.errorMessage = error.message;

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  onError(error: HttpErrorResponse) {
    if (error.status === 400) {
      this.errorMessage = error?.error[0]?.description;
    } else if (error.status === 500 || error.status === 0) {
      this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
    }
    console.log(error);
  }

  getCountries(): void {
    this.contentService.getCountires().subscribe(
      (m: SelectModel[]) => { this.countriesModel = m; },
      (error: HttpErrorResponse) => this.onError(error)
    );
  }
  initStripeElements() {
    //This part will be run if the server returns payment intent id
    this.stripe = Stripe(this.stripeKey, { locale: 'en' });
    const elements = this.stripe.elements();
    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);
    this.card.addEventListener('change', event => {
      if (this.checkoutModel.template.price > 0) {
        this.isPaymentValid = false;
        if (event && !event.error && event.complete) {
          this.isPaymentValid = true;
        } else {
          this.cardErrors = event.error && event.error.message;
        }
      } else {
        this.isPaymentValid = true;
      }
      console.log('Init stripe:' + this.isPaymentValid);
    });
  }

}
