<div class="row">
    <div class="col-12 col-lg-6">
        <div class="row align-items-center g-2">
            <div class="col-12 col-md-auto">
                <h4 class="text-center">Assignments</h4>
                <p class="lead text-center">This Week</p>
                <div class="d-flex justify-content-center align-items-center">
                    <div
                        class="d-inline-flex align-items-center justify-content-center border rounded-circle obrame-dashboard-info obrame-dashboard-info-green m-5">
                        <h3>{{ weekModel.numberOfTasks }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12 col-lg-6" >
        <h2 class="pb-4">Overview</h2>
        <div class="row align-items-center g-4">
            <div class="col-12 col-md-auto">
                <div class="d-flex align-items-center">
                    <div
                        class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-features-icon-bg">
                        <fa-icon [icon]="faUsers"></fa-icon>
                    </div>
                    <div class="ms-3">
                        <h4 class="mb-0">{{ dashboardModel.teamMemberships }} Team</h4>
                        <p class="text-800 fs--1 mb-0">Membership</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-auto">
                <div class="d-flex align-items-center">
                    <div
                        class="d-inline-flex align-items-center justify-content-center p-3 me-3 fs-4 flex-shrink-0 rounded-circle obrame-features-icon-bg">
                        <fa-icon [icon]="faClipboardCheck"></fa-icon>
                    </div>
                    <div class="ms-3">
                        <h4 class="mb-0">{{ dashboardModel.acceptedAssigments }} Assignments</h4>
                        <p class="text-800 fs--1 mb-0">Accepted</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>