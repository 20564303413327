<footer class="mx-5">
    <div class="container-flux">
        <div class="row pb-5">
            <div class="col-12 col-lg-3">
                <a href="/" class="d-inline-flex link-body-emphasis text-decoration-none">
                    <img src="./assets/obrame-logo.png" alt="Obrame" width="185" height="40">
                </a>
                <p class="pt-3">Obrame is workforce and payment management for rental properties. Find and invite service professionals to take care of your properties. Assign work orders, quality checks, and handle payments.</p>
            </div>
            <div class="col-12 col-lg-5 footer-nav">
                <nav id="obrame-navigation-top" class=" d-none d-lg-block">
                    <ul class="nav col-12 col-md-auto mb-2 justify-content-center text-center mb-md-0">
                        <li><a href="{{publicWebUrl}}/product" routerLinkActive="active" class="nav-link">What is Obrame</a></li>
                        <li><a  href="{{publicWebUrl}}/pricing" routerLinkActive="active" class="nav-link">Pricing</a></li>
                        <li><a href="{{publicWebUrl}}/company" class="nav-link">Company</a></li>
                    </ul>
                </nav>
            </div>
            <div class="col-12 col-lg-4">

            </div>
        </div>
        <div class="row border-top p-2">
            <div class="col-6">
                <p>© 2024 Obrame.com part of Saman Ventures S.L. All rights reserved.</p>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <ul class="nav col-md-5 list-unstyled d-flex">
                    <li class="ms-3">
                        <a class="text-body-secondary" href="https://www.youtube.com/@Obramecom">
                            <fa-icon [icon]="faYoutube" [fixedWidth]="true" size="2x" class="fixed"></fa-icon>
                        </a>
                    </li>
                    <li class="ms-3">
                        <a class="text-body-secondary" href="https://www.linkedin.com/company/84487195/admin/feed/posts/">
                            <fa-icon [icon]="faLinkedinIn" [fixedWidth]="true" size="2x" class="fixed"></fa-icon>
                        </a>
                    </li>
                    <li class="ms-3">
                        <a class="text-body-secondary" href="https://www.instagram.com/obramecom/">
                            <fa-icon [icon]="faInstagram" [fixedWidth]="true" size="2x" class="fixed"></fa-icon>
                        </a>
                    </li>
                    <li class="ms-3">
                        <a class="text-body-secondary" href="https://www.facebook.com/obramecom">
                            <fa-icon [icon]="faFacebook" [fixedWidth]="true" size="2x" class="fixed"></fa-icon>
                        </a>
                    </li>
                    <li class="ms-3">
                        <a class="text-body-secondary" href="https://twitter.com/obramecom">
                            <fa-icon [icon]="faXTwitter" [fixedWidth]="true" size="2x" class="fixed"></fa-icon>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>