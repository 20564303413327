import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { faFacebook, faYoutube, faInstagram, faLinkedinIn, faXTwitter  } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  publicWebUrl = environment.publicWebsiteUrl;
  faFacebook = faFacebook;
  faYoutube = faYoutube;
  faInstagram = faInstagram;
  faLinkedinIn = faLinkedinIn;
  faXTwitter = faXTwitter;


  constructor() { }

  ngOnInit(): void {}

}
