import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AddressService } from 'src/app/profile/addresses/address.service';
import { AddressModel } from 'src/app/models/address.model';
import { SelectModel } from 'src/app/models/select.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressComponent } from '../address.component';
import { UntypedFormControl, Validators } from '@angular/forms';

import { faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-address-select',
  templateUrl: './address-select.component.html',
  styleUrls: ['./address-select.component.css']
})
export class AddressSelectComponent implements OnInit {

  faPlus = faPlus;
  @Input() label: string;
  @Output() addressIdEvent: EventEmitter<string> = new EventEmitter<string>();
  isAddressLoading = false;
  showLable = false;
  profileAddresses: SelectModel[];
  closeResult: string;
  addressControl: UntypedFormControl;





  constructor(private addressService: AddressService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.addressControl = new UntypedFormControl('', [Validators.required]);
    this.getAddresses();
    if (this.label) {
      this.showLable = true;
    }
  }

  private getAddresses() {
    this.isAddressLoading = true;
    this.addressService.getAddresses().subscribe(
      addresses => this.transformAddressToSelect(addresses)
      // error => this.errorMessage = <any>error
    );
  }

  transformAddressToSelect(addresses: AddressModel[]) {
    this.profileAddresses = new Array();
    if (addresses && addresses.length > 0) {
      this.profileAddresses.push(new SelectModel('', 'Selecciona su dirección'));
      addresses.forEach(element => {
        const title = `${element.street_Name} ${element.street_Number}, ${element.postal_Code}, ${element.city}`;
        this.profileAddresses.push(new SelectModel(element.id, title));
      });
    } else {
      const title = '- Por favor crea una nueva dirección -';
      this.profileAddresses.push(new SelectModel('', title));
    }
    this.isAddressLoading = false;
  }
  onOpenNewAddress() {
    this.modalService.open(AddressComponent, { size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.getDismissReason(reason);
    });
  }
  private getDismissReason(reason: any) {
    console.dir('dissmiss' + reason);
    if (reason === 'saved') {
      this.getAddresses();
    }
  }
  onChange(value: string) {
    console.log(value);
    this.addressIdEvent.emit(value);
  }

}
