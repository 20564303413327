<ng-template #anonymousView>
    <header class="container">

        <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" [routerLink]='["/"]'>
                <img src="/assets/obrame-logo.png" width="159" height="35" alt="Obrame">
            </a>

            <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse p-3  justify-content-end" [ngClass]="{ 'show': navbarOpen }"
                id="navbarSupportedContent">
                <div class="d-flex">
                    <ul class="navbar-nav gap-5">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]='["login"]' [routerLinkActive]='["active"]'>Sign In</a>
                        </li>
                        <li class="nav-item d-grid">
                            <button type="button" class="btn btn-obrame-primary px-4" [routerLink]='["register"]'>Register</button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</ng-template>

<ng-template [ngIf]="isLoggedIn" [ngIfElse]="anonymousView">

    <div class="container-fluid header-obrame px-4">
        <header
            class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
            <a [routerLink]='["/"]' class="d-flex align-items-center col-6 col-md-6 col-lg-3 mb-2 mb-md-0">
                <img src="/assets/obrame-logo.png" width="159" height="35" alt="Obrame">
            </a>

            <ul id="topNav" class="nav col-12 col-md-auto mb-2 justify-content-center d-none d-md-none d-lg-flex d-xl-flex d-xxl-flex"
                *ngIf="model">
                <li class="nav-item" *ngIf="!model.isOnboarded"><a [routerLink]='["/onboarding"]' [routerLinkActive]='["active"]' class="nav-link">Onboarding</a></li>
                <li class="nav-item" *ngIf="model.isOnboarded"><a [routerLink]='["/dashboard"]' [routerLinkActive]='["active"]' class="nav-link">Today</a></li>
                <li class="nav-item" *ngIf="model.isPropertyManager"><a [routerLink]='["/teams/members"]' [routerLinkActive]='["active"]' class="nav-link">Teams</a></li>
                <li class="nav-item" *ngIf="model.isPropertyManager"><a [routerLink]='["/propertyManagement"]' [routerLinkActive]='["active"]' class="nav-link">Properties</a></li>
                <li class="nav-item" *ngIf="model.isPropertyManager"><a [routerLink]='["/assignments/propertyOwner"]' [routerLinkActive]='["active"]' class="nav-link">Assignments</a></li>
                <li class="nav-item" *ngIf="model.isServiceProvider"><a [routerLink]='["/teams/myteams"]' [routerLinkActive]='["active"]' class="nav-link">My Teams</a></li>
                <li class="nav-item" *ngIf="model.isServiceProvider"><a [routerLink]='["/serviceProvider"]' [routerLinkActive]='["active"]' class="nav-link">Pricing and Bio</a></li>
                <li class="nav-item" *ngIf="model.isServiceProvider"><a [routerLink]='["/assignments/servicePorvider"]' [routerLinkActive]='["active"]' class="nav-link">Assignments</a></li>
            </ul>

            <div class="col-6 col-md-6 col-lg-3 text-end" *ngIf="model">
                <div class="d-block d-md-block d-lg-none d-xl-none d-xxl-none">
                    <button class="btn btn-lg" (click)="openMobileNav(content)">
                        <fa-icon [icon]="faBars" class="px-2 "></fa-icon>
                    </button>
                </div>
                <ul class="nav gap-5 d-none d-md-none d-lg-block d-xl-block d-xxl-block">
                    <li class="nav-item dropdown">
                        <div ngbDropdown class="d-inline-block float_right" placement="bottom-right" display="dynamic">
                            <button class="button profile-button" id="navProfile" ngbDropdownToggle>
                                <app-profile-info></app-profile-info>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="navProfile">
                                <a ngbDropdownItem class="dropdown-item" [routerLink]='["profile"]'>
                                    My Profile
                                </a>
                                <div class="dropdown-divider"></div>
                                <a ngbDropdownItem class="dropdown-item" (click)="onLogout()">Logout</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </header>
    </div>
</ng-template>

<ng-template #content let-offcanvas class="offcanvas-obrame">
    <div class="offcanvas-header">
        <h4 class="offcanvas-title"></h4>
        <button type="button" class="btn-close pull-right" aria-label="Close"
            (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
        <ul class="nav nav-offcanvas-obrame flex-column">
            <div class="navbar-text navbar-text-obrame">Menu</div>
            <li class="nav-item" *ngIf="!model.isOnboarded">
                <a [routerLinkActive]='["active"]' class="nav-link" (click)="navigate('onboarding')">
                    <fa-icon class="fixed" [fixedWidth]="true" [icon]="faStar"></fa-icon>
                    Onboarding
                </a>
            </li>
            <li class="nav-item" *ngIf="model.isOnboarded">
                <a [routerLinkActive]='["active"]' class="nav-link" (click)="navigate('dashboard')">
                    <fa-icon class="fixed" [fixedWidth]="true" [icon]="faTableColumns"></fa-icon>
                    Dashboard
                </a>
            </li>
            <li class="nav-item" *ngIf="model.isPropertyManager">
                <a [routerLinkActive]='["active"]' class="nav-link" (click)="navigate('/teams/members')">
                    <fa-icon [icon]="faUser" [fixedWidth]="true" class="fixed" size=""></fa-icon>
                    Members
                </a>
            </li>
            <li class="nav-item" *ngIf="model.isOnboarded">
                <a [routerLinkActive]='["active"]' class="nav-link" (click)="navigate('/teams/myteams')">
                    <fa-icon [icon]="faPeopleGroup" [fixedWidth]="true" class="fixed" size=""></fa-icon>
                    My Teams
                </a>
            </li>
            <li class="nav-item" *ngIf="model.isPropertyManager">
                <a [routerLinkActive]='["active"]' class="nav-link" (click)="navigate('propertyManagement')">
                    <fa-icon [icon]="faMapLocationDot" [fixedWidth]="true" class="fixed" size=""></fa-icon>
                    Properties
                </a>
            </li>
            <li class="nav-item" *ngIf="model.isPropertyManager">
                <a [routerLinkActive]='["active"]' class="nav-link" (click)="navigate('/assignments/propertyOwner')">
                    <fa-icon [icon]="faClipboardCheck" [fixedWidth]="true" class="fixed" size=""></fa-icon>
                    Assignments
                </a>
            </li>
            <li class="nav-item" *ngIf="model.isServiceProvider">
                <a [routerLinkActive]='["active"]' class="nav-link" (click)="navigate('/serviceProvider')">
                    <fa-icon [icon]="faMoneyCheck" [fixedWidth]="true" class="fixed" size=""></fa-icon>
                    Pricing and Bio
                </a>
            </li>
            <li class="nav-item" *ngIf="model.isServiceProvider">
                <a [routerLinkActive]='["active"]' class="nav-link" (click)="navigate('/assignments/servicePorvider')">
                    <fa-icon [icon]="faClipboard" [fixedWidth]="true" class="fixed" size=""></fa-icon>
                    Assignments
                </a>
            </li>
        </ul>
        <div class="border-bottom mt-3"></div>
        <ul class="nav nav-offcanvas-obrame flex-column">
            <div class="navbar-text navbar-text-obrame">Account</div>
            <li class="nav-item">
                <a [routerLinkActive]='["active"]' class="nav-link" (click)="navigate('/profile/info')">
                    <fa-icon class="fixed" [fixedWidth]="true" [icon]="faTableColumns"></fa-icon>
                    Your Profile
                </a>
            </li>
        </ul>
        <div class="border-bottom mt-3 mb-5"></div>
        <div class="d-grid gap-2">
            <button class="btn btn-primary btn-obrame-secondary" type="button" (click)="onLogout()">Log out</button>
        </div>


    </div>
</ng-template>