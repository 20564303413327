<div class="container">
    <div class="row">
        <div class="col-12">
            <section>
                <nav class="obrame-nav-secondary">
                    <ul class="nav gap-3 d-flex justify-content-center align-content-center">
                        <li class="nav-item d-flex align-items-center">
                            <fa-icon [icon]="faAddressCard" size="2x"></fa-icon>
                            <a class="nav-link" [routerLink]="['info']" [routerLinkActive]="['active'] "
                                [routerLinkActiveOptions]="{exact: true} ">Basic Information</a>
                        </li>
                        <li class="nav-item d-flex align-items-center">
                            <fa-icon [icon]="faMoneyCheckDollar" size="2x"></fa-icon>
                            <a class="nav-link" [routerLink]="['mysubscriptions']" [routerLinkActive]="['active']">My
                                subscriptions</a>
                        </li>
                        <li class="nav-item d-flex align-items-center">
                            <fa-icon [icon]="faLock" size="2x"></fa-icon>
                            <a class="nav-link" [routerLink]="['password']" [routerLinkActive]="['active']">Change
                                Password</a>
                        </li>
                    </ul>
                </nav>
            </section>

            <router-outlet></router-outlet>

        </div>
    </div>
</div>