import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AccountService } from 'src/app/account/account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PhoneVerificationModel } from 'src/app/models/phoneVerification.model';

@Component({
    selector: 'app-phone-number-edit',
    templateUrl: './phone-number-edit.component.html',
    styleUrls: ['./phone-number-edit.component.css']
})
export class PhoneNumberEditComponent implements OnInit {

    @Input() isReadMode: boolean;
    @Input() phoneNumber: string;
    @Output() phoneVerificationEvent: EventEmitter<boolean> = new EventEmitter<boolean>();


    phone: UntypedFormControl;
    phoneValidationCode: UntypedFormControl;
    model: PhoneVerificationModel;
    showValidation = false;
    hideErrorMessage = true;
    errorMessage = "";
    phoneValidationPattern = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    validationCodePattern = /^[0-9]{3,6}$/;
    isSendLoading = false;
    isVerificationLoading = false;

    constructor(private accountService: AccountService) { }

    ngOnInit() {
        this.phone = new UntypedFormControl('', [Validators.required, Validators.pattern(this.phoneValidationPattern)]);
        this.phoneValidationCode = new UntypedFormControl('', [Validators.required, Validators.pattern(this.validationCodePattern)]);
    }

    onSendValidationCode() {
        console.log('Current phone number: ' + this.phone.value);
        this.isSendLoading = true;
        this.accountService.sendPhoneVerificationCode(this.phone.value)
            .subscribe(
                (data: string) => this.onReceiveCodeComplete(data),
                (error: HttpErrorResponse) => this.onError(error)
            );
    }

    onReceiveCodeComplete(data: string) {
        if (data.length !== 0) {
            this.model = new PhoneVerificationModel(data, '', '');
            this.showValidation = true;
            this.hideErrorMessage = true;
            this.isSendLoading = false;
        }
    }
  

    onError(error: HttpErrorResponse) {
        this.hideErrorMessage = false;
        this.isSendLoading = false;
        this.isVerificationLoading = false;
        if (error.status === 400) {
          this.errorMessage = error.error;
        } else if (error.status === 500 || error.status === 0) {
          this.errorMessage = 'We found an error in our systems, our engineers on it so no worries. Please check back in a few minutes.';
        }
        console.log(error);
      }

    onValidateCode() {
        this.model.VerficationCode = this.phoneValidationCode.value;
        //console.log('Current verification: ' + this.model);
        this.isVerificationLoading = true;
        this.accountService.verifyPhoneNumber(this.model)
            .subscribe(
                (data) => this.onVerificationComplete(data),
                (error: HttpErrorResponse) => this.onVerificationError(error)
            );
    }

    onVerificationError(error: HttpErrorResponse) {
        console.log(error.message);
        this.hideErrorMessage = false;
        this.showValidation = false;
        this.phoneValidationCode.setValue('');
    }

    onVerificationComplete(verificationModel: PhoneVerificationModel) {
        console.log('verification completed');
        this.phoneNumber = verificationModel.verifiedNumber;
        this.hideErrorMessage = true;
        this.isReadMode = true;
        this.isVerificationLoading = false;
        this.phoneVerificationEvent.emit(true);
    }

    disableControls() {
        this.phone.disable();
        this.phoneValidationCode.disable();
    }

    onCancelVerification(){
        this.isSendLoading = false;
        this.showValidation = false;
        this.phone.setValue('');
        this.phoneValidationCode.setValue('');

    }

}
