import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

export class CardItemModel {
  public faIcon : IconDefinition;
    constructor(
        public id: string,
        public title: string,
        public content: string,
        public icon: string
      ) {  }
}
