import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DiscussionModel } from '../../models/discussion.model';
import { InboxService } from '../inbox.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class DiscussionResolverService  {

    constructor(private service: InboxService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DiscussionModel> {

        const id = route.params.id;
        console.log('retrived id: ' + id);

        return this.service.getDiscussion(id).pipe(
            map(discussion => {
                if (discussion) {
                    return discussion;
                }
                console.log(`No Discussion was found.`);
                this.router.navigate(['/inbox']);
                return null;
            }),
            catchError(error => {
                console.log(`Retrieval error: ${error}`);
                this.router.navigate(['/inbox']);
                return of(null);
            })
        );
    }
}
