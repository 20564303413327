<div class="row mt-5">
    <div class="col-12">
        <h2 class="mb-5 mt-3">Profile Information</h2>
        <div class="position-relative">
            <div class="position-absolute top-0 end-0">
                <button class="btn btn-primary btn-obrame-success" (click)="setPersonalEditMode()"
                    *ngIf="isPersonalReadOnlyMode">
                    <fa-icon [icon]="faPenToSquare" class="px-2"></fa-icon>
                    Edit
                </button>
                <button [disabled]="isPersonalLoading || !profileForm.valid || !profileForm.dirty"
                    class="btn btn-primary btn-obrame-success" (click)="onPersonalSave()"
                    *ngIf="!isPersonalReadOnlyMode">
                    <fa-icon [icon]="faFloppyDisk" class="px-2"></fa-icon>
                    Save
                    <span class="spinner-border spinner-border-sm ml-2 mb-1" role="status" aria-hidden="true"
                        *ngIf="isPersonalLoading"></span>
                </button>
                <button class="btn btn-primary btn-obrame-success" (click)="setPersonalEditMode()"
                    *ngIf="!isPersonalReadOnlyMode">
                    <fa-icon [icon]="faRectangleXmark" class="px-2"></fa-icon>
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>

<form novalidate [formGroup]="profileForm" (ngSubmit)="onPersonalSave()">

    <div class="row">
        <div class="col-12 col-md-12 col-lg-3">
            <app-profile-picture [readMode]="false" [profilePictureUrl]="model.profilePictureUrl"></app-profile-picture>
        </div>
        <div class="col-12 col-md-12 col-lg-9 mt-5">
            <div class="row g-2">
                <div class="col-md col-lg-5">
                    <div class="form-floating">
                        <input readonly type="text" class="form-control-plaintext" id="floatingInputEmail"
                            placeholder="Email" aria-describedby="emailHelp" formControlName="email">
                        <label for="floatingInputName">Email</label>
                        <small id="emailHelp" class="form-text text-muted">
                            You cannot edit or change the email address at the moment.
                        </small>
                    </div>
                </div>
                <div class="offset-md-1"></div>
            </div>

            <div class="row g-2">
                <div class="col-md col-lg-5">
                    <div class="form-floating">
                        <input type="text" [readonly]="isPersonalReadOnlyMode" id="floatingInputName"
                            placeholder="Firstname" aria-describedby="titleHelp" formControlName="firstName"
                            [ngClass]="[profileForm.get('firstName').dirty && !profileForm.get('firstName').valid ? 'is-invalid': '', isPersonalReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input']">
                        <label for="floatingInputName">Name</label>
                        <small id="nameHelp" class="form-text text-muted">
                            Your first name as described in official documents.
                        </small>
                    </div>
                </div>
                <div class="offset-md-1"></div>
            </div>

            <div class="row g-2 mb-4">
                <div class="col-md col-lg-5">
                    <div class="form-floating">
                        <input type="text" id="floatingInputFamily" [readonly]="isPersonalReadOnlyMode"
                            placeholder="Family Name" aria-describedby="titleHelp" formControlName="lastName"
                            [ngClass]="[profileForm.get('firstName').dirty && !profileForm.get('firstName').valid ? 'is-invalid': '', isPersonalReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input']">
                        <label for="floatingInputFamily">Family Name</label>
                        <small id="familyNameHelp" class="form-text text-muted">
                            Your family name as described in official documents.
                        </small>
                    </div>
                </div>
                <div class="offset-md-1"></div>
            </div>

            <app-phone-number-edit [isReadMode]="model.phoneNumberVerified" [phoneNumber]="model.phoneNumber"
                (phoneVerificationEvent)="onPhoneVerificationCompleted($event)">
            </app-phone-number-edit>

        </div>
    </div>
</form>

<div class="row mt-5">
    <div class="col-12">
        <h2>Business Information</h2>
        <div class="position-relative">
            <div class="position-absolute top-0 end-0">
                <button class="btn btn-primary btn-obrame-success" (click)="setBusinessEditMode()"
                    *ngIf="isBusinessReadOnlyMode">
                    <fa-icon [icon]="faPenToSquare" class="px-2"></fa-icon>
                    Edit
                </button>
                <button [disabled]="isBusinessLoading || !businessForm.valid || !businessForm.dirty"
                    class="btn btn-primary btn-obrame-success" (click)="onSetBusinessData()"
                    *ngIf="!isBusinessReadOnlyMode">
                    <fa-icon [icon]="faFloppyDisk" class="px-2"></fa-icon>
                    Save
                    <span class="spinner-border spinner-border-sm ml-2 mb-1" role="status" aria-hidden="true"
                        *ngIf="isBusinessLoading"></span>
                </button>
                <button class="btn btn-primary btn-obrame-success" (click)="setBusinessEditMode()"
                    *ngIf="!isBusinessReadOnlyMode">
                    <fa-icon [icon]="faRectangleXmark" class="px-2"></fa-icon>
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
<div class="row mt-3">
    <div class="col-12 col-md-12 offset-lg-3 col-lg-6">
        <form [formGroup]="businessForm" novalidate *ngIf="bizModel">
            <div class="row mb-5">
                <div class="col-md">
                    <div class="form-floating">
                        <input type="text" [readonly]="isBusinessReadOnlyMode"
                            [ngClass]="[isBusinessReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input', (businessForm.controls.name.invalid) ? 'is-invalid' : '']"
                            id="floatingInputName" placeholder="Business Name" aria-describedby="nameHelp"
                            formControlName="name">
                        <label for="floatingInputName">Business Name</label>
                        <div class="invalid-feedback">The Name field is required.</div>
                    </div>
                </div>
            </div> <!-- end row -->
            <h3 class="mb-3">Official Information</h3>
            <div class="row g-2 mt-4" *ngIf="isBusinessReadOnlyMode && bizModel.isCompany">
                <div class="col-md">
                    <div class="form-floating mb-3">
                        <input type="text" [readonly]="isBusinessReadOnlyMode"
                            [ngClass]="[isBusinessReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input', (businessForm.controls.legalName.invalid) ? 'is-invalid' : '']"
                            id="floatingStaticLegalName" placeholder="company123" formControlName="legalName">
                        <label for="floatingPlaintextInput">Company</label>
                        <div class="invalid-feedback">Company name is a required field.</div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="form-floating mb-3">
                        <input type="text" [readonly]="isBusinessReadOnlyMode"
                            [ngClass]="[isBusinessReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input', (businessForm.controls.taxId.invalid) ? 'is-invalid' : '']"
                            id="floatingStaticTaxId" placeholder="B1234123" formControlName="taxId">
                        <label for="floatingPlaintextInput">Business Id</label>
                        <div class="invalid-feedback">Business ID is a required field.</div>
                    </div>
                </div>
            </div><!-- end row -->
            <div class="row g-2 mt-4" *ngIf="isBusinessReadOnlyMode && bizModel.isCompany">
                <div class="col-md">
                    <div class="form-floating" *ngIf="isBusinessReadOnlyMode">
                        <input type="text" readonly ngClass="form-control-plaintext"
                            id="countryOfRegistrationInputReadOnly" placeholder="CountryOfResidency" value=""
                            formControlName="country">
                        <label for="countryOfRegistrationInputReadOnly">Country Of Registration</label>
                    </div>
                </div>
            </div><!-- end row -->
            <div class="row g-2 mt-4" *ngIf="isBusinessReadOnlyMode && !bizModel.isCompany">
                <div class="col-md">
                    <div class="form-floating mb-3">
                        <input type="text" [readonly]="isBusinessReadOnlyMode"
                            [ngClass]="[isBusinessReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input', (businessForm.controls.personalId.invalid) ? 'is-invalid' : '']"
                            id="floatingPersonalId" placeholder="company123" formControlName="personalId">
                        <label for="floatingPersonalId">Personal Id</label>
                        <div class="invalid-feedback">Personal Id or Tax number is a required field.</div>
                    </div>
                </div>
            </div><!-- end row -->
            <div class="row g-2 mt-4" *ngIf="isBusinessReadOnlyMode && !bizModel.isCompany">
                <div class="col-md">
                    <div class="form-floating" *ngIf="isBusinessReadOnlyMode">
                        <input type="text" readonly ngClass="form-control-plaintext"
                            id="countryOfResidencyInputReadOnly" placeholder="CountryOfResidency" value=""
                            formControlName="countryOfResidency">
                        <label for="floatingInputPostalCode">Country Of Residency</label>
                    </div>
                </div>
            </div><!-- end row -->
            <!-- Beginning of the business info Edit -->
            <div class="row mt-5" *ngIf="!isBusinessReadOnlyMode">
                <div class="col-12 col-md-6 p-3" [ngClass]="[bizModel.isCompany ? 'bg-light': '']">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="company" id="rbIsCompany" formControlName="isCompanyCtrl" (change)="onChangeCompanyOrPerson($event)">
                        <label class="form-check-label" for="rbIsCompany">
                            I works for a company
                        </label>
                    </div>
                    <div class="row g-2 mt-4">
                        <div class="col-md">
                            <div class="form-floating mb-3">
                                <input type="text" [readonly]="isBusinessReadOnlyMode || !bizModel.isCompany"
                                    [ngClass]="[isBusinessReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input', (businessForm.controls.legalName.invalid) ? 'is-invalid' : '']"
                                    id="floatingStaticLegalName" placeholder="company123" formControlName="legalName">
                                <label for="floatingPlaintextInput">Company</label>
                                <div class="invalid-feedback">Company name is a required field.</div>
                            </div>
                        </div>
                    </div><!-- end row -->
                    <div class="row g-2 mt-4">
                        <div class="col-md">
                            <div class="form-floating mb-3">
                                <input type="text" [readonly]="isBusinessReadOnlyMode || !bizModel.isCompany"
                                    [ngClass]="[isBusinessReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input', (businessForm.controls.taxId.invalid) ? 'is-invalid' : '']"
                                    id="floatingStaticTaxId" placeholder="B1234123" formControlName="taxId">
                                <label for="floatingPlaintextInput">Business Id</label>
                                <div class="invalid-feedback">Business ID is a required field.</div>
                            </div>
                        </div>
                    </div><!-- end row -->
                    <div class="row g-2 mt-4">
                        <div class="col-md">
                            <div class="form-floating" *ngIf="!isBusinessReadOnlyMode">
                                <select class="form-select" id="companyCountryInput" formControlName="countryId"
                                    aria-describedby="companyCountryHelp" *ngIf="countriesModel">
                                    <option *ngFor="let country of countriesModel" [value]="country.id">
                                        {{country.title}}</option>
                                </select>
                                <label for="companyCountryInput">Country of Registeration</label>
                            </div>
        
                            <div class="form-floating" *ngIf="isBusinessReadOnlyMode">
                                <input type="text" readonly ngClass="form-control-plaintext" id="companyCountryInputReadOnly"
                                    placeholder="Country" value="" formControlName="country">
                                <label for="floatingInputPostalCode">Country</label>
                            </div>
                        </div>
                    </div><!-- end row -->
                </div>
                <!-- Beginning of individual business edit -->
                <div class="col-12 col-md-6 p-3" [ngClass]="[!bizModel.isCompany ? 'bg-light': '']">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="person" id="rbIsPerson" formControlName="isCompanyCtrl" (change)="onChangeCompanyOrPerson($event)">
                        <label class="form-check-label" for="rbIsPerson">
                            I work as an individual
                        </label>
                    </div>
                    <!-- Rest of your column content goes here -->
                    <div class="row g-2 mt-4">
                        <div class="col-md">
                            <div class="form-floating mb-3">
                                <input type="text" [readonly]="isBusinessReadOnlyMode || bizModel.isCompany"
                                    [ngClass]="[isBusinessReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input', (businessForm.controls.personalId.invalid) ? 'is-invalid' : '']"
                                    id="floatingPersonalId" placeholder="1234XYZ" formControlName="personalId">
                                <label for="floatingPlaintextInput">Personal Id</label>
                                <div class="invalid-feedback">Personal Id is a required field.</div>
                            </div>
                        </div>
                    </div><!-- end row -->
                    <div class="row g-2 mt-4">
                        <div class="col-md">
                            <div class="form-floating" *ngIf="!isBusinessReadOnlyMode">
                                <select class="form-select" id="countryOfResidencyInput" formControlName="countryOfResidencyId"
                                    aria-describedby="companyCountryHelp" *ngIf="countriesModel">
                                    <option *ngFor="let country of countriesModel" [value]="country.id">
                                        {{country.title}}</option>
                                </select>
                                <label for="countryOfResidencyInput">Country of Residency</label>
                            </div>
                        </div>
                    </div><!-- end row -->


                </div>
            </div>

            <h3 class="mb-3 mt-5">Address Information</h3>
            <div class="row g-2 mt-4">
                <div class="col-md">
                    <div class="form-floating mb-3">
                        <input type="text" [readonly]="isBusinessReadOnlyMode"
                            [ngClass]="[isBusinessReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input', (businessForm.controls.website.invalid) ? 'is-invalid' : '']"
                            id="floatingStaticWebsite" placeholder="https://www." formControlName="website">
                        <label for="floatingStaticWebsite">Website</label>
                        <div class="invalid-feedback">Please review the URL format.</div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="form-floating mb-3">
                        <input type="text" [readonly]="isBusinessReadOnlyMode"
                            [ngClass]="[isBusinessReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input', (businessForm.controls.phoneNumber.invalid) ? 'is-invalid' : '']"
                            id="floatingStaticPhoneNumber" placeholder="+34..." formControlName="phoneNumber">
                        <label for="floatingStaticPhoneNumber">Business Phone:</label>

                    </div>
                </div>
            </div><!-- end row -->
            <div class="row">
                <div class="col-md">
                    <div class="form-floating">
                        <input type="text" [readonly]="isBusinessReadOnlyMode"
                            [ngClass]="[isBusinessReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input', (businessForm.controls.streetAddress.invalid) ? 'is-invalid' : '']"
                            id="floatingInputFullAddress" placeholder="Full Address" aria-describedby="fullAddressHelp"
                            formControlName="streetAddress">
                        <label for="floatingInputCityName">Full Address</label>
                        <div class="invalid-feedback">The full address is required.</div>
                    </div>
                </div>
            </div> <!-- end row -->
            <div class="row g-2 mt-4">
                <div class="col-md">
                    <div class="form-floating">
                        <input type="text" [readonly]="isBusinessReadOnlyMode"
                            [ngClass]="[isBusinessReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input', (businessForm.controls.city.invalid) ? 'is-invalid' : '']"
                            id="floatingInputCityName" placeholder="My City" aria-describedby="cityNameHelp"
                            formControlName="city">
                        <label for="floatingInputCityName">Town / City</label>
                        <div class="invalid-feedback">The City name is required.</div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="form-floating">
                        <input type="text" [readonly]="isBusinessReadOnlyMode"
                            [ngClass]="[isBusinessReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input', (businessForm.controls.province.invalid) ? 'is-invalid' : '']"
                            id="floatingInputStateId" placeholder="State Or Province" value=""
                            formControlName="province">
                        <label for="floatingInputStateId">State / Province</label>
                        <div class="invalid-feedback">The Province name is required.</div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="form-floating">
                        <input type="text" [readonly]="isBusinessReadOnlyMode"
                            [ngClass]="[isBusinessReadOnlyMode ? 'form-control-plaintext' : 'form-control obrame-form-floating-input', (businessForm.controls.postalCode.invalid) ? 'is-invalid' : '']"
                            id="floatingInputPostalCode" placeholder="Zip or Postal Code" value=""
                            formControlName="postalCode">
                        <label for="floatingInputPostalCode">Zip / Postal Code</label>
                        <div class="invalid-feedback">The Zip Or Postal Code is required.</div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="row justify-content-md-center mt-5 mb-5">
    <div class="col-9 offset-3">
        <!-- <p>Form status: {{ businessForm.status | json }}</p> -->
        <app-error-alert [errorMessage]="errorMessage" [hideError]="hideErrorMessage"></app-error-alert>

    </div>
</div>