import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionCheckoutModel } from 'src/app/models/subscriptionCheckout.model';
import { SubscriptionGroupModel } from 'src/app/models/subscriptionGroupModel.model';
import { SubscriptionListModel } from 'src/app/models/subscriptionList.model';
import { SubscriptionTemplateModel } from 'src/app/models/subscriptionTemplate.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private serviceUrl = environment.apiUrl + 'subscription';

  constructor(private http: HttpClient) { }

  getSubscriptionTemplateByModule(id: string): Observable<SubscriptionGroupModel[]> {
    return this.http.get<SubscriptionGroupModel[]>(`${this.serviceUrl}/getSubscriptionTemplateByModule/${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getSubscriptionTemplate(id: string): Observable<SubscriptionTemplateModel> {
    return this.http.get<SubscriptionTemplateModel>(`${this.serviceUrl}/getSubscriptionTemplate/${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getMySubscriptions(): Observable<SubscriptionListModel[]> {
    return this.http.get<SubscriptionListModel[]>(`${this.serviceUrl}/getMySubscriptions`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getSubscriptionCheckout(id: string): Observable<SubscriptionCheckoutModel> {
    return this.http.get<SubscriptionCheckoutModel>(`${this.serviceUrl}/getSubscriptionCheckout/${id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  setSubscription(newSub: SubscriptionCheckoutModel): Observable<SubscriptionCheckoutModel> {
    return this.http.post<SubscriptionCheckoutModel>(`${this.serviceUrl}/setSubscription`, newSub, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
}
