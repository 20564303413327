import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { PortfolioService } from './portfolio/portfolio.service';
import { ProfessionalRegistrationModel } from '../models/professionalRegistration.model';


@Injectable()
export class ProGuardGuard  {

  constructor(private _router: Router, private portfolioService: PortfolioService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log('in professional route guard:' + state.url);

    return this.portfolioService.getProfessionalRegistrationStage().pipe(
      map(stage => {
        console.dir(stage);
        if (!stage.isPersonalContactInfoCompleted && state.url !== '/professional/register') {
          this._router.navigate(['/professional/register']);
          return false;
        }

        if (stage.isPersonalContactInfoCompleted && !stage.isPortfolioCompeted && state.url !== '/professional/portfolio') {
          this._router.navigate(['/professional/portfolio']);
          return false;
        }

        return true;

      })
    );

  }

}
