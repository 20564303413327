import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { ProfileService } from './profile.service';
import { ProfileProgressModel } from '../models/profileProgress.model';

export const ProfileProgressGuard: CanActivateFn = (route, state) => {
  const profileService = inject(ProfileService);
  const router = inject(Router);

  return profileService.getProfileProgress().pipe(
    map((progress: ProfileProgressModel) => {
      if (progress.isOnboarded && progress.hasSubscription) {
        return true
      } else if (progress.isOnboarded && !progress.hasSubscription) {
        return router.parseUrl('/profile/subscriptions');
      } else if (!progress.isOnboarded) {
        return router.parseUrl('/onboarding');
      }
      //return router.parseUrl('/');
    }),
    catchError(error => {
      console.log(`Retrieval error: ${error}`);
      return of(router.createUrlTree(['/']));
    })
  );
};
