import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TeamInvitationModel } from 'src/app/models/teamInvitation.model';
import { TeamsService } from '../teams.service';




@Injectable()
export class TeamInvitationResolver {

  constructor(private teamService: TeamsService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<null | TeamInvitationModel> {
    const id = route.params.id;
    // console.log('retrived id: ' + id);

    return this.teamService.getInvitation(id).pipe(
      map(invitation => {
        if (invitation) {
          return invitation;
        }
        console.log(`No invitation was found`);
        this.router.navigate(['/teams']);
        //return of(null);
      }),
      catchError(error => {
        console.log(`Retrieval error: ${error}`);
        this.router.navigate(['/teams']);
        return of(null);
      })
    );
  }

}
