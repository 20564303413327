<div class="input-group">
    <input readonly="true" class="form-control" [placeholder]="inputPlaceholder" value="{{inputSelectedValue}}">
    <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="open(content)" type="button">
            <fa-icon [icon]="buttonIcon"></fa-icon>
        </button>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h2 class="display-6" id="modal-basic-title">{{title}}</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <ul class="list-group list-group-flush" *ngIf="model">
            <li class="list-group-item d-flex align-items-center" *ngFor="let pcItem of model" (click)="onSelect(pcItem)" [class.active]="pcItem === selectedModel">
                <svg class="bd-placeholder-img rounded-circle me-3 " width="80" height="80"
                    xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140"
                    preserveAspectRatio="xMidYMid slice" focusable="false" *ngIf="includeIcon">
                    <title>Placeholder</title>
                    <rect width="100%" height="100%" fill="#777"></rect><text x="50%" y="50%" fill="#777"
                        dy=".3em">140x140</text>
                </svg>

                <div class="d-flex justify-content-start flex-column">
                    <b class="mb-1 fs-6">{{pcItem.title}}</b>
                    <span class="d-block fs-7">{{pcItem.content}}</span>
                </div>
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-obrame-success" (click)="modal.close('select')">Select</button>
    </div>
</ng-template>