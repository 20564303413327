<!-- <ng-template #content let-modal> -->
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form autocomplete="off" novalidate [formGroup]="addressForm">
        <div class="form-group">
            <label for="inputTitle">Título</label>
            <input type="text" class="form-control" id="inputTitle" aria-describedby="titleHelp" placeholder="" formControlName="title" [ngClass]="{'is-invalid': addressForm.get('title').dirty && !addressForm.get('title').valid }">
            <small id="titleHelp" class="form-text text-muted">Un nombre para la dirección, por ejemplo mi
                casa</small>
            <div class="invalid-feedback">
                Por favor inserte un título para esta dirección.
            </div>
        </div>
        <div class="form-group">
            <label>Dirección</label>
            <p *ngIf="!isNewMode">
                <b>{{predefinedAddress}}</b>
            </p>
            <app-location-picker searchScope="address" [resetAfterSelect]="false" (location)="onLocationSelected($event)" placeHolder="Busca su dirección" *ngIf="isNewMode"></app-location-picker>
            <div class="invalid-feedback">
                Por favor seleccione una dirección válida.
            </div>
        </div>
        <div class="form-group">
            <input type="text" class="form-control" id="inputAdditional" aria-describedby="additionalHelp" placeholder="piso, suite, unidad, edificio o piso" formControlName="additional_Address" [ngClass]="{'is-invalid': addressForm.get('additional_Address').dirty && !addressForm.get('additional_Address').valid }">
            <small id="additionalHelp" class="form-text text-muted">
                Número de piso o portal p.ej. Portal 1 B Piso 6
            </small>
            <div class="invalid-feedback">
                Por favor inserte un título para esta dirección.
            </div>
        </div>
        <div class="form-group">
            <label for="tbAdditional">Información Adicional</label>
            <textarea class="form-control" id="tbinfo" rows="3" formControlName="instructions" [ngClass]="{'is-invalid': addressForm.get('instructions').dirty && !addressForm.get('instructions').valid }"></textarea>
        </div>
    </form>
    <div class="alert alert-danger" role="alert" *ngIf="showErrors">
        Por favor elija una dirección válida.
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save close')">Cancelalo</button>
    <button type="button" class="btn btn-primary" (click)="onSave()" [disabled]="!addressForm.valid || !addressForm.dirty || isLoading" *ngIf="isNewMode">
        Guardar
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isLoading"></span>
    </button>
    <button type="button" class="btn btn-primary" (click)="onUpdate()" [disabled]="!addressForm.valid || !addressForm.dirty || isLoading" *ngIf="!isNewMode">
        Actualizarla
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isLoading"></span>
    </button>
</div>
<!-- </ng-template> -->