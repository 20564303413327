import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCalendarDays, faList, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { AssignmentDisplaytModel } from 'src/app/models/assignmentDisplay.model';
import { FilterAssignmentModel } from 'src/app/models/filterAssignment.model';
import { AssignmnetService } from '../assignmnet.service';
import { Observable, catchError, map, of, tap } from 'rxjs';

@Component({
  selector: 'app-assignment-service-provider',
  templateUrl: './assignment-service-provider.component.html',
  styleUrls: ['./assignment-service-provider.component.css']
})
export class AssignmentServiceProviderComponent {

  faCalendarDays = faCalendarDays;
  faList = faList;
  faEllipsis = faEllipsis;

  isLoading = false;
  hideErrorMessage = true;
  errorMessage = '';

  assignmentsModel: AssignmentDisplaytModel[];
  // Create a FilterAssignmentModel object
  filter: FilterAssignmentModel;

  constructor(private route: ActivatedRoute, private assignmentService: AssignmnetService) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.onAssignmentsReceived(data.assignmentData);
    });

    // Calculate today's date
    const today = new Date();
    // Calculate the date 30 days from today
    const endDate = new Date();
    endDate.setDate(today.getDate() + 30);
    this.filter = new FilterAssignmentModel(10, 1, today, endDate, []);
  }

  onAssignmentsReceived(assignments: AssignmentDisplaytModel[]) {
    //console.log(assignments)
    this.assignmentsModel = assignments;
  }

  onApplyFilter(newFilter: FilterAssignmentModel) {
    console.log(newFilter);

    this.assignmentService.getServiceProviderAssignments(newFilter).pipe(
      tap(_ => { this.filter = newFilter; this.isLoading = true; }),
      map(response => this.onAssignmentsLoaded(response)),
      catchError(this.handleError<FilterAssignmentModel>('getServiceProviderAssignments'))
    ).subscribe();
  }

  onAssignmentsLoaded(fetchedAssignments: AssignmentDisplaytModel[]) {
    this.assignmentsModel = fetchedAssignments;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.hideErrorMessage = false;
      this.isLoading = false;

      this.errorMessage = error?.error;

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
