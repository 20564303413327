<main class="container">
    <div class="row">
        <div class="col-4">
            <app-profile-card [portfolioId]="model.portfolioId"></app-profile-card>
        </div>

        <div class="col-8">
            <h3 class="pb-4 mt-5 font-italic border-bottom">Detalles del pedido</h3>
            <div class="row">
                <div class="col-3">
                    <b>Fecha de orden:</b>
                </div>
                <div class="col-9">
                    {{model.createdOn}}
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <b>Día de actividad:</b>
                </div>
                <div class="col-9">
                    {{model.appointmentDate}}
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <b>Hora de actividad:</b>
                </div>
                <div class="col-9">
                    {{model.appointmentTime}}
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <b>Dirección de Destino:</b>
                </div>
                <div class="col-9">
                    {{model.address}}
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-3">
                    <b>Mis deseos:</b>
                </div>
                <div class="col-9">
                    {{model.wishes}}
                </div>
            </div>
            <table class="table table-hover mt-5">
                <thead>
                    <tr>
                        <th scope="col">ARTÍCULOS Y DESCRIPCIÓN</th>
                        <th scope="col">
                            <fa-icon [icon]="faClock"></fa-icon>
                        </th>
                        <th scope="col">
                            <fa-icon [icon]="faEuroSign"></fa-icon>
                        </th>

                        <th scope="col">TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{model.serviceTitle}}</th>
                        <td> {{model.duration}}</td>
                        <td>{{model.price}}</td>
                        <td>{{model.total}}</td>

                    </tr>
                </tbody>
            </table>
            <div class="row">
                <div class="col-12 text-right">
                    <h5>Total de la factura: {{model.total}} €</h5>
                </div>
            </div>
        </div>
    </div>
</main>